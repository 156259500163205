$main-font-family: 'Cabin', 'Roboto', sans-serif;

$top-toolbar-height: 56px; 
$horizontal-menu-height: 64px; 
$footer-toolbar-height: 56px;

$sidenav-width: 260px;
$compact-sidenav-width: 170px;
$mini-sidenav-width: 66px;

$footer-font-size: 12px;

$default-table-width: 100%;
$default-table-action-column-width: 30px;
$default-table-selection-column-width: 30px;
