@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import '~handsontable/dist/handsontable.full.css';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Cabin&display=swap");
@import url("https://fonts.googleapis.com/css?family=Orbitron&display=swap");
@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/nova-light/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~font-awesome/css/font-awesome.min.css';
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(material-icon.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;
}

body {
  font-family: "Cabin", "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: unset;
}

.app-radius, .form-separator {
  border-radius: 3px;
}

.app-radius-top-large, form .content-header {
  border-radius: 20px 20px 0 0;
}

.app-radius-bottom-large, .login-container {
  border-radius: 0 0 20px 20px;
}

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.d-content {
  display: contents;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.justify-between {
  justify-content: space-between;
}

.o-hidden {
  overflow: hidden !important;
}

.h-300p {
  height: 300px;
}

.h-400p {
  height: 400px;
}

.transition {
  transition: 0.2s;
}

.white {
  color: #fff;
}

.modal-no-closing a.ui-dialog-titlebar-icon.ui-dialog-titlebar-close {
  display: none;
}

div[role=dialog]::before {
  content: "";
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.mat-card-header-text {
  margin: 0 8px !important;
}

.calendar-weekend-color td.fc-sun {
  background: #ffa09e;
}
.calendar-weekend-color td.fc-sat {
  background: #ffa09e;
}

mat-form-field {
  width: 100%;
}

.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.page-wrapper.mat-drawer-container {
  z-index: unset;
  min-height: calc(100% - (56px + 56px));
}
.page-wrapper.mat-drawer-container .mat-drawer-content {
  overflow: hidden;
  min-height: 450px;
  min-height: calc(100vh - (56px + 56px));
}

.toolbar-dropdown-menu.mat-menu-panel {
  margin-top: 9px;
  border-radius: 0 !important;
  overflow: hidden;
}
.toolbar-dropdown-menu.mat-menu-panel .mat-menu-content {
  padding: 0;
}
.toolbar-dropdown-menu.mat-menu-panel .ps__rail-y {
  left: auto !important;
}

.logo {
  font-weight: 700;
  text-decoration: none;
  min-width: calc(260px - 16px);
  color: #fff;
}
.logo img {
  height: 55px;
}
.logo.mobile {
  min-width: calc(66px - 16px);
}
.logo.mobile span {
  display: none;
}

.app {
  height: 100%;
  overflow: hidden;
}
.app.fixed-header .page-wrapper.mat-drawer-container {
  top: 56px;
  padding-bottom: 56px;
  min-height: calc(100% - 56px);
}
.app.fixed-header .header {
  position: fixed;
  z-index: 999;
  width: 100%;
}
.app.fixed-header .sidenav {
  padding-bottom: 56px;
}
.app.fixed-header.fixed-sidenav .sidenav {
  padding-bottom: 0;
}
.app.fixed-sidenav .sidenav {
  position: fixed;
  z-index: 3;
}
.app.fixed-sidenav .sidenav .sidenav-menu-outer {
  height: calc(100% - 56px);
}
.app.fixed-sidenav .footer {
  transition: 0.3s;
  width: calc(100% - 260px);
  float: right;
}
.app.fixed-sidenav .footer.full-width {
  width: 100%;
}
.app.fixed-sidenav.compact .footer {
  width: calc(100% - 170px);
}
.app.fixed-sidenav.compact .footer.full-width {
  width: 100%;
}
.app.fixed-sidenav.mini .footer {
  width: calc(100% - 66px);
}
.app.fixed-sidenav.mini .footer.full-width {
  width: 100%;
}
.app.fixed-footer .page-wrapper.mat-drawer-container {
  padding-bottom: 56px;
}
.app.fixed-footer .sidenav {
  padding-bottom: 56px;
}
.app.fixed-footer .footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
.app.fixed-footer.fixed-sidenav .sidenav {
  padding-bottom: 0;
  z-index: 4;
}
.app.compact .logo {
  min-width: calc(170px - 16px);
}
.app.compact .sidenav {
  width: 170px;
}
.app.compact .user-block img {
  width: 90px;
  height: 90px;
}
.app.compact .menu-item .mat-button {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.app.compact .menu-item .mat-button .menu-icon {
  margin: 0;
}
.app.compact .menu-item .mat-button .menu-title {
  line-height: 24px;
}
.app.compact .menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.compact .menu-expand-icon {
  top: 28px;
}
.app.compact .sub-menu .mat-button {
  padding-left: 0 !important;
}
.app.mini .logo {
  min-width: calc(66px - 16px);
}
.app.mini .logo span {
  display: none;
}
.app.mini .sidenav {
  width: 66px;
}
.app.mini .user-block img {
  width: 45px;
  height: 45px;
}
.app.mini .menu-item .mat-button {
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 66px;
}
.app.mini .menu-item .mat-button .menu-icon {
  margin: 0;
}
.app.mini .menu-item .mat-button .menu-title {
  line-height: 24px;
}
.app.mini .menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.mini .menu-item .menu-title {
  display: none;
}
.app.mini .menu-expand-icon {
  top: 14px;
  right: -4px;
}
.app.mini .sub-menu .mat-button {
  padding-left: 0 !important;
}
.app.horizontal-menu.compact .logo {
  min-width: calc(260px - 16px);
}
.app.horizontal-menu.compact .logo span {
  display: block;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button {
  padding-top: 4px;
  padding-bottom: 4px;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button .horizontal-menu-title {
  line-height: 18px;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button .horizontal-menu-icon {
  margin: 0;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button {
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button .horizontal-menu-icon {
  margin-right: 10px;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.app.horizontal-menu.mini .logo {
  min-width: calc(260px - 16px);
}
.app.horizontal-menu.mini .logo span {
  display: block;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button {
  min-width: 56px;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button .horizontal-menu-title {
  display: none;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button .horizontal-menu-icon {
  margin: 0;
}
.app.horizontal-menu.mini .horizontal-menu-item .horizontal-sub-menu {
  width: 68px;
}
.app.horizontal-menu.mini .horizontal-menu-item .horizontal-sub-menu .mat-button {
  justify-content: center !important;
}

/* Material Components */
.mat-card {
  overflow: hidden;
}
.mat-card.custom-card .mat-card-header {
  margin: -16px -16px 0;
}
.mat-card.custom-card .mat-card-header .mat-card-title {
  font-size: 14px;
}
.mat-card.custom-card .mat-card-actions {
  padding-left: 6px;
  padding-right: 6px;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.vertical-tabs.mat-tab-group {
  flex-direction: row;
}
.vertical-tabs.mat-tab-group .mat-tab-labels {
  flex-direction: column;
}
.vertical-tabs.mat-tab-group .mat-ink-bar {
  display: none;
}
.vertical-tabs.mat-tab-group .mat-tab-header {
  border: none !important;
}
.vertical-tabs.mat-tab-group .mat-tab-body-wrapper {
  padding: 0 16px;
}

.mat-table {
  width: 100%;
}

.w-table-actions {
  max-width: 30px;
}

.w-table-selection {
  width: 30px;
}

.mat-table tr,
.mat-table th,
.mat-table td {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mail-body p {
  margin-bottom: 1rem;
}
.mail-body ul {
  margin: 0 16px;
}
.mail-body .ql-container.ql-snow {
  min-height: 120px;
  margin-bottom: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
}

.connexion-titre {
  margin: 14px 0px;
}

.ui-inputtext {
  width: 100%;
}

.form-separator {
  margin: 35px 5px 15px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  padding: 0px 0px;
}
.form-separator::before {
  content: "";
  display: block;
  position: relative;
  top: -15px;
  border: 2px solid;
}

.spacing-elt {
  flex: 1 1 auto;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.overflow-hidden {
  overflow: hidden;
}

.space-between {
  justify-content: space-between;
}

.italic {
  font-style: italic;
}

.font-rem08 {
  font-size: 0.8rem;
}

.hidden {
  visibility: hidden;
  position: absolute;
  z-index: -1;
  top: 400%;
}

.noScroll {
  overflow: hidden !important;
  position: fixed !important;
}

.scroll {
  overflow: scroll;
}

.relative {
  position: relative;
}

/* Input telephone */
intl-tel-input {
  display: flex;
  flex-direction: column;
}

input.form-control, input, .iti {
  width: 100%;
}

/* Redimenssion des inputs */
input,
.ui-inputgroup-addon {
  height: 26px !important;
}

.ui-inputgroup-addon {
  display: flex !important;
  align-items: center;
}
.ui-inputgroup-addon mat-icon {
  display: flex !important;
  align-items: center;
  height: 0 !important;
}

button.mat-button, button.mat-raised-button, span.mat-button-wrapper {
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

button.mat-icon-button {
  height: 26px;
  width: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button.mat-icon-button mat-icon.mat-icon {
  font-size: 18px;
}

label.ui-dropdown-label.ui-inputtext,
span.ui-multiselect-label {
  height: 26px;
  display: flex;
  align-items: center;
}

button.ui-button.ui-widget {
  height: 26px;
}

.ui-button.ui-button-text-icon-left .ui-button-text {
  padding: 0.1em 1em 0.429em 2em !important;
}

td, tr {
  line-height: 1.5em;
  padding: 0px 5px !important;
}

li.ui-state-default {
  height: 26px;
}
li.ui-state-default a {
  padding: 0px 5px !important;
}

.ui-dropdown {
  min-width: initial;
}

label {
  font-size: 0.89em;
  font-weight: 600;
}

p-table td, p-table th {
  word-break: break-all !important;
}
p-table .xlsx-preview-table table {
  width: auto;
}

.image-in-table {
  position: relative;
  width: 50px;
  height: 50px;
  border: 2px solid #777777;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.mat-standard-chip {
  height: inherit !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

/* perfect-scrollbar */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 7px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 7px;
  right: 0px;
  position: absolute;
  z-index: 99;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 1px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 1px;
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 5px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.sebm-google-map-container {
  height: 500px;
  height: calc(100vh - 112px);
}

#leaflet-map {
  width: 100%;
  height: 490px;
  height: calc(100vh - 112px);
}

/* -- NGU carousel -- */
.tile {
  box-shadow: none !important;
}

.nguCarouselPoints {
  list-style-type: none;
  text-align: center;
  padding: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
  box-sizing: border-box;
}

.nguCarouselPoints li {
  display: inline-block;
  border-radius: 50%;
  background: #6b6b6b;
  padding: 4px;
  margin: 0 3px;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: 0.4s;
}

.nguCarouselPoints li.active {
  background: #6b6b6b;
  transform: scale(1.5);
}

.nguCarouselPoints li:hover {
  cursor: pointer;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.app.thm-yellow-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-yellow-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-yellow-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-option:hover:not(.mat-option-disabled), .app.thm-yellow-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffab40;
}
.app.thm-yellow-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-yellow-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-yellow-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-yellow-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-yellow-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #fbc02d;
}
.app.thm-yellow-light .mat-pseudo-checkbox-checked,
.app.thm-yellow-light .mat-pseudo-checkbox-indeterminate,
.app.thm-yellow-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-yellow-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffab40;
}
.app.thm-yellow-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-yellow-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-yellow-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-yellow-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-yellow-light .mat-app-background, .app.thm-yellow-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-yellow-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-yellow-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #fbc02d;
}
.cdk-high-contrast-active .app.thm-yellow-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-yellow-light .mat-badge-accent .mat-badge-content {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-yellow-light .mat-badge {
  position: relative;
}
.app.thm-yellow-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-yellow-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-yellow-light .ng-animate-disabled .mat-badge-content,
.app.thm-yellow-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-yellow-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-yellow-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-yellow-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-yellow-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-yellow-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-yellow-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-yellow-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-yellow-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-yellow-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-button, .app.thm-yellow-light .mat-icon-button, .app.thm-yellow-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-yellow-light .mat-button.mat-primary, .app.thm-yellow-light .mat-icon-button.mat-primary, .app.thm-yellow-light .mat-stroked-button.mat-primary {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-button.mat-accent, .app.thm-yellow-light .mat-icon-button.mat-accent, .app.thm-yellow-light .mat-stroked-button.mat-accent {
  color: #ffab40;
}
.app.thm-yellow-light .mat-button.mat-warn, .app.thm-yellow-light .mat-icon-button.mat-warn, .app.thm-yellow-light .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-yellow-light .mat-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-yellow-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-yellow-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-yellow-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-yellow-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-yellow-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-yellow-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-yellow-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-yellow-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-yellow-light .mat-button .mat-ripple-element, .app.thm-yellow-light .mat-icon-button .mat-ripple-element, .app.thm-yellow-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-yellow-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-yellow-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-flat-button, .app.thm-yellow-light .mat-raised-button, .app.thm-yellow-light .mat-fab, .app.thm-yellow-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-yellow-light .mat-flat-button.mat-primary, .app.thm-yellow-light .mat-raised-button.mat-primary, .app.thm-yellow-light .mat-fab.mat-primary, .app.thm-yellow-light .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-flat-button.mat-accent, .app.thm-yellow-light .mat-raised-button.mat-accent, .app.thm-yellow-light .mat-fab.mat-accent, .app.thm-yellow-light .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-flat-button.mat-warn, .app.thm-yellow-light .mat-raised-button.mat-warn, .app.thm-yellow-light .mat-fab.mat-warn, .app.thm-yellow-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-yellow-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-flat-button.mat-primary, .app.thm-yellow-light .mat-raised-button.mat-primary, .app.thm-yellow-light .mat-fab.mat-primary, .app.thm-yellow-light .mat-mini-fab.mat-primary {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-flat-button.mat-accent, .app.thm-yellow-light .mat-raised-button.mat-accent, .app.thm-yellow-light .mat-fab.mat-accent, .app.thm-yellow-light .mat-mini-fab.mat-accent {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-flat-button.mat-warn, .app.thm-yellow-light .mat-raised-button.mat-warn, .app.thm-yellow-light .mat-fab.mat-warn, .app.thm-yellow-light .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-yellow-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-yellow-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-yellow-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-yellow-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-yellow-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-yellow-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-yellow-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-yellow-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-yellow-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-yellow-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-yellow-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-yellow-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-yellow-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-yellow-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-yellow-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-yellow-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-yellow-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-button-toggle-standalone,
.app.thm-yellow-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-yellow-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-yellow-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-yellow-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-yellow-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-yellow-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-yellow-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-yellow-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-yellow-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.app.thm-yellow-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-yellow-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-yellow-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-yellow-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-yellow-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-yellow-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-yellow-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-yellow-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-yellow-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-yellow-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-yellow-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-yellow-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #fbc02d;
}
.app.thm-yellow-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-yellow-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffab40;
}
.app.thm-yellow-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-yellow-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-yellow-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-yellow-light .mat-table {
  background: white;
}
.app.thm-yellow-light .mat-table thead, .app.thm-yellow-light .mat-table tbody, .app.thm-yellow-light .mat-table tfoot,
.app.thm-yellow-light mat-header-row, .app.thm-yellow-light mat-row, .app.thm-yellow-light mat-footer-row,
.app.thm-yellow-light [mat-header-row], .app.thm-yellow-light [mat-row], .app.thm-yellow-light [mat-footer-row],
.app.thm-yellow-light .mat-table-sticky {
  background: inherit;
}
.app.thm-yellow-light mat-row, .app.thm-yellow-light mat-header-row, .app.thm-yellow-light mat-footer-row,
.app.thm-yellow-light th.mat-header-cell, .app.thm-yellow-light td.mat-cell, .app.thm-yellow-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-cell, .app.thm-yellow-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-datepicker-toggle,
.app.thm-yellow-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-yellow-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-calendar-body-cell-content,
.app.thm-yellow-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-yellow-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-yellow-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-yellow-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-yellow-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-yellow-light .mat-calendar-body-in-range::before {
  background: rgba(251, 192, 45, 0.2);
}
.app.thm-yellow-light .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-yellow-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-yellow-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(251, 192, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-yellow-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(251, 192, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-yellow-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-yellow-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-yellow-light .mat-calendar-body-selected {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(251, 192, 45, 0.4);
}
.app.thm-yellow-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 171, 64, 0.2);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-yellow-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-yellow-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 171, 64, 0.4);
}
.app.thm-yellow-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-yellow-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-yellow-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-yellow-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-yellow-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-datepicker-toggle-active {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-datepicker-toggle-active.mat-accent {
  color: #ffab40;
}
.app.thm-yellow-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-yellow-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-yellow-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-yellow-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-yellow-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-yellow-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-expansion-panel-header-description,
.app.thm-yellow-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-yellow-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.app.thm-yellow-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-yellow-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffab40;
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-yellow-light .mat-focused .mat-form-field-required-marker {
  color: #ffab40;
}
.app.thm-yellow-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffab40;
}
.app.thm-yellow-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-yellow-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-yellow-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-error {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-yellow-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-yellow-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-yellow-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-yellow-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffab40;
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-yellow-light .mat-icon.mat-primary {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-icon.mat-accent {
  color: #ffab40;
}
.app.thm-yellow-light .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-input-element:disabled,
.app.thm-yellow-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-input-element {
  caret-color: #fbc02d;
}
.app.thm-yellow-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffab40;
}
.app.thm-yellow-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-yellow-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-yellow-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-yellow-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-yellow-light .mat-list-option:hover, .app.thm-yellow-light .mat-list-option:focus,
.app.thm-yellow-light .mat-nav-list .mat-list-item:hover,
.app.thm-yellow-light .mat-nav-list .mat-list-item:focus,
.app.thm-yellow-light .mat-action-list .mat-list-item:hover,
.app.thm-yellow-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-list-single-selected-option, .app.thm-yellow-light .mat-list-single-selected-option:hover, .app.thm-yellow-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-menu-panel {
  background: white;
}
.app.thm-yellow-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-menu-item[disabled], .app.thm-yellow-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-menu-item .mat-icon-no-color,
.app.thm-yellow-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-menu-item:hover:not([disabled]),
.app.thm-yellow-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-yellow-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-yellow-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-yellow-light .mat-paginator {
  background: white;
}
.app.thm-yellow-light .mat-paginator,
.app.thm-yellow-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-paginator-decrement,
.app.thm-yellow-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-paginator-first,
.app.thm-yellow-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-yellow-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-yellow-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-yellow-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.app.thm-yellow-light .mat-progress-bar-background {
  fill: #fffde7;
}
.app.thm-yellow-light .mat-progress-bar-buffer {
  background-color: #fffde7;
}
.app.thm-yellow-light .mat-progress-bar-fill::after {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffd180;
}
.app.thm-yellow-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffd180;
}
.app.thm-yellow-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-yellow-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-yellow-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-progress-spinner circle, .app.thm-yellow-light .mat-spinner circle {
  stroke: #fbc02d;
}
.app.thm-yellow-light .mat-progress-spinner.mat-accent circle, .app.thm-yellow-light .mat-spinner.mat-accent circle {
  stroke: #ffab40;
}
.app.thm-yellow-light .mat-progress-spinner.mat-warn circle, .app.thm-yellow-light .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-yellow-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fbc02d;
}
.app.thm-yellow-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-yellow-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-yellow-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-yellow-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffab40;
}
.app.thm-yellow-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-yellow-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-yellow-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-yellow-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-yellow-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-yellow-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-yellow-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-yellow-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-yellow-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-yellow-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-yellow-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-yellow-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-select-panel {
  background: white;
}
.app.thm-yellow-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #fbc02d;
}
.app.thm-yellow-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffab40;
}
.app.thm-yellow-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-yellow-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-yellow-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-yellow-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-yellow-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-yellow-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 64, 0.54);
}
.app.thm-yellow-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(251, 192, 45, 0.54);
}
.app.thm-yellow-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-yellow-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-yellow-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-yellow-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-primary .mat-slider-track-fill,
.app.thm-yellow-light .mat-primary .mat-slider-thumb,
.app.thm-yellow-light .mat-primary .mat-slider-thumb-label {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(251, 192, 45, 0.2);
}
.app.thm-yellow-light .mat-accent .mat-slider-track-fill,
.app.thm-yellow-light .mat-accent .mat-slider-thumb,
.app.thm-yellow-light .mat-accent .mat-slider-thumb-label {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 171, 64, 0.2);
}
.app.thm-yellow-light .mat-warn .mat-slider-track-fill,
.app.thm-yellow-light .mat-warn .mat-slider-thumb,
.app.thm-yellow-light .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-yellow-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-yellow-light .mat-slider:hover .mat-slider-track-background,
.app.thm-yellow-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-yellow-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-yellow-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-yellow-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-yellow-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-yellow-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-yellow-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-yellow-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-yellow-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-yellow-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-yellow-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-yellow-light .mat-step-header.cdk-keyboard-focused, .app.thm-yellow-light .mat-step-header.cdk-program-focused, .app.thm-yellow-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-yellow-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-yellow-light .mat-step-header .mat-step-label,
.app.thm-yellow-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-step-header .mat-step-icon-selected,
.app.thm-yellow-light .mat-step-header .mat-step-icon-state-done,
.app.thm-yellow-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-yellow-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-yellow-light .mat-stepper-horizontal, .app.thm-yellow-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-yellow-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-horizontal-stepper-header::before,
.app.thm-yellow-light .mat-horizontal-stepper-header::after,
.app.thm-yellow-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.app.thm-yellow-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-yellow-light .mat-tab-nav-bar,
.app.thm-yellow-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-yellow-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-yellow-light .mat-tab-label, .app.thm-yellow-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-yellow-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-yellow-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-yellow-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 253, 231, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-yellow-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 253, 231, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #fbc02d;
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffab40;
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-yellow-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-yellow-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-yellow-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-toolbar.mat-primary {
  background: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-toolbar.mat-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-yellow-light .mat-toolbar .mat-form-field-underline,
.app.thm-yellow-light .mat-toolbar .mat-form-field-ripple,
.app.thm-yellow-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-yellow-light .mat-toolbar .mat-form-field-label,
.app.thm-yellow-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-yellow-light .mat-toolbar .mat-select-value,
.app.thm-yellow-light .mat-toolbar .mat-select-arrow,
.app.thm-yellow-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-yellow-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.thm-yellow-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-yellow-light .mat-tree {
  background: white;
}
.app.thm-yellow-light .mat-tree-node,
.app.thm-yellow-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.app.thm-yellow-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .mat-simple-snackbar-action {
  color: #ffab40;
}
.app.thm-yellow-light .bg-primary {
  background: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .bg-primary-l-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .bg-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-yellow-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .primary-color {
  color: #fbc02d !important;
}
.app.thm-yellow-light .accent-color {
  color: #ffab40 !important;
}
.app.thm-yellow-light .warn-color {
  color: #ff5252 !important;
}
.app.thm-yellow-light .bg-card {
  background: white;
}
.app.thm-yellow-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-yellow-light .bg-white {
  background: #fff;
}
.app.thm-yellow-light .link-style {
  color: #ffab40;
  cursor: pointer;
}
.app.thm-yellow-light .card-style, .app.thm-yellow-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-yellow-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-yellow-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-yellow-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-yellow-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-yellow-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-yellow-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-yellow-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-yellow-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-yellow-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-yellow-light .ui-tree, .app.thm-gray-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-gray-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-yellow-light .ui-paginator, .app.thm-gray-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-yellow-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-yellow-light .ui-multiselect, .app.thm-gray-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-yellow-light .ui-tree, .app.thm-red-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-red-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-yellow-light .ui-paginator, .app.thm-red-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-yellow-light .ui-inputtext, .app.thm-red-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-yellow-light .ui-multiselect, .app.thm-red-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-yellow-light .ui-tree, .app.thm-teal-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-teal-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-yellow-light .ui-paginator, .app.thm-teal-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-yellow-light .ui-inputtext, .app.thm-teal-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-yellow-light .ui-multiselect, .app.thm-teal-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-yellow-light .ui-tree, .app.thm-indigo-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-indigo-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-yellow-light .ui-paginator, .app.thm-indigo-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-yellow-light .ui-inputtext, .app.thm-indigo-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-yellow-light .ui-multiselect, .app.thm-indigo-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-yellow-light .ui-tree, .app.thm-green-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-yellow-light .ui-table-caption,
.app.thm-green-light .ui-table .app.thm-yellow-light .ui-table-summary, .app.thm-yellow-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-yellow-light .ui-paginator, .app.thm-green-light .ui-tabview .app.thm-yellow-light .ui-tabview-panels, .app.thm-yellow-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-yellow-light .ui-inputtext, .app.thm-green-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box, .app.thm-yellow-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-yellow-light .ui-multiselect, .app.thm-green-light .ui-dialog .app.thm-yellow-light .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-yellow-light .ui-dialog-content, .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .ui-table-caption,
.app.thm-yellow-light .ui-table .ui-table-summary, .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .ui-dialog-content {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-red-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-teal-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-teal-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-indigo-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-green-light .ui-dropdown .app.thm-yellow-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item, .app.thm-green-light .ui-multiselect-panel .app.thm-yellow-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-yellow-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-dropdown .ui-dropdown-trigger, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .ui-multiselect-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-teal-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-indigo-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-yellow-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-yellow-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-yellow-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-yellow-light li.ui-state-active, .app.thm-green-light .ui-chkbox .app.thm-yellow-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-yellow-light .ui-chkbox .ui-chkbox-box.ui-state-active {
  background: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-yellow-light .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  background: #ffee58;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-yellow-light .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .border-error-style, .app.thm-yellow-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-yellow-light .clv-bg-danger, .app.thm-yellow-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-yellow-light .clv-color-danger, .app.thm-yellow-light .danger-color {
  color: #d32f2f;
}
.app.thm-yellow-light .clv-bg-danger-imp, .app.thm-yellow-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-danger-imp, .app.thm-yellow-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-yellow-light .clv-bg-success, .app.thm-yellow-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-yellow-light .clv-color-success, .app.thm-yellow-light .success-color {
  color: #388e3c;
}
.app.thm-yellow-light .clv-bg-success-imp, .app.thm-yellow-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-success-imp, .app.thm-yellow-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-yellow-light .clv-bg-warning, .app.thm-yellow-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .clv-color-warning, .app.thm-yellow-light .warning-color {
  color: #fbc02d;
}
.app.thm-yellow-light .clv-bg-warning-imp, .app.thm-yellow-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-yellow-light .clv-color-warning-imp, .app.thm-yellow-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-yellow-light .clv-bg-orange, .app.thm-yellow-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .clv-color-orange, .app.thm-yellow-light .orange-color {
  color: #ffa000;
}
.app.thm-yellow-light .clv-bg-orange-imp, .app.thm-yellow-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-yellow-light .clv-color-orange-imp, .app.thm-yellow-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-yellow-light .clv-bg-info, .app.thm-yellow-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-yellow-light .clv-color-info, .app.thm-yellow-light .info-color {
  color: #0288d1;
}
.app.thm-yellow-light .clv-bg-info-imp, .app.thm-yellow-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-info-imp, .app.thm-yellow-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-yellow-light .clv-bg-blue, .app.thm-yellow-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-yellow-light .clv-color-blue, .app.thm-yellow-light .blue-color {
  color: #1976d2;
}
.app.thm-yellow-light .clv-bg-blue-imp, .app.thm-yellow-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-blue-imp, .app.thm-yellow-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-yellow-light .clv-bg-indigo, .app.thm-yellow-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-yellow-light .clv-color-indigo, .app.thm-yellow-light .indigo-color {
  color: #303f9f;
}
.app.thm-yellow-light .clv-bg-indigo-imp, .app.thm-yellow-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-indigo-imp, .app.thm-yellow-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-yellow-light .clv-bg-brown, .app.thm-yellow-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-yellow-light .clv-color-brown, .app.thm-yellow-light .brown-color {
  color: #5d4037;
}
.app.thm-yellow-light .clv-bg-brown-imp, .app.thm-yellow-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-yellow-light .clv-color-brown-imp, .app.thm-yellow-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-yellow-light .connexion-titre {
  color: #fbc02d;
}
.app.thm-yellow-light .header {
  background: white;
  color: black;
}
.app.thm-yellow-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .content-header h4 {
  color: #fffde7;
}
.app.thm-yellow-light .favorites-container:after {
  color: #ffab40;
}
.app.thm-yellow-light .horizontal-menu {
  background: white;
}
.app.thm-yellow-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-yellow-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #fbc02d;
  color: #fff;
}
.app.thm-yellow-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .menu-item .mat-button.active-link .menu-icon {
  background: #fbc02d;
  color: #fff;
}
.app.thm-yellow-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-yellow-light .breadcrumb a {
  color: #fffde7;
}
.app.thm-yellow-light .top-toolbar, .app.thm-yellow-light .landing-toolbar {
  background: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .menu-toolbar {
  background: #f57f17;
  color: #fff9c4;
}
.app.thm-yellow-light .ps__thumb-y {
  background-color: #fbc02d;
}
.app.thm-yellow-light .testimonial-item, .app.thm-yellow-light .pricing-card {
  border-top: 4px solid #ffab40;
}
.app.thm-yellow-light .login-container {
  background: #fafafa;
}
.app.thm-yellow-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-yellow-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-yellow-light .annular-pagination .ngx-pagination .current {
  background: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .annular-pagination .ngx-pagination a:hover, .app.thm-yellow-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(251, 192, 45, 0.2);
  color: black;
}
.app.thm-yellow-light .cal-day-view .cal-hour-segment:hover,
.app.thm-yellow-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-yellow-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-yellow-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-yellow-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-yellow-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(251, 192, 45, 0.4);
}
.app.thm-yellow-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(251, 192, 45, 0.05);
}
.app.thm-yellow-light .cal-week-view .cal-header.cal-today,
.app.thm-yellow-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(251, 192, 45, 0.4);
}
.app.thm-yellow-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-yellow-light .cal-event-action i {
  margin: 4px;
}
.app.thm-yellow-light .ql-snow .ql-picker,
.app.thm-yellow-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-yellow-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-yellow-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-yellow-light .ui-dialog .ui-dialog-titlebar {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .ui-button {
  color: !important;
  background-color: #fbc02d;
  border: 1px solid #fbc02d;
}
.app.thm-yellow-light .ui-button.ui-button-primary {
  background-color: #fbc02d;
  border: 1px solid #fbc02d;
}
.app.thm-yellow-light .ui-button.ui-button-secondary {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ffab40;
}
.app.thm-yellow-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-yellow-light .ui-multiselect {
  display: block;
}
.app.thm-yellow-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-yellow-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .form-separator {
  background: #fbc02d;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-yellow-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-yellow-light .titre-in-table {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.app.thm-yellow-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-green-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-green-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-option:hover:not(.mat-option-disabled), .app.thm-green-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #388e3c;
}
.app.thm-green-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffab40;
}
.app.thm-green-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-green-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-green-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-green-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-green-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #388e3c;
}
.app.thm-green-light .mat-pseudo-checkbox-checked,
.app.thm-green-light .mat-pseudo-checkbox-indeterminate,
.app.thm-green-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-green-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffab40;
}
.app.thm-green-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-green-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-green-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-green-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-green-light .mat-app-background, .app.thm-green-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-green-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-green-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-badge-content {
  color: white;
  background: #388e3c;
}
.cdk-high-contrast-active .app.thm-green-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-green-light .mat-badge-accent .mat-badge-content {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-green-light .mat-badge {
  position: relative;
}
.app.thm-green-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-green-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-green-light .ng-animate-disabled .mat-badge-content,
.app.thm-green-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-green-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-green-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-green-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-green-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-green-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-green-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-green-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-green-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-green-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-green-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-green-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-green-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-green-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-green-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-green-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-green-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-green-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-green-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-green-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-green-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-green-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-green-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-green-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-green-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-green-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-green-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-green-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-green-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-green-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-green-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-button, .app.thm-green-light .mat-icon-button, .app.thm-green-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-green-light .mat-button.mat-primary, .app.thm-green-light .mat-icon-button.mat-primary, .app.thm-green-light .mat-stroked-button.mat-primary {
  color: #388e3c;
}
.app.thm-green-light .mat-button.mat-accent, .app.thm-green-light .mat-icon-button.mat-accent, .app.thm-green-light .mat-stroked-button.mat-accent {
  color: #ffab40;
}
.app.thm-green-light .mat-button.mat-warn, .app.thm-green-light .mat-icon-button.mat-warn, .app.thm-green-light .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-green-light .mat-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-green-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-green-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #388e3c;
}
.app.thm-green-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-green-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-green-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffab40;
}
.app.thm-green-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-green-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-green-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-green-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-green-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-green-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-green-light .mat-button .mat-ripple-element, .app.thm-green-light .mat-icon-button .mat-ripple-element, .app.thm-green-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-green-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-green-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-flat-button, .app.thm-green-light .mat-raised-button, .app.thm-green-light .mat-fab, .app.thm-green-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-green-light .mat-flat-button.mat-primary, .app.thm-green-light .mat-raised-button.mat-primary, .app.thm-green-light .mat-fab.mat-primary, .app.thm-green-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-green-light .mat-flat-button.mat-accent, .app.thm-green-light .mat-raised-button.mat-accent, .app.thm-green-light .mat-fab.mat-accent, .app.thm-green-light .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-flat-button.mat-warn, .app.thm-green-light .mat-raised-button.mat-warn, .app.thm-green-light .mat-fab.mat-warn, .app.thm-green-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-green-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-green-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-green-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-green-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-flat-button.mat-primary, .app.thm-green-light .mat-raised-button.mat-primary, .app.thm-green-light .mat-fab.mat-primary, .app.thm-green-light .mat-mini-fab.mat-primary {
  background-color: #388e3c;
}
.app.thm-green-light .mat-flat-button.mat-accent, .app.thm-green-light .mat-raised-button.mat-accent, .app.thm-green-light .mat-fab.mat-accent, .app.thm-green-light .mat-mini-fab.mat-accent {
  background-color: #ffab40;
}
.app.thm-green-light .mat-flat-button.mat-warn, .app.thm-green-light .mat-raised-button.mat-warn, .app.thm-green-light .mat-fab.mat-warn, .app.thm-green-light .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-green-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-green-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-green-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-green-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-green-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-green-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-green-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-green-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-green-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-green-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-green-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-green-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-green-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-green-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-green-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-green-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-green-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-green-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-green-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-green-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-button-toggle-standalone,
.app.thm-green-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-green-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-green-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-green-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-green-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-green-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-green-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-green-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-green-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-green-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-green-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-green-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-green-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #388e3c;
}
.app.thm-green-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-green-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffab40;
}
.app.thm-green-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-green-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-green-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-green-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-green-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-green-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-green-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-green-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #388e3c;
}
.app.thm-green-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-green-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffab40;
}
.app.thm-green-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-green-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-green-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-green-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-green-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-green-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-green-light .mat-table {
  background: white;
}
.app.thm-green-light .mat-table thead, .app.thm-green-light .mat-table tbody, .app.thm-green-light .mat-table tfoot,
.app.thm-green-light mat-header-row, .app.thm-green-light mat-row, .app.thm-green-light mat-footer-row,
.app.thm-green-light [mat-header-row], .app.thm-green-light [mat-row], .app.thm-green-light [mat-footer-row],
.app.thm-green-light .mat-table-sticky {
  background: inherit;
}
.app.thm-green-light mat-row, .app.thm-green-light mat-header-row, .app.thm-green-light mat-footer-row,
.app.thm-green-light th.mat-header-cell, .app.thm-green-light td.mat-cell, .app.thm-green-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-cell, .app.thm-green-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-datepicker-toggle,
.app.thm-green-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-green-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-calendar-body-cell-content,
.app.thm-green-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-green-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-green-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-green-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-green-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-green-light .mat-calendar-body-in-range::before {
  background: rgba(56, 142, 60, 0.2);
}
.app.thm-green-light .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-light .mat-calendar-body-selected {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-green-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 171, 64, 0.2);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 171, 64, 0.4);
}
.app.thm-green-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-green-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-green-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-datepicker-toggle-active {
  color: #388e3c;
}
.app.thm-green-light .mat-datepicker-toggle-active.mat-accent {
  color: #ffab40;
}
.app.thm-green-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-green-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-green-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-green-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-green-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-green-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-expansion-panel-header-description,
.app.thm-green-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-green-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-green-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-green-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #388e3c;
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffab40;
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-green-light .mat-focused .mat-form-field-required-marker {
  color: #ffab40;
}
.app.thm-green-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #388e3c;
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffab40;
}
.app.thm-green-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-green-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #388e3c;
}
.app.thm-green-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffab40;
}
.app.thm-green-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-green-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-green-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-green-light .mat-error {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-green-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-green-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-green-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-green-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #388e3c;
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffab40;
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-green-light .mat-icon.mat-primary {
  color: #388e3c;
}
.app.thm-green-light .mat-icon.mat-accent {
  color: #ffab40;
}
.app.thm-green-light .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-input-element:disabled,
.app.thm-green-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-input-element {
  caret-color: #388e3c;
}
.app.thm-green-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffab40;
}
.app.thm-green-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-green-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-green-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-green-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-green-light .mat-list-option:hover, .app.thm-green-light .mat-list-option:focus,
.app.thm-green-light .mat-nav-list .mat-list-item:hover,
.app.thm-green-light .mat-nav-list .mat-list-item:focus,
.app.thm-green-light .mat-action-list .mat-list-item:hover,
.app.thm-green-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-list-single-selected-option, .app.thm-green-light .mat-list-single-selected-option:hover, .app.thm-green-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-menu-panel {
  background: white;
}
.app.thm-green-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-menu-item[disabled], .app.thm-green-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-menu-item .mat-icon-no-color,
.app.thm-green-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-menu-item:hover:not([disabled]),
.app.thm-green-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-green-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-green-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-green-light .mat-paginator {
  background: white;
}
.app.thm-green-light .mat-paginator,
.app.thm-green-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-paginator-decrement,
.app.thm-green-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-paginator-first,
.app.thm-green-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-green-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-green-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-green-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-progress-bar-background {
  fill: #e8f5e9;
}
.app.thm-green-light .mat-progress-bar-buffer {
  background-color: #e8f5e9;
}
.app.thm-green-light .mat-progress-bar-fill::after {
  background-color: #388e3c;
}
.app.thm-green-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffd180;
}
.app.thm-green-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffd180;
}
.app.thm-green-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffab40;
}
.app.thm-green-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-green-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-green-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-green-light .mat-progress-spinner circle, .app.thm-green-light .mat-spinner circle {
  stroke: #388e3c;
}
.app.thm-green-light .mat-progress-spinner.mat-accent circle, .app.thm-green-light .mat-spinner.mat-accent circle {
  stroke: #ffab40;
}
.app.thm-green-light .mat-progress-spinner.mat-warn circle, .app.thm-green-light .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-green-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #388e3c;
}
.app.thm-green-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-green-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #388e3c;
}
.app.thm-green-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffab40;
}
.app.thm-green-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-green-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffab40;
}
.app.thm-green-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-green-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-green-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-green-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-green-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-green-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-green-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-green-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-select-panel {
  background: white;
}
.app.thm-green-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #388e3c;
}
.app.thm-green-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffab40;
}
.app.thm-green-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-green-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-green-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-green-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-green-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-green-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab40;
}
.app.thm-green-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 64, 0.54);
}
.app.thm-green-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffab40;
}
.app.thm-green-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #388e3c;
}
.app.thm-green-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(56, 142, 60, 0.54);
}
.app.thm-green-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #388e3c;
}
.app.thm-green-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-green-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-green-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-green-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-green-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-green-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-primary .mat-slider-track-fill,
.app.thm-green-light .mat-primary .mat-slider-thumb,
.app.thm-green-light .mat-primary .mat-slider-thumb-label {
  background-color: #388e3c;
}
.app.thm-green-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-green-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(56, 142, 60, 0.2);
}
.app.thm-green-light .mat-accent .mat-slider-track-fill,
.app.thm-green-light .mat-accent .mat-slider-thumb,
.app.thm-green-light .mat-accent .mat-slider-thumb-label {
  background-color: #ffab40;
}
.app.thm-green-light .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 171, 64, 0.2);
}
.app.thm-green-light .mat-warn .mat-slider-track-fill,
.app.thm-green-light .mat-warn .mat-slider-thumb,
.app.thm-green-light .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-green-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-green-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-green-light .mat-slider:hover .mat-slider-track-background,
.app.thm-green-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-green-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-green-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-green-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-green-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-green-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-green-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-green-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-green-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-green-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-green-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-green-light .mat-step-header.cdk-keyboard-focused, .app.thm-green-light .mat-step-header.cdk-program-focused, .app.thm-green-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-green-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-green-light .mat-step-header .mat-step-label,
.app.thm-green-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-green-light .mat-step-header .mat-step-icon-selected,
.app.thm-green-light .mat-step-header .mat-step-icon-state-done,
.app.thm-green-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-green-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-green-light .mat-stepper-horizontal, .app.thm-green-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-green-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-horizontal-stepper-header::before,
.app.thm-green-light .mat-horizontal-stepper-header::after,
.app.thm-green-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-green-light .mat-tab-nav-bar,
.app.thm-green-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-green-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-green-light .mat-tab-label, .app.thm-green-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-green-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-green-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #388e3c;
}
.app.thm-green-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-green-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffab40;
}
.app.thm-green-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-green-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-green-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #388e3c;
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-green-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffab40;
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-green-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-green-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-toolbar.mat-primary {
  background: #388e3c;
  color: white;
}
.app.thm-green-light .mat-toolbar.mat-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-green-light .mat-toolbar .mat-form-field-underline,
.app.thm-green-light .mat-toolbar .mat-form-field-ripple,
.app.thm-green-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-green-light .mat-toolbar .mat-form-field-label,
.app.thm-green-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-green-light .mat-toolbar .mat-select-value,
.app.thm-green-light .mat-toolbar .mat-select-arrow,
.app.thm-green-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-green-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-green-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-green-light .mat-tree {
  background: white;
}
.app.thm-green-light .mat-tree-node,
.app.thm-green-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .mat-simple-snackbar-action {
  color: #ffab40;
}
.app.thm-green-light .bg-primary {
  background: #388e3c;
  color: white;
}
.app.thm-green-light .bg-primary-l-400 {
  background-color: #66bb6a;
  color: white;
}
.app.thm-green-light .bg-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-green-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .primary-color {
  color: #388e3c !important;
}
.app.thm-green-light .accent-color {
  color: #ffab40 !important;
}
.app.thm-green-light .warn-color {
  color: #ff5252 !important;
}
.app.thm-green-light .bg-card {
  background: white;
}
.app.thm-green-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-green-light .bg-white {
  background: #fff;
}
.app.thm-green-light .link-style {
  color: #ffab40;
  cursor: pointer;
}
.app.thm-green-light .card-style, .app.thm-green-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-green-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-green-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-green-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-green-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-green-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-green-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-green-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-green-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-green-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-green-light .ui-tree, .app.thm-gray-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-gray-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-green-light .ui-paginator, .app.thm-gray-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-green-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-green-light .ui-multiselect, .app.thm-gray-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-green-light .ui-tree, .app.thm-red-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-red-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-green-light .ui-paginator, .app.thm-red-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-green-light .ui-inputtext, .app.thm-red-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-green-light .ui-multiselect, .app.thm-red-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-green-light .ui-tree, .app.thm-teal-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-teal-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-green-light .ui-paginator, .app.thm-teal-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-green-light .ui-inputtext, .app.thm-teal-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-green-light .ui-multiselect, .app.thm-teal-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-green-light .ui-tree, .app.thm-indigo-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-indigo-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-green-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-green-light .ui-paginator, .app.thm-indigo-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-green-light .ui-inputtext, .app.thm-indigo-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-green-light .ui-multiselect, .app.thm-indigo-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-green-light .ui-tree, .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .ui-table-caption,
.app.thm-green-light .ui-table .ui-table-summary, .app.thm-green-light .ui-paginator, .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-inputtext, .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .ui-multiselect, .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-green-light .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-green-light .ui-dialog-footer, .app.thm-green-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-green-light .ui-multiselect-panel, .app.thm-yellow-light .ui-chkbox .app.thm-green-light .ui-chkbox-box, .app.thm-green-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-green-light .ui-inputtext, .app.thm-yellow-light .ui-tabview .app.thm-green-light .ui-tabview-panels, .app.thm-green-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-green-light .ui-paginator, .app.thm-yellow-light .ui-table .app.thm-green-light .ui-table-caption,
.app.thm-yellow-light .ui-table .app.thm-green-light .ui-table-summary, .app.thm-yellow-light .ui-table .app.thm-green-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-green-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-red-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-teal-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-teal-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-indigo-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-green-light .ui-dropdown .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-green-light .ui-multiselect .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-green-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-green-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-green-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-green-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-teal-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-indigo-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-green-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-green-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-light .ui-treenode-label.ui-state-highlight {
  background: #388e3c;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-light .ui-multiselect-item.ui-state-highlight {
  background: #66bb6a;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-green-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .border-error-style, .app.thm-green-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-green-light .clv-bg-danger, .app.thm-green-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-green-light .clv-color-danger, .app.thm-green-light .danger-color {
  color: #d32f2f;
}
.app.thm-green-light .clv-bg-danger-imp, .app.thm-green-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-danger-imp, .app.thm-green-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-green-light .clv-bg-success, .app.thm-green-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-green-light .clv-color-success, .app.thm-green-light .success-color {
  color: #388e3c;
}
.app.thm-green-light .clv-bg-success-imp, .app.thm-green-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-success-imp, .app.thm-green-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-green-light .clv-bg-warning, .app.thm-green-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .clv-color-warning, .app.thm-green-light .warning-color {
  color: #fbc02d;
}
.app.thm-green-light .clv-bg-warning-imp, .app.thm-green-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-green-light .clv-color-warning-imp, .app.thm-green-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-green-light .clv-bg-orange, .app.thm-green-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .clv-color-orange, .app.thm-green-light .orange-color {
  color: #ffa000;
}
.app.thm-green-light .clv-bg-orange-imp, .app.thm-green-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-green-light .clv-color-orange-imp, .app.thm-green-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-green-light .clv-bg-info, .app.thm-green-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-green-light .clv-color-info, .app.thm-green-light .info-color {
  color: #0288d1;
}
.app.thm-green-light .clv-bg-info-imp, .app.thm-green-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-info-imp, .app.thm-green-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-green-light .clv-bg-blue, .app.thm-green-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-green-light .clv-color-blue, .app.thm-green-light .blue-color {
  color: #1976d2;
}
.app.thm-green-light .clv-bg-blue-imp, .app.thm-green-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-blue-imp, .app.thm-green-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-green-light .clv-bg-indigo, .app.thm-green-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-green-light .clv-color-indigo, .app.thm-green-light .indigo-color {
  color: #303f9f;
}
.app.thm-green-light .clv-bg-indigo-imp, .app.thm-green-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-indigo-imp, .app.thm-green-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-green-light .clv-bg-brown, .app.thm-green-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-green-light .clv-color-brown, .app.thm-green-light .brown-color {
  color: #5d4037;
}
.app.thm-green-light .clv-bg-brown-imp, .app.thm-green-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-green-light .clv-color-brown-imp, .app.thm-green-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-green-light .connexion-titre {
  color: #388e3c;
}
.app.thm-green-light .header {
  background: white;
  color: black;
}
.app.thm-green-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .content-header h4 {
  color: #e8f5e9;
}
.app.thm-green-light .favorites-container:after {
  color: #ffab40;
}
.app.thm-green-light .horizontal-menu {
  background: white;
}
.app.thm-green-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-green-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.thm-green-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .menu-item .mat-button.active-link .menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.thm-green-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-green-light .breadcrumb a {
  color: #e8f5e9;
}
.app.thm-green-light .top-toolbar, .app.thm-green-light .landing-toolbar {
  background: white;
}
.app.thm-green-light .menu-toolbar {
  background: #1b5e20;
  color: #c8e6c9;
}
.app.thm-green-light .ps__thumb-y {
  background-color: #388e3c;
}
.app.thm-green-light .testimonial-item, .app.thm-green-light .pricing-card {
  border-top: 4px solid #ffab40;
}
.app.thm-green-light .login-container {
  background: #fafafa;
}
.app.thm-green-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-green-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-green-light .annular-pagination .ngx-pagination .current {
  background: #388e3c;
  color: white;
}
.app.thm-green-light .annular-pagination .ngx-pagination a:hover, .app.thm-green-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(56, 142, 60, 0.2);
  color: black;
}
.app.thm-green-light .cal-day-view .cal-hour-segment:hover,
.app.thm-green-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-green-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-green-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-green-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-green-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(56, 142, 60, 0.05);
}
.app.thm-green-light .cal-week-view .cal-header.cal-today,
.app.thm-green-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-green-light .cal-event-action i {
  margin: 4px;
}
.app.thm-green-light .ql-snow .ql-picker,
.app.thm-green-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-green-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-green-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-green-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-green-light .ui-dialog .ui-dialog-titlebar {
  background-color: #388e3c;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-green-light .ui-button {
  color: !important;
  background-color: #388e3c;
  border: 1px solid #388e3c;
}
.app.thm-green-light .ui-button.ui-button-primary {
  background-color: #388e3c;
  border: 1px solid #388e3c;
}
.app.thm-green-light .ui-button.ui-button-secondary {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ffab40;
}
.app.thm-green-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-green-light .ui-multiselect {
  display: block;
}
.app.thm-green-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-green-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-green-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .form-separator {
  background: #388e3c;
  text-align: center;
  color: white;
}
.app.thm-green-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-light .titre-in-table {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.app.thm-green-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-indigo-light .footer.bg-footer {
  background: #cccccc;
  color: #333333;
}
.app.thm-indigo-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-indigo-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-option:hover:not(.mat-option-disabled), .app.thm-indigo-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #303f9f;
}
.app.thm-indigo-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.thm-indigo-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.thm-indigo-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-indigo-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-indigo-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-indigo-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #303f9f;
}
.app.thm-indigo-light .mat-pseudo-checkbox-checked,
.app.thm-indigo-light .mat-pseudo-checkbox-indeterminate,
.app.thm-indigo-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-indigo-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.thm-indigo-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-indigo-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.thm-indigo-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-indigo-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-indigo-light .mat-app-background, .app.thm-indigo-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-indigo-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-indigo-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-badge-content {
  color: white;
  background: #303f9f;
}
.cdk-high-contrast-active .app.thm-indigo-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-indigo-light .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.thm-indigo-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.thm-indigo-light .mat-badge {
  position: relative;
}
.app.thm-indigo-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-indigo-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-indigo-light .ng-animate-disabled .mat-badge-content,
.app.thm-indigo-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-indigo-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-indigo-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-indigo-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-indigo-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-indigo-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-button, .app.thm-indigo-light .mat-icon-button, .app.thm-indigo-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-indigo-light .mat-button.mat-primary, .app.thm-indigo-light .mat-icon-button.mat-primary, .app.thm-indigo-light .mat-stroked-button.mat-primary {
  color: #303f9f;
}
.app.thm-indigo-light .mat-button.mat-accent, .app.thm-indigo-light .mat-icon-button.mat-accent, .app.thm-indigo-light .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.thm-indigo-light .mat-button.mat-warn, .app.thm-indigo-light .mat-icon-button.mat-warn, .app.thm-indigo-light .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.thm-indigo-light .mat-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-indigo-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-indigo-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-indigo-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-indigo-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-indigo-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-indigo-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-indigo-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-indigo-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-indigo-light .mat-button .mat-ripple-element, .app.thm-indigo-light .mat-icon-button .mat-ripple-element, .app.thm-indigo-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-indigo-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-indigo-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-flat-button, .app.thm-indigo-light .mat-raised-button, .app.thm-indigo-light .mat-fab, .app.thm-indigo-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-indigo-light .mat-flat-button.mat-primary, .app.thm-indigo-light .mat-raised-button.mat-primary, .app.thm-indigo-light .mat-fab.mat-primary, .app.thm-indigo-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-indigo-light .mat-flat-button.mat-accent, .app.thm-indigo-light .mat-raised-button.mat-accent, .app.thm-indigo-light .mat-fab.mat-accent, .app.thm-indigo-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-indigo-light .mat-flat-button.mat-warn, .app.thm-indigo-light .mat-raised-button.mat-warn, .app.thm-indigo-light .mat-fab.mat-warn, .app.thm-indigo-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-indigo-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-flat-button.mat-primary, .app.thm-indigo-light .mat-raised-button.mat-primary, .app.thm-indigo-light .mat-fab.mat-primary, .app.thm-indigo-light .mat-mini-fab.mat-primary {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-flat-button.mat-accent, .app.thm-indigo-light .mat-raised-button.mat-accent, .app.thm-indigo-light .mat-fab.mat-accent, .app.thm-indigo-light .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-flat-button.mat-warn, .app.thm-indigo-light .mat-raised-button.mat-warn, .app.thm-indigo-light .mat-fab.mat-warn, .app.thm-indigo-light .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-indigo-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-indigo-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-indigo-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-indigo-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-indigo-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-indigo-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-indigo-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-indigo-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-indigo-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-indigo-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-indigo-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-indigo-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-indigo-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-indigo-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-button-toggle-standalone,
.app.thm-indigo-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-indigo-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-indigo-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-indigo-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-indigo-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-indigo-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-indigo-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-indigo-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-indigo-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-indigo-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-indigo-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-indigo-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-indigo-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-indigo-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-indigo-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-indigo-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-indigo-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-indigo-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #303f9f;
}
.app.thm-indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.thm-indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #303f9f;
  color: white;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-indigo-light .mat-table {
  background: white;
}
.app.thm-indigo-light .mat-table thead, .app.thm-indigo-light .mat-table tbody, .app.thm-indigo-light .mat-table tfoot,
.app.thm-indigo-light mat-header-row, .app.thm-indigo-light mat-row, .app.thm-indigo-light mat-footer-row,
.app.thm-indigo-light [mat-header-row], .app.thm-indigo-light [mat-row], .app.thm-indigo-light [mat-footer-row],
.app.thm-indigo-light .mat-table-sticky {
  background: inherit;
}
.app.thm-indigo-light mat-row, .app.thm-indigo-light mat-header-row, .app.thm-indigo-light mat-footer-row,
.app.thm-indigo-light th.mat-header-cell, .app.thm-indigo-light td.mat-cell, .app.thm-indigo-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-cell, .app.thm-indigo-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-datepicker-toggle,
.app.thm-indigo-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-indigo-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-calendar-body-cell-content,
.app.thm-indigo-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-indigo-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-indigo-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-indigo-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-indigo-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-indigo-light .mat-calendar-body-in-range::before {
  background: rgba(48, 63, 159, 0.2);
}
.app.thm-indigo-light .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-indigo-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-indigo-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(48, 63, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-indigo-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(48, 63, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-indigo-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-indigo-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-indigo-light .mat-calendar-body-selected {
  background-color: #303f9f;
  color: white;
}
.app.thm-indigo-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.thm-indigo-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-indigo-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-indigo-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-indigo-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.thm-indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-indigo-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-indigo-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.thm-indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-indigo-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-datepicker-toggle-active {
  color: #303f9f;
}
.app.thm-indigo-light .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.thm-indigo-light .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.thm-indigo-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-indigo-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-indigo-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-indigo-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-indigo-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-expansion-panel-header-description,
.app.thm-indigo-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-indigo-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-indigo-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-indigo-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #303f9f;
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.thm-indigo-light .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.thm-indigo-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #303f9f;
}
.app.thm-indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.thm-indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-error {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-indigo-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-indigo-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-indigo-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-indigo-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #303f9f;
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-indigo-light .mat-icon.mat-primary {
  color: #303f9f;
}
.app.thm-indigo-light .mat-icon.mat-accent {
  color: #ff4081;
}
.app.thm-indigo-light .mat-icon.mat-warn {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-input-element:disabled,
.app.thm-indigo-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-input-element {
  caret-color: #303f9f;
}
.app.thm-indigo-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.thm-indigo-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-indigo-light .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.thm-indigo-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-indigo-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-indigo-light .mat-list-option:hover, .app.thm-indigo-light .mat-list-option:focus,
.app.thm-indigo-light .mat-nav-list .mat-list-item:hover,
.app.thm-indigo-light .mat-nav-list .mat-list-item:focus,
.app.thm-indigo-light .mat-action-list .mat-list-item:hover,
.app.thm-indigo-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-list-single-selected-option, .app.thm-indigo-light .mat-list-single-selected-option:hover, .app.thm-indigo-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-menu-panel {
  background: white;
}
.app.thm-indigo-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-menu-item[disabled], .app.thm-indigo-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-menu-item .mat-icon-no-color,
.app.thm-indigo-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-menu-item:hover:not([disabled]),
.app.thm-indigo-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-indigo-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-indigo-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-indigo-light .mat-paginator {
  background: white;
}
.app.thm-indigo-light .mat-paginator,
.app.thm-indigo-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-paginator-decrement,
.app.thm-indigo-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-paginator-first,
.app.thm-indigo-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-indigo-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-indigo-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-indigo-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-progress-bar-background {
  fill: #e8eaf6;
}
.app.thm-indigo-light .mat-progress-bar-buffer {
  background-color: #e8eaf6;
}
.app.thm-indigo-light .mat-progress-bar-fill::after {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab;
}
.app.thm-indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab;
}
.app.thm-indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-progress-spinner circle, .app.thm-indigo-light .mat-spinner circle {
  stroke: #303f9f;
}
.app.thm-indigo-light .mat-progress-spinner.mat-accent circle, .app.thm-indigo-light .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.thm-indigo-light .mat-progress-spinner.mat-warn circle, .app.thm-indigo-light .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.thm-indigo-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #303f9f;
}
.app.thm-indigo-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-indigo-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-indigo-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-indigo-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.thm-indigo-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-indigo-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-indigo-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-indigo-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.thm-indigo-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-indigo-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-indigo-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-indigo-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-indigo-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-indigo-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-select-panel {
  background: white;
}
.app.thm-indigo-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #303f9f;
}
.app.thm-indigo-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.thm-indigo-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.thm-indigo-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-indigo-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-indigo-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-indigo-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-indigo-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.thm-indigo-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(48, 63, 159, 0.54);
}
.app.thm-indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.thm-indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-indigo-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-indigo-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-primary .mat-slider-track-fill,
.app.thm-indigo-light .mat-primary .mat-slider-thumb,
.app.thm-indigo-light .mat-primary .mat-slider-thumb-label {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-indigo-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(48, 63, 159, 0.2);
}
.app.thm-indigo-light .mat-accent .mat-slider-track-fill,
.app.thm-indigo-light .mat-accent .mat-slider-thumb,
.app.thm-indigo-light .mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-indigo-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.thm-indigo-light .mat-warn .mat-slider-track-fill,
.app.thm-indigo-light .mat-warn .mat-slider-thumb,
.app.thm-indigo-light .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-indigo-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.thm-indigo-light .mat-slider:hover .mat-slider-track-background,
.app.thm-indigo-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-indigo-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-indigo-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-indigo-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-indigo-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-indigo-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-indigo-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-indigo-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-indigo-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-indigo-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-indigo-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-indigo-light .mat-step-header.cdk-keyboard-focused, .app.thm-indigo-light .mat-step-header.cdk-program-focused, .app.thm-indigo-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-indigo-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-indigo-light .mat-step-header .mat-step-label,
.app.thm-indigo-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-indigo-light .mat-step-header .mat-step-icon-selected,
.app.thm-indigo-light .mat-step-header .mat-step-icon-state-done,
.app.thm-indigo-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #303f9f;
  color: white;
}
.app.thm-indigo-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.thm-indigo-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.thm-indigo-light .mat-stepper-horizontal, .app.thm-indigo-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-indigo-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-horizontal-stepper-header::before,
.app.thm-indigo-light .mat-horizontal-stepper-header::after,
.app.thm-indigo-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-indigo-light .mat-tab-nav-bar,
.app.thm-indigo-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-indigo-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-indigo-light .mat-tab-label, .app.thm-indigo-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-indigo-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-indigo-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-indigo-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 234, 246, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-indigo-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 234, 246, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #303f9f;
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-indigo-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-indigo-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-indigo-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-toolbar.mat-primary {
  background: #303f9f;
  color: white;
}
.app.thm-indigo-light .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.thm-indigo-light .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.thm-indigo-light .mat-toolbar .mat-form-field-underline,
.app.thm-indigo-light .mat-toolbar .mat-form-field-ripple,
.app.thm-indigo-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-indigo-light .mat-toolbar .mat-form-field-label,
.app.thm-indigo-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-indigo-light .mat-toolbar .mat-select-value,
.app.thm-indigo-light .mat-toolbar .mat-select-arrow,
.app.thm-indigo-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-indigo-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-indigo-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-indigo-light .mat-tree {
  background: white;
}
.app.thm-indigo-light .mat-tree-node,
.app.thm-indigo-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .mat-simple-snackbar-action {
  color: #ff4081;
}
.app.thm-indigo-light .bg-primary {
  background: #303f9f;
  color: white;
}
.app.thm-indigo-light .bg-primary-l-400 {
  background-color: #5c6bc0;
  color: white;
}
.app.thm-indigo-light .bg-accent {
  background: #ff4081;
  color: white;
}
.app.thm-indigo-light .bg-warn {
  background: #f44336;
  color: white;
}
.app.thm-indigo-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .primary-color {
  color: #303f9f !important;
}
.app.thm-indigo-light .accent-color {
  color: #ff4081 !important;
}
.app.thm-indigo-light .warn-color {
  color: #f44336 !important;
}
.app.thm-indigo-light .bg-card {
  background: white;
}
.app.thm-indigo-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-indigo-light .bg-white {
  background: #fff;
}
.app.thm-indigo-light .link-style {
  color: #ff4081;
  cursor: pointer;
}
.app.thm-indigo-light .card-style, .app.thm-indigo-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-indigo-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-indigo-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-indigo-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-indigo-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-indigo-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-indigo-light .ui-tree, .app.thm-gray-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-gray-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-indigo-light .ui-paginator, .app.thm-gray-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-indigo-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-indigo-light .ui-multiselect, .app.thm-gray-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-indigo-light .ui-tree, .app.thm-red-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-red-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-indigo-light .ui-paginator, .app.thm-red-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-indigo-light .ui-inputtext, .app.thm-red-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-indigo-light .ui-multiselect, .app.thm-red-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-indigo-light .ui-tree, .app.thm-teal-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-teal-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-indigo-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-indigo-light .ui-paginator, .app.thm-teal-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-indigo-light .ui-inputtext, .app.thm-teal-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-indigo-light .ui-multiselect, .app.thm-teal-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .ui-table-caption,
.app.thm-indigo-light .ui-table .ui-table-summary, .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-yellow-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-indigo-light .ui-inputtext, .app.thm-yellow-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-indigo-light .ui-paginator, .app.thm-yellow-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-yellow-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-yellow-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-indigo-light .ui-tree, .app.thm-green-light .ui-dialog .app.thm-indigo-light .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-indigo-light .ui-dialog-footer, .app.thm-indigo-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-green-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box, .app.thm-indigo-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-indigo-light .ui-inputtext, .app.thm-green-light .ui-tabview .app.thm-indigo-light .ui-tabview-panels, .app.thm-indigo-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-indigo-light .ui-paginator, .app.thm-green-light .ui-table .app.thm-indigo-light .ui-table-caption,
.app.thm-green-light .ui-table .app.thm-indigo-light .ui-table-summary, .app.thm-green-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-indigo-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-red-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-teal-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-teal-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-dropdown .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-indigo-light .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-indigo-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-indigo-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-teal-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-indigo-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-indigo-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-indigo-light li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-indigo-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-indigo-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-indigo-light .ui-treenode-label.ui-state-highlight {
  background: #303f9f;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-indigo-light .ui-multiselect-item.ui-state-highlight {
  background: #5c6bc0;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-indigo-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .border-error-style, .app.thm-indigo-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-indigo-light .clv-bg-danger, .app.thm-indigo-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-indigo-light .clv-color-danger, .app.thm-indigo-light .danger-color {
  color: #d32f2f;
}
.app.thm-indigo-light .clv-bg-danger-imp, .app.thm-indigo-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-danger-imp, .app.thm-indigo-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-indigo-light .clv-bg-success, .app.thm-indigo-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-indigo-light .clv-color-success, .app.thm-indigo-light .success-color {
  color: #388e3c;
}
.app.thm-indigo-light .clv-bg-success-imp, .app.thm-indigo-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-success-imp, .app.thm-indigo-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-indigo-light .clv-bg-warning, .app.thm-indigo-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .clv-color-warning, .app.thm-indigo-light .warning-color {
  color: #fbc02d;
}
.app.thm-indigo-light .clv-bg-warning-imp, .app.thm-indigo-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-indigo-light .clv-color-warning-imp, .app.thm-indigo-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-indigo-light .clv-bg-orange, .app.thm-indigo-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .clv-color-orange, .app.thm-indigo-light .orange-color {
  color: #ffa000;
}
.app.thm-indigo-light .clv-bg-orange-imp, .app.thm-indigo-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-indigo-light .clv-color-orange-imp, .app.thm-indigo-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-indigo-light .clv-bg-info, .app.thm-indigo-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-indigo-light .clv-color-info, .app.thm-indigo-light .info-color {
  color: #0288d1;
}
.app.thm-indigo-light .clv-bg-info-imp, .app.thm-indigo-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-info-imp, .app.thm-indigo-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-indigo-light .clv-bg-blue, .app.thm-indigo-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-indigo-light .clv-color-blue, .app.thm-indigo-light .blue-color {
  color: #1976d2;
}
.app.thm-indigo-light .clv-bg-blue-imp, .app.thm-indigo-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-blue-imp, .app.thm-indigo-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-indigo-light .clv-bg-indigo, .app.thm-indigo-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-indigo-light .clv-color-indigo, .app.thm-indigo-light .indigo-color {
  color: #303f9f;
}
.app.thm-indigo-light .clv-bg-indigo-imp, .app.thm-indigo-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-indigo-imp, .app.thm-indigo-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-indigo-light .clv-bg-brown, .app.thm-indigo-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-indigo-light .clv-color-brown, .app.thm-indigo-light .brown-color {
  color: #5d4037;
}
.app.thm-indigo-light .clv-bg-brown-imp, .app.thm-indigo-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-indigo-light .clv-color-brown-imp, .app.thm-indigo-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-indigo-light .connexion-titre {
  color: #303f9f;
}
.app.thm-indigo-light .header {
  background: white;
  color: black;
}
.app.thm-indigo-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .content-header h4 {
  color: #e8eaf6;
}
.app.thm-indigo-light .favorites-container:after {
  color: #ff4081;
}
.app.thm-indigo-light .horizontal-menu {
  background: white;
}
.app.thm-indigo-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-indigo-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #303f9f;
  color: #fff;
}
.app.thm-indigo-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .menu-item .mat-button.active-link .menu-icon {
  background: #303f9f;
  color: #fff;
}
.app.thm-indigo-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-indigo-light .breadcrumb a {
  color: #e8eaf6;
}
.app.thm-indigo-light .top-toolbar, .app.thm-indigo-light .landing-toolbar {
  background: white;
  color: #283593;
}
.app.thm-indigo-light .menu-toolbar {
  background: #1a237e;
  color: #c5cae9;
}
.app.thm-indigo-light .ps__thumb-y {
  background-color: #303f9f;
}
.app.thm-indigo-light .testimonial-item, .app.thm-indigo-light .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.thm-indigo-light .login-container {
  background: #fafafa;
}
.app.thm-indigo-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-indigo-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-indigo-light .annular-pagination .ngx-pagination .current {
  background: #303f9f;
  color: white;
}
.app.thm-indigo-light .annular-pagination .ngx-pagination a:hover, .app.thm-indigo-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(48, 63, 159, 0.2);
  color: black;
}
.app.thm-indigo-light .cal-day-view .cal-hour-segment:hover,
.app.thm-indigo-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-indigo-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-indigo-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-indigo-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-indigo-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.thm-indigo-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(48, 63, 159, 0.05);
}
.app.thm-indigo-light .cal-week-view .cal-header.cal-today,
.app.thm-indigo-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.thm-indigo-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-indigo-light .cal-event-action i {
  margin: 4px;
}
.app.thm-indigo-light .ql-snow .ql-picker,
.app.thm-indigo-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-indigo-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-indigo-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-indigo-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-indigo-light .ui-dialog .ui-dialog-titlebar {
  background-color: #303f9f;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-indigo-light .ui-button {
  color: !important;
  background-color: #303f9f;
  border: 1px solid #303f9f;
}
.app.thm-indigo-light .ui-button.ui-button-primary {
  background-color: #303f9f;
  border: 1px solid #303f9f;
}
.app.thm-indigo-light .ui-button.ui-button-secondary {
  background: #ff4081;
  color: white;
  border: 1px solid #ff4081;
}
.app.thm-indigo-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-indigo-light .ui-multiselect {
  display: block;
}
.app.thm-indigo-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-indigo-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-indigo-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .form-separator {
  background: #303f9f;
  text-align: center;
  color: white;
}
.app.thm-indigo-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-indigo-light .titre-in-table {
  background: #ff4081;
  color: white;
  text-align: center;
}
.app.thm-indigo-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-teal-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-teal-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-option:hover:not(.mat-option-disabled), .app.thm-teal-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00796b;
}
.app.thm-teal-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f4511e;
}
.app.thm-teal-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e53935;
}
.app.thm-teal-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-teal-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-teal-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-teal-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00796b;
}
.app.thm-teal-light .mat-pseudo-checkbox-checked,
.app.thm-teal-light .mat-pseudo-checkbox-indeterminate,
.app.thm-teal-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-teal-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f4511e;
}
.app.thm-teal-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-teal-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e53935;
}
.app.thm-teal-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-teal-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-teal-light .mat-app-background, .app.thm-teal-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-teal-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-teal-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-badge-content {
  color: white;
  background: #00796b;
}
.cdk-high-contrast-active .app.thm-teal-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-teal-light .mat-badge-accent .mat-badge-content {
  background: #f4511e;
  color: white;
}
.app.thm-teal-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e53935;
}
.app.thm-teal-light .mat-badge {
  position: relative;
}
.app.thm-teal-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-teal-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-teal-light .ng-animate-disabled .mat-badge-content,
.app.thm-teal-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-teal-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-teal-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-teal-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-teal-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-teal-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-teal-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-teal-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-teal-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-button, .app.thm-teal-light .mat-icon-button, .app.thm-teal-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-teal-light .mat-button.mat-primary, .app.thm-teal-light .mat-icon-button.mat-primary, .app.thm-teal-light .mat-stroked-button.mat-primary {
  color: #00796b;
}
.app.thm-teal-light .mat-button.mat-accent, .app.thm-teal-light .mat-icon-button.mat-accent, .app.thm-teal-light .mat-stroked-button.mat-accent {
  color: #f4511e;
}
.app.thm-teal-light .mat-button.mat-warn, .app.thm-teal-light .mat-icon-button.mat-warn, .app.thm-teal-light .mat-stroked-button.mat-warn {
  color: #e53935;
}
.app.thm-teal-light .mat-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-teal-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-teal-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00796b;
}
.app.thm-teal-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-teal-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-teal-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-teal-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-teal-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e53935;
}
.app.thm-teal-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-teal-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-teal-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-teal-light .mat-button .mat-ripple-element, .app.thm-teal-light .mat-icon-button .mat-ripple-element, .app.thm-teal-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-teal-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-teal-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-flat-button, .app.thm-teal-light .mat-raised-button, .app.thm-teal-light .mat-fab, .app.thm-teal-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-teal-light .mat-flat-button.mat-primary, .app.thm-teal-light .mat-raised-button.mat-primary, .app.thm-teal-light .mat-fab.mat-primary, .app.thm-teal-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-teal-light .mat-flat-button.mat-accent, .app.thm-teal-light .mat-raised-button.mat-accent, .app.thm-teal-light .mat-fab.mat-accent, .app.thm-teal-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-teal-light .mat-flat-button.mat-warn, .app.thm-teal-light .mat-raised-button.mat-warn, .app.thm-teal-light .mat-fab.mat-warn, .app.thm-teal-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-teal-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-flat-button.mat-primary, .app.thm-teal-light .mat-raised-button.mat-primary, .app.thm-teal-light .mat-fab.mat-primary, .app.thm-teal-light .mat-mini-fab.mat-primary {
  background-color: #00796b;
}
.app.thm-teal-light .mat-flat-button.mat-accent, .app.thm-teal-light .mat-raised-button.mat-accent, .app.thm-teal-light .mat-fab.mat-accent, .app.thm-teal-light .mat-mini-fab.mat-accent {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-flat-button.mat-warn, .app.thm-teal-light .mat-raised-button.mat-warn, .app.thm-teal-light .mat-fab.mat-warn, .app.thm-teal-light .mat-mini-fab.mat-warn {
  background-color: #e53935;
}
.app.thm-teal-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-teal-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-teal-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-teal-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-teal-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-teal-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-teal-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-teal-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-teal-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-teal-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-teal-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-teal-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-teal-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-teal-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-teal-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-button-toggle-standalone,
.app.thm-teal-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-teal-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-teal-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-teal-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-teal-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-teal-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-teal-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-teal-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-teal-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-teal-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-teal-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-teal-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-teal-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00796b;
}
.app.thm-teal-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-teal-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-teal-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e53935;
}
.app.thm-teal-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-teal-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-teal-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-teal-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00796b;
}
.app.thm-teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #f4511e;
}
.app.thm-teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e53935;
}
.app.thm-teal-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-teal-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-teal-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00796b;
  color: white;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e53935;
  color: white;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #f4511e;
  color: white;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-teal-light .mat-table {
  background: white;
}
.app.thm-teal-light .mat-table thead, .app.thm-teal-light .mat-table tbody, .app.thm-teal-light .mat-table tfoot,
.app.thm-teal-light mat-header-row, .app.thm-teal-light mat-row, .app.thm-teal-light mat-footer-row,
.app.thm-teal-light [mat-header-row], .app.thm-teal-light [mat-row], .app.thm-teal-light [mat-footer-row],
.app.thm-teal-light .mat-table-sticky {
  background: inherit;
}
.app.thm-teal-light mat-row, .app.thm-teal-light mat-header-row, .app.thm-teal-light mat-footer-row,
.app.thm-teal-light th.mat-header-cell, .app.thm-teal-light td.mat-cell, .app.thm-teal-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-cell, .app.thm-teal-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-datepicker-toggle,
.app.thm-teal-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-teal-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-calendar-body-cell-content,
.app.thm-teal-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-teal-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-teal-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-teal-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-teal-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-teal-light .mat-calendar-body-in-range::before {
  background: rgba(0, 121, 107, 0.2);
}
.app.thm-teal-light .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-teal-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-teal-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 121, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-teal-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 121, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-teal-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-teal-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-teal-light .mat-calendar-body-selected {
  background-color: #00796b;
  color: white;
}
.app.thm-teal-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.thm-teal-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-teal-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(244, 81, 30, 0.2);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-teal-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-teal-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #f4511e;
  color: white;
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 81, 30, 0.4);
}
.app.thm-teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 57, 53, 0.2);
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-teal-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-teal-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e53935;
  color: white;
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 57, 53, 0.4);
}
.app.thm-teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-teal-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-datepicker-toggle-active {
  color: #00796b;
}
.app.thm-teal-light .mat-datepicker-toggle-active.mat-accent {
  color: #f4511e;
}
.app.thm-teal-light .mat-datepicker-toggle-active.mat-warn {
  color: #e53935;
}
.app.thm-teal-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-teal-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-teal-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-teal-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-teal-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-expansion-panel-header-description,
.app.thm-teal-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-teal-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-teal-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-teal-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #00796b;
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #f4511e;
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e53935;
}
.app.thm-teal-light .mat-focused .mat-form-field-required-marker {
  color: #f4511e;
}
.app.thm-teal-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00796b;
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e53935;
}
.app.thm-teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00796b;
}
.app.thm-teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #f4511e;
}
.app.thm-teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e53935;
}
.app.thm-teal-light .mat-error {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-teal-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-teal-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-teal-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-teal-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00796b;
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #f4511e;
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-teal-light .mat-icon.mat-primary {
  color: #00796b;
}
.app.thm-teal-light .mat-icon.mat-accent {
  color: #f4511e;
}
.app.thm-teal-light .mat-icon.mat-warn {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-input-element:disabled,
.app.thm-teal-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-input-element {
  caret-color: #00796b;
}
.app.thm-teal-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #f4511e;
}
.app.thm-teal-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-teal-light .mat-form-field-invalid .mat-input-element {
  caret-color: #e53935;
}
.app.thm-teal-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e53935;
}
.app.thm-teal-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-teal-light .mat-list-option:hover, .app.thm-teal-light .mat-list-option:focus,
.app.thm-teal-light .mat-nav-list .mat-list-item:hover,
.app.thm-teal-light .mat-nav-list .mat-list-item:focus,
.app.thm-teal-light .mat-action-list .mat-list-item:hover,
.app.thm-teal-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-list-single-selected-option, .app.thm-teal-light .mat-list-single-selected-option:hover, .app.thm-teal-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-menu-panel {
  background: white;
}
.app.thm-teal-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-menu-item[disabled], .app.thm-teal-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-menu-item .mat-icon-no-color,
.app.thm-teal-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-menu-item:hover:not([disabled]),
.app.thm-teal-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-teal-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-teal-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-teal-light .mat-paginator {
  background: white;
}
.app.thm-teal-light .mat-paginator,
.app.thm-teal-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-paginator-decrement,
.app.thm-teal-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-paginator-first,
.app.thm-teal-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-teal-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-teal-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-teal-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-progress-bar-background {
  fill: #e0f2f1;
}
.app.thm-teal-light .mat-progress-bar-buffer {
  background-color: #e0f2f1;
}
.app.thm-teal-light .mat-progress-bar-fill::after {
  background-color: #00796b;
}
.app.thm-teal-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff3d00;
}
.app.thm-teal-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff3d00;
}
.app.thm-teal-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-teal-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-teal-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e53935;
}
.app.thm-teal-light .mat-progress-spinner circle, .app.thm-teal-light .mat-spinner circle {
  stroke: #00796b;
}
.app.thm-teal-light .mat-progress-spinner.mat-accent circle, .app.thm-teal-light .mat-spinner.mat-accent circle {
  stroke: #f4511e;
}
.app.thm-teal-light .mat-progress-spinner.mat-warn circle, .app.thm-teal-light .mat-spinner.mat-warn circle {
  stroke: #e53935;
}
.app.thm-teal-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00796b;
}
.app.thm-teal-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-teal-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-teal-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-teal-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00796b;
}
.app.thm-teal-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f4511e;
}
.app.thm-teal-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-teal-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-teal-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-teal-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e53935;
}
.app.thm-teal-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-teal-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-teal-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-teal-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e53935;
}
.app.thm-teal-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-teal-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-teal-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-teal-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-teal-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-select-panel {
  background: white;
}
.app.thm-teal-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00796b;
}
.app.thm-teal-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #f4511e;
}
.app.thm-teal-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e53935;
}
.app.thm-teal-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-teal-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-teal-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-teal-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-teal-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 81, 30, 0.54);
}
.app.thm-teal-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00796b;
}
.app.thm-teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 121, 107, 0.54);
}
.app.thm-teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00796b;
}
.app.thm-teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e53935;
}
.app.thm-teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 57, 53, 0.54);
}
.app.thm-teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e53935;
}
.app.thm-teal-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-teal-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-teal-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-primary .mat-slider-track-fill,
.app.thm-teal-light .mat-primary .mat-slider-thumb,
.app.thm-teal-light .mat-primary .mat-slider-thumb-label {
  background-color: #00796b;
}
.app.thm-teal-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-teal-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 121, 107, 0.2);
}
.app.thm-teal-light .mat-accent .mat-slider-track-fill,
.app.thm-teal-light .mat-accent .mat-slider-thumb,
.app.thm-teal-light .mat-accent .mat-slider-thumb-label {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-teal-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(244, 81, 30, 0.2);
}
.app.thm-teal-light .mat-warn .mat-slider-track-fill,
.app.thm-teal-light .mat-warn .mat-slider-thumb,
.app.thm-teal-light .mat-warn .mat-slider-thumb-label {
  background-color: #e53935;
}
.app.thm-teal-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-teal-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 57, 53, 0.2);
}
.app.thm-teal-light .mat-slider:hover .mat-slider-track-background,
.app.thm-teal-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-teal-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-teal-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-teal-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-teal-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-teal-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-teal-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-teal-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-teal-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-teal-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-teal-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-teal-light .mat-step-header.cdk-keyboard-focused, .app.thm-teal-light .mat-step-header.cdk-program-focused, .app.thm-teal-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-teal-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-teal-light .mat-step-header .mat-step-label,
.app.thm-teal-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-teal-light .mat-step-header .mat-step-icon-selected,
.app.thm-teal-light .mat-step-header .mat-step-icon-state-done,
.app.thm-teal-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #00796b;
  color: white;
}
.app.thm-teal-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e53935;
}
.app.thm-teal-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e53935;
}
.app.thm-teal-light .mat-stepper-horizontal, .app.thm-teal-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-teal-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-horizontal-stepper-header::before,
.app.thm-teal-light .mat-horizontal-stepper-header::after,
.app.thm-teal-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-teal-light .mat-tab-nav-bar,
.app.thm-teal-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-teal-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-teal-light .mat-tab-label, .app.thm-teal-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-teal-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-teal-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-teal-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 242, 241, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00796b;
}
.app.thm-teal-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 61, 0, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e53935;
}
.app.thm-teal-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-teal-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 242, 241, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #00796b;
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-teal-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 61, 0, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #f4511e;
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-teal-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #e53935;
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-teal-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-teal-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-teal-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-toolbar.mat-primary {
  background: #00796b;
  color: white;
}
.app.thm-teal-light .mat-toolbar.mat-accent {
  background: #f4511e;
  color: white;
}
.app.thm-teal-light .mat-toolbar.mat-warn {
  background: #e53935;
  color: white;
}
.app.thm-teal-light .mat-toolbar .mat-form-field-underline,
.app.thm-teal-light .mat-toolbar .mat-form-field-ripple,
.app.thm-teal-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-teal-light .mat-toolbar .mat-form-field-label,
.app.thm-teal-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-teal-light .mat-toolbar .mat-select-value,
.app.thm-teal-light .mat-toolbar .mat-select-arrow,
.app.thm-teal-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-teal-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-teal-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-teal-light .mat-tree {
  background: white;
}
.app.thm-teal-light .mat-tree-node,
.app.thm-teal-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .mat-simple-snackbar-action {
  color: #f4511e;
}
.app.thm-teal-light .bg-primary {
  background: #00796b;
  color: white;
}
.app.thm-teal-light .bg-primary-l-400 {
  background-color: #26a69a;
  color: white;
}
.app.thm-teal-light .bg-accent {
  background: #f4511e;
  color: white;
}
.app.thm-teal-light .bg-warn {
  background: #e53935;
  color: white;
}
.app.thm-teal-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .primary-color {
  color: #00796b !important;
}
.app.thm-teal-light .accent-color {
  color: #f4511e !important;
}
.app.thm-teal-light .warn-color {
  color: #e53935 !important;
}
.app.thm-teal-light .bg-card {
  background: white;
}
.app.thm-teal-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-teal-light .bg-white {
  background: #fff;
}
.app.thm-teal-light .link-style {
  color: #f4511e;
  cursor: pointer;
}
.app.thm-teal-light .card-style, .app.thm-teal-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-teal-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-teal-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-teal-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-teal-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-teal-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-teal-light .ui-tree, .app.thm-gray-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-gray-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-teal-light .ui-paginator, .app.thm-gray-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-teal-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-teal-light .ui-multiselect, .app.thm-gray-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-teal-light .ui-tree, .app.thm-red-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-red-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-teal-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-teal-light .ui-paginator, .app.thm-red-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-teal-light .ui-inputtext, .app.thm-red-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-teal-light .ui-multiselect, .app.thm-red-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-teal-light .ui-tree, .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .ui-table-caption,
.app.thm-teal-light .ui-table .ui-table-summary, .app.thm-teal-light .ui-paginator, .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-yellow-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-teal-light .ui-inputtext, .app.thm-yellow-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-teal-light .ui-paginator, .app.thm-yellow-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-yellow-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-yellow-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-teal-light .ui-tree, .app.thm-green-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-green-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-teal-light .ui-inputtext, .app.thm-green-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-teal-light .ui-paginator, .app.thm-green-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-green-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-green-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-teal-light .ui-tree, .app.thm-indigo-light .ui-dialog .app.thm-teal-light .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-teal-light .ui-dialog-footer, .app.thm-teal-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-indigo-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box, .app.thm-teal-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-teal-light .ui-inputtext, .app.thm-indigo-light .ui-tabview .app.thm-teal-light .ui-tabview-panels, .app.thm-teal-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-teal-light .ui-paginator, .app.thm-indigo-light .ui-table .app.thm-teal-light .ui-table-caption,
.app.thm-indigo-light .ui-table .app.thm-teal-light .ui-table-summary, .app.thm-indigo-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-teal-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-red-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-teal-light .ui-dropdown .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-teal-light .ui-multiselect .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-teal-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-teal-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-teal-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-teal-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-teal-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-teal-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-teal-light li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-teal-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-teal-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-teal-light .ui-treenode-label.ui-state-highlight {
  background: #00796b;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-teal-light .ui-multiselect-item.ui-state-highlight {
  background: #26a69a;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-teal-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .border-error-style, .app.thm-teal-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-teal-light .clv-bg-danger, .app.thm-teal-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-teal-light .clv-color-danger, .app.thm-teal-light .danger-color {
  color: #d32f2f;
}
.app.thm-teal-light .clv-bg-danger-imp, .app.thm-teal-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-danger-imp, .app.thm-teal-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-teal-light .clv-bg-success, .app.thm-teal-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-teal-light .clv-color-success, .app.thm-teal-light .success-color {
  color: #388e3c;
}
.app.thm-teal-light .clv-bg-success-imp, .app.thm-teal-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-success-imp, .app.thm-teal-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-teal-light .clv-bg-warning, .app.thm-teal-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .clv-color-warning, .app.thm-teal-light .warning-color {
  color: #fbc02d;
}
.app.thm-teal-light .clv-bg-warning-imp, .app.thm-teal-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-teal-light .clv-color-warning-imp, .app.thm-teal-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-teal-light .clv-bg-orange, .app.thm-teal-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .clv-color-orange, .app.thm-teal-light .orange-color {
  color: #ffa000;
}
.app.thm-teal-light .clv-bg-orange-imp, .app.thm-teal-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-teal-light .clv-color-orange-imp, .app.thm-teal-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-teal-light .clv-bg-info, .app.thm-teal-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-teal-light .clv-color-info, .app.thm-teal-light .info-color {
  color: #0288d1;
}
.app.thm-teal-light .clv-bg-info-imp, .app.thm-teal-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-info-imp, .app.thm-teal-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-teal-light .clv-bg-blue, .app.thm-teal-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-teal-light .clv-color-blue, .app.thm-teal-light .blue-color {
  color: #1976d2;
}
.app.thm-teal-light .clv-bg-blue-imp, .app.thm-teal-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-blue-imp, .app.thm-teal-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-teal-light .clv-bg-indigo, .app.thm-teal-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-teal-light .clv-color-indigo, .app.thm-teal-light .indigo-color {
  color: #303f9f;
}
.app.thm-teal-light .clv-bg-indigo-imp, .app.thm-teal-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-indigo-imp, .app.thm-teal-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-teal-light .clv-bg-brown, .app.thm-teal-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-teal-light .clv-color-brown, .app.thm-teal-light .brown-color {
  color: #5d4037;
}
.app.thm-teal-light .clv-bg-brown-imp, .app.thm-teal-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-teal-light .clv-color-brown-imp, .app.thm-teal-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-teal-light .connexion-titre {
  color: #00796b;
}
.app.thm-teal-light .header {
  background: white;
  color: black;
}
.app.thm-teal-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .content-header h4 {
  color: #e0f2f1;
}
.app.thm-teal-light .favorites-container:after {
  color: #f4511e;
}
.app.thm-teal-light .horizontal-menu {
  background: white;
}
.app.thm-teal-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-teal-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #00796b;
  color: #fff;
}
.app.thm-teal-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .menu-item .mat-button.active-link .menu-icon {
  background: #00796b;
  color: #fff;
}
.app.thm-teal-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-teal-light .breadcrumb a {
  color: #e0f2f1;
}
.app.thm-teal-light .top-toolbar, .app.thm-teal-light .landing-toolbar {
  background: white;
  color: #00695c;
}
.app.thm-teal-light .menu-toolbar {
  background: #004d40;
  color: #b2dfdb;
}
.app.thm-teal-light .ps__thumb-y {
  background-color: #00796b;
}
.app.thm-teal-light .testimonial-item, .app.thm-teal-light .pricing-card {
  border-top: 4px solid #f4511e;
}
.app.thm-teal-light .login-container {
  background: #fafafa;
}
.app.thm-teal-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-teal-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-teal-light .annular-pagination .ngx-pagination .current {
  background: #00796b;
  color: white;
}
.app.thm-teal-light .annular-pagination .ngx-pagination a:hover, .app.thm-teal-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(0, 121, 107, 0.2);
  color: black;
}
.app.thm-teal-light .cal-day-view .cal-hour-segment:hover,
.app.thm-teal-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-teal-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-teal-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-teal-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-teal-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.thm-teal-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(0, 121, 107, 0.05);
}
.app.thm-teal-light .cal-week-view .cal-header.cal-today,
.app.thm-teal-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.thm-teal-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-teal-light .cal-event-action i {
  margin: 4px;
}
.app.thm-teal-light .ql-snow .ql-picker,
.app.thm-teal-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-teal-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-teal-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-teal-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-teal-light .ui-dialog .ui-dialog-titlebar {
  background-color: #00796b;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-teal-light .ui-button {
  color: !important;
  background-color: #00796b;
  border: 1px solid #00796b;
}
.app.thm-teal-light .ui-button.ui-button-primary {
  background-color: #00796b;
  border: 1px solid #00796b;
}
.app.thm-teal-light .ui-button.ui-button-secondary {
  background: #f4511e;
  color: white;
  border: 1px solid #f4511e;
}
.app.thm-teal-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-teal-light .ui-multiselect {
  display: block;
}
.app.thm-teal-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-teal-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-teal-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .form-separator {
  background: #00796b;
  text-align: center;
  color: white;
}
.app.thm-teal-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-teal-light .titre-in-table {
  background: #f4511e;
  color: white;
  text-align: center;
}
.app.thm-teal-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-red-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-red-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-option:hover:not(.mat-option-disabled), .app.thm-red-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f;
}
.app.thm-red-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.thm-red-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d00;
}
.app.thm-red-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-red-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-red-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-red-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f;
}
.app.thm-red-light .mat-pseudo-checkbox-checked,
.app.thm-red-light .mat-pseudo-checkbox-indeterminate,
.app.thm-red-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-red-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.thm-red-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-red-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d00;
}
.app.thm-red-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-red-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-red-light .mat-app-background, .app.thm-red-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-red-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-red-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-badge-content {
  color: white;
  background: #d32f2f;
}
.cdk-high-contrast-active .app.thm-red-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-red-light .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.thm-red-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.thm-red-light .mat-badge {
  position: relative;
}
.app.thm-red-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-red-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-red-light .ng-animate-disabled .mat-badge-content,
.app.thm-red-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-red-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-red-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-red-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-red-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-red-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-red-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-red-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-red-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-red-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-red-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-red-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-red-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-red-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-red-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-red-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-red-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-red-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-red-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-red-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-red-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-red-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-red-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-red-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-red-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-red-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-red-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-red-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-red-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-red-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-red-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-button, .app.thm-red-light .mat-icon-button, .app.thm-red-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-red-light .mat-button.mat-primary, .app.thm-red-light .mat-icon-button.mat-primary, .app.thm-red-light .mat-stroked-button.mat-primary {
  color: #d32f2f;
}
.app.thm-red-light .mat-button.mat-accent, .app.thm-red-light .mat-icon-button.mat-accent, .app.thm-red-light .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.thm-red-light .mat-button.mat-warn, .app.thm-red-light .mat-icon-button.mat-warn, .app.thm-red-light .mat-stroked-button.mat-warn {
  color: #ff3d00;
}
.app.thm-red-light .mat-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-red-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-red-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-red-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-red-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.thm-red-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-red-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-red-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-red-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-red-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-red-light .mat-button .mat-ripple-element, .app.thm-red-light .mat-icon-button .mat-ripple-element, .app.thm-red-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-red-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-red-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-flat-button, .app.thm-red-light .mat-raised-button, .app.thm-red-light .mat-fab, .app.thm-red-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-red-light .mat-flat-button.mat-primary, .app.thm-red-light .mat-raised-button.mat-primary, .app.thm-red-light .mat-fab.mat-primary, .app.thm-red-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-red-light .mat-flat-button.mat-accent, .app.thm-red-light .mat-raised-button.mat-accent, .app.thm-red-light .mat-fab.mat-accent, .app.thm-red-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-red-light .mat-flat-button.mat-warn, .app.thm-red-light .mat-raised-button.mat-warn, .app.thm-red-light .mat-fab.mat-warn, .app.thm-red-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-red-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-red-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-red-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-red-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-flat-button.mat-primary, .app.thm-red-light .mat-raised-button.mat-primary, .app.thm-red-light .mat-fab.mat-primary, .app.thm-red-light .mat-mini-fab.mat-primary {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-flat-button.mat-accent, .app.thm-red-light .mat-raised-button.mat-accent, .app.thm-red-light .mat-fab.mat-accent, .app.thm-red-light .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.thm-red-light .mat-flat-button.mat-warn, .app.thm-red-light .mat-raised-button.mat-warn, .app.thm-red-light .mat-fab.mat-warn, .app.thm-red-light .mat-mini-fab.mat-warn {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-red-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-red-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-red-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-red-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-red-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-red-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-red-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-red-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-red-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-red-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-red-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-red-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-red-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-red-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-red-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-red-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-red-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-red-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-button-toggle-standalone,
.app.thm-red-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-red-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-red-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-red-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-red-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-red-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-red-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-red-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-red-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-red-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-red-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-red-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-red-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-red-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.thm-red-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-red-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-red-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-red-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-red-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d32f2f;
}
.app.thm-red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.thm-red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d00;
}
.app.thm-red-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-red-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-red-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d32f2f;
  color: white;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d00;
  color: white;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-red-light .mat-table {
  background: white;
}
.app.thm-red-light .mat-table thead, .app.thm-red-light .mat-table tbody, .app.thm-red-light .mat-table tfoot,
.app.thm-red-light mat-header-row, .app.thm-red-light mat-row, .app.thm-red-light mat-footer-row,
.app.thm-red-light [mat-header-row], .app.thm-red-light [mat-row], .app.thm-red-light [mat-footer-row],
.app.thm-red-light .mat-table-sticky {
  background: inherit;
}
.app.thm-red-light mat-row, .app.thm-red-light mat-header-row, .app.thm-red-light mat-footer-row,
.app.thm-red-light th.mat-header-cell, .app.thm-red-light td.mat-cell, .app.thm-red-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-cell, .app.thm-red-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-datepicker-toggle,
.app.thm-red-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-red-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-calendar-body-cell-content,
.app.thm-red-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-red-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-red-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-red-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-red-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-red-light .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.app.thm-red-light .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-red-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-red-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-red-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-red-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-red-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-red-light .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.app.thm-red-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.thm-red-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-red-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-red-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-red-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.thm-red-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-red-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-red-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.thm-red-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-red-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-datepicker-toggle-active {
  color: #d32f2f;
}
.app.thm-red-light .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.thm-red-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.thm-red-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-red-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-red-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-red-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-red-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-expansion-panel-header-description,
.app.thm-red-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-red-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-red-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-red-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #d32f2f;
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d00;
}
.app.thm-red-light .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.thm-red-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.thm-red-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d32f2f;
}
.app.thm-red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.thm-red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-red-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-error {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-red-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-red-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-red-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-red-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d32f2f;
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-red-light .mat-icon.mat-primary {
  color: #d32f2f;
}
.app.thm-red-light .mat-icon.mat-accent {
  color: #43a047;
}
.app.thm-red-light .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-input-element:disabled,
.app.thm-red-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-input-element {
  caret-color: #d32f2f;
}
.app.thm-red-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.thm-red-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-red-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d00;
}
.app.thm-red-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.thm-red-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-red-light .mat-list-option:hover, .app.thm-red-light .mat-list-option:focus,
.app.thm-red-light .mat-nav-list .mat-list-item:hover,
.app.thm-red-light .mat-nav-list .mat-list-item:focus,
.app.thm-red-light .mat-action-list .mat-list-item:hover,
.app.thm-red-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-list-single-selected-option, .app.thm-red-light .mat-list-single-selected-option:hover, .app.thm-red-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-menu-panel {
  background: white;
}
.app.thm-red-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-menu-item[disabled], .app.thm-red-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-menu-item .mat-icon-no-color,
.app.thm-red-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-menu-item:hover:not([disabled]),
.app.thm-red-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-red-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-red-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-red-light .mat-paginator {
  background: white;
}
.app.thm-red-light .mat-paginator,
.app.thm-red-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-paginator-decrement,
.app.thm-red-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-paginator-first,
.app.thm-red-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-red-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-red-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-red-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-progress-bar-background {
  fill: #ffebee;
}
.app.thm-red-light .mat-progress-bar-buffer {
  background-color: #ffebee;
}
.app.thm-red-light .mat-progress-bar-fill::after {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #81c784;
}
.app.thm-red-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #81c784;
}
.app.thm-red-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.thm-red-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffccbc;
}
.app.thm-red-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffccbc;
}
.app.thm-red-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-progress-spinner circle, .app.thm-red-light .mat-spinner circle {
  stroke: #d32f2f;
}
.app.thm-red-light .mat-progress-spinner.mat-accent circle, .app.thm-red-light .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.thm-red-light .mat-progress-spinner.mat-warn circle, .app.thm-red-light .mat-spinner.mat-warn circle {
  stroke: #ff3d00;
}
.app.thm-red-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f;
}
.app.thm-red-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-red-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-red-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-red-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.thm-red-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-red-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-red-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-red-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.thm-red-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d00;
}
.app.thm-red-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-red-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-red-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-red-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-red-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-red-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-red-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-red-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-select-panel {
  background: white;
}
.app.thm-red-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d32f2f;
}
.app.thm-red-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.thm-red-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d00;
}
.app.thm-red-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-red-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-red-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-red-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-red-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.thm-red-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.thm-red-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.thm-red-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54);
}
.app.thm-red-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 0, 0.54);
}
.app.thm-red-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-red-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-red-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-primary .mat-slider-track-fill,
.app.thm-red-light .mat-primary .mat-slider-thumb,
.app.thm-red-light .mat-primary .mat-slider-thumb-label {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-red-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(211, 47, 47, 0.2);
}
.app.thm-red-light .mat-accent .mat-slider-track-fill,
.app.thm-red-light .mat-accent .mat-slider-thumb,
.app.thm-red-light .mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.thm-red-light .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-red-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.thm-red-light .mat-warn .mat-slider-track-fill,
.app.thm-red-light .mat-warn .mat-slider-thumb,
.app.thm-red-light .mat-warn .mat-slider-thumb-label {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-red-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 0, 0.2);
}
.app.thm-red-light .mat-slider:hover .mat-slider-track-background,
.app.thm-red-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-red-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-red-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-red-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-red-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-red-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-red-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-red-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-red-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-red-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-red-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-red-light .mat-step-header.cdk-keyboard-focused, .app.thm-red-light .mat-step-header.cdk-program-focused, .app.thm-red-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-red-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-red-light .mat-step-header .mat-step-label,
.app.thm-red-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-red-light .mat-step-header .mat-step-icon-selected,
.app.thm-red-light .mat-step-header .mat-step-icon-state-done,
.app.thm-red-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.app.thm-red-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.thm-red-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.thm-red-light .mat-stepper-horizontal, .app.thm-red-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-red-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-horizontal-stepper-header::before,
.app.thm-red-light .mat-horizontal-stepper-header::after,
.app.thm-red-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-red-light .mat-tab-nav-bar,
.app.thm-red-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-red-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-red-light .mat-tab-label, .app.thm-red-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-red-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-red-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-red-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-red-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(129, 199, 132, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.thm-red-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-red-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-red-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #d32f2f;
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-red-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(129, 199, 132, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-red-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff3d00;
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-red-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-red-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-red-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-toolbar.mat-primary {
  background: #d32f2f;
  color: white;
}
.app.thm-red-light .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.thm-red-light .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.thm-red-light .mat-toolbar .mat-form-field-underline,
.app.thm-red-light .mat-toolbar .mat-form-field-ripple,
.app.thm-red-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-red-light .mat-toolbar .mat-form-field-label,
.app.thm-red-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-red-light .mat-toolbar .mat-select-value,
.app.thm-red-light .mat-toolbar .mat-select-arrow,
.app.thm-red-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-red-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-red-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-red-light .mat-tree {
  background: white;
}
.app.thm-red-light .mat-tree-node,
.app.thm-red-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .mat-simple-snackbar-action {
  color: #43a047;
}
.app.thm-red-light .bg-primary {
  background: #d32f2f;
  color: white;
}
.app.thm-red-light .bg-primary-l-400 {
  background-color: #ef5350;
  color: white;
}
.app.thm-red-light .bg-accent {
  background: #43a047;
  color: white;
}
.app.thm-red-light .bg-warn {
  background: #ff3d00;
  color: white;
}
.app.thm-red-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .primary-color {
  color: #d32f2f !important;
}
.app.thm-red-light .accent-color {
  color: #43a047 !important;
}
.app.thm-red-light .warn-color {
  color: #ff3d00 !important;
}
.app.thm-red-light .bg-card {
  background: white;
}
.app.thm-red-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-red-light .bg-white {
  background: #fff;
}
.app.thm-red-light .link-style {
  color: #43a047;
  cursor: pointer;
}
.app.thm-red-light .card-style, .app.thm-red-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-red-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-red-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-red-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-red-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-red-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-red-light .ui-tree, .app.thm-gray-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-gray-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-red-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-red-light .ui-paginator, .app.thm-gray-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-red-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-red-light .ui-multiselect, .app.thm-gray-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-red-light .ui-tree, .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .ui-table-caption,
.app.thm-red-light .ui-table .ui-table-summary, .app.thm-red-light .ui-paginator, .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-inputtext, .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .ui-multiselect, .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-red-light .ui-multiselect-panel, .app.thm-yellow-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-red-light .ui-inputtext, .app.thm-yellow-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-red-light .ui-paginator, .app.thm-yellow-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-yellow-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-yellow-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-red-light .ui-tree, .app.thm-green-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-red-light .ui-multiselect-panel, .app.thm-green-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-red-light .ui-inputtext, .app.thm-green-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-red-light .ui-paginator, .app.thm-green-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-green-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-green-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-red-light .ui-tree, .app.thm-indigo-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-red-light .ui-multiselect-panel, .app.thm-indigo-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-red-light .ui-inputtext, .app.thm-indigo-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-red-light .ui-paginator, .app.thm-indigo-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-indigo-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-indigo-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-red-light .ui-tree, .app.thm-teal-light .ui-dialog .app.thm-red-light .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-red-light .ui-dialog-footer, .app.thm-red-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-red-light .ui-multiselect-panel, .app.thm-teal-light .ui-chkbox .app.thm-red-light .ui-chkbox-box, .app.thm-red-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-red-light .ui-inputtext, .app.thm-teal-light .ui-tabview .app.thm-red-light .ui-tabview-panels, .app.thm-red-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-red-light .ui-paginator, .app.thm-teal-light .ui-table .app.thm-red-light .ui-table-caption,
.app.thm-teal-light .ui-table .app.thm-red-light .ui-table-summary, .app.thm-teal-light .ui-table .app.thm-red-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-red-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-red-light .ui-dropdown .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-red-light .ui-multiselect .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-red-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-red-light .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-red-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-red-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-red-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-chkbox .app.thm-red-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-red-light li.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-red-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-red-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-red-light .ui-treenode-label.ui-state-highlight {
  background: #d32f2f;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-red-light .ui-multiselect-item.ui-state-highlight {
  background: #ef5350;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-red-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .border-error-style, .app.thm-red-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-red-light .clv-bg-danger, .app.thm-red-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-red-light .clv-color-danger, .app.thm-red-light .danger-color {
  color: #d32f2f;
}
.app.thm-red-light .clv-bg-danger-imp, .app.thm-red-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-danger-imp, .app.thm-red-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-red-light .clv-bg-success, .app.thm-red-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-red-light .clv-color-success, .app.thm-red-light .success-color {
  color: #388e3c;
}
.app.thm-red-light .clv-bg-success-imp, .app.thm-red-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-success-imp, .app.thm-red-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-red-light .clv-bg-warning, .app.thm-red-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .clv-color-warning, .app.thm-red-light .warning-color {
  color: #fbc02d;
}
.app.thm-red-light .clv-bg-warning-imp, .app.thm-red-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-red-light .clv-color-warning-imp, .app.thm-red-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-red-light .clv-bg-orange, .app.thm-red-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .clv-color-orange, .app.thm-red-light .orange-color {
  color: #ffa000;
}
.app.thm-red-light .clv-bg-orange-imp, .app.thm-red-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-red-light .clv-color-orange-imp, .app.thm-red-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-red-light .clv-bg-info, .app.thm-red-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-red-light .clv-color-info, .app.thm-red-light .info-color {
  color: #0288d1;
}
.app.thm-red-light .clv-bg-info-imp, .app.thm-red-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-info-imp, .app.thm-red-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-red-light .clv-bg-blue, .app.thm-red-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-red-light .clv-color-blue, .app.thm-red-light .blue-color {
  color: #1976d2;
}
.app.thm-red-light .clv-bg-blue-imp, .app.thm-red-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-blue-imp, .app.thm-red-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-red-light .clv-bg-indigo, .app.thm-red-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-red-light .clv-color-indigo, .app.thm-red-light .indigo-color {
  color: #303f9f;
}
.app.thm-red-light .clv-bg-indigo-imp, .app.thm-red-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-indigo-imp, .app.thm-red-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-red-light .clv-bg-brown, .app.thm-red-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-red-light .clv-color-brown, .app.thm-red-light .brown-color {
  color: #5d4037;
}
.app.thm-red-light .clv-bg-brown-imp, .app.thm-red-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-red-light .clv-color-brown-imp, .app.thm-red-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-red-light .connexion-titre {
  color: #d32f2f;
}
.app.thm-red-light .header {
  background: white;
  color: black;
}
.app.thm-red-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .content-header h4 {
  color: #ffebee;
}
.app.thm-red-light .favorites-container:after {
  color: #43a047;
}
.app.thm-red-light .horizontal-menu {
  background: white;
}
.app.thm-red-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-red-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #d32f2f;
  color: #fff;
}
.app.thm-red-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .menu-item .mat-button.active-link .menu-icon {
  background: #d32f2f;
  color: #fff;
}
.app.thm-red-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-red-light .breadcrumb a {
  color: #ffebee;
}
.app.thm-red-light .top-toolbar, .app.thm-red-light .landing-toolbar {
  background: white;
  color: #c62828;
}
.app.thm-red-light .menu-toolbar {
  background: #b71c1c;
  color: #ffcdd2;
}
.app.thm-red-light .ps__thumb-y {
  background-color: #d32f2f;
}
.app.thm-red-light .testimonial-item, .app.thm-red-light .pricing-card {
  border-top: 4px solid #43a047;
}
.app.thm-red-light .login-container {
  background: #fafafa;
}
.app.thm-red-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-red-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-red-light .annular-pagination .ngx-pagination .current {
  background: #d32f2f;
  color: white;
}
.app.thm-red-light .annular-pagination .ngx-pagination a:hover, .app.thm-red-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(211, 47, 47, 0.2);
  color: black;
}
.app.thm-red-light .cal-day-view .cal-hour-segment:hover,
.app.thm-red-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-red-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-red-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-red-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-red-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.thm-red-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(211, 47, 47, 0.05);
}
.app.thm-red-light .cal-week-view .cal-header.cal-today,
.app.thm-red-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.thm-red-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-red-light .cal-event-action i {
  margin: 4px;
}
.app.thm-red-light .ql-snow .ql-picker,
.app.thm-red-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-red-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-red-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-red-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-red-light .ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-red-light .ui-button {
  color: !important;
  background-color: #d32f2f;
  border: 1px solid #d32f2f;
}
.app.thm-red-light .ui-button.ui-button-primary {
  background-color: #d32f2f;
  border: 1px solid #d32f2f;
}
.app.thm-red-light .ui-button.ui-button-secondary {
  background: #43a047;
  color: white;
  border: 1px solid #43a047;
}
.app.thm-red-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-red-light .ui-multiselect {
  display: block;
}
.app.thm-red-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-red-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-red-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .form-separator {
  background: #d32f2f;
  text-align: center;
  color: white;
}
.app.thm-red-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-red-light .titre-in-table {
  background: #43a047;
  color: white;
  text-align: center;
}
.app.thm-red-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-gray-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-gray-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-option:hover:not(.mat-option-disabled), .app.thm-gray-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #616161;
}
.app.thm-gray-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.thm-gray-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.thm-gray-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-gray-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-gray-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-gray-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #616161;
}
.app.thm-gray-light .mat-pseudo-checkbox-checked,
.app.thm-gray-light .mat-pseudo-checkbox-indeterminate,
.app.thm-gray-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-gray-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.thm-gray-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-gray-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.thm-gray-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-gray-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-gray-light .mat-app-background, .app.thm-gray-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-gray-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-gray-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-badge-content {
  color: white;
  background: #616161;
}
.cdk-high-contrast-active .app.thm-gray-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-gray-light .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.thm-gray-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.thm-gray-light .mat-badge {
  position: relative;
}
.app.thm-gray-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-gray-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-gray-light .ng-animate-disabled .mat-badge-content,
.app.thm-gray-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-gray-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-gray-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-gray-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-gray-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-gray-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-gray-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-gray-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-gray-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-button, .app.thm-gray-light .mat-icon-button, .app.thm-gray-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-gray-light .mat-button.mat-primary, .app.thm-gray-light .mat-icon-button.mat-primary, .app.thm-gray-light .mat-stroked-button.mat-primary {
  color: #616161;
}
.app.thm-gray-light .mat-button.mat-accent, .app.thm-gray-light .mat-icon-button.mat-accent, .app.thm-gray-light .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.thm-gray-light .mat-button.mat-warn, .app.thm-gray-light .mat-icon-button.mat-warn, .app.thm-gray-light .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.thm-gray-light .mat-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-gray-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-gray-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #616161;
}
.app.thm-gray-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-gray-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-gray-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-gray-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-gray-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.thm-gray-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-gray-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-gray-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-gray-light .mat-button .mat-ripple-element, .app.thm-gray-light .mat-icon-button .mat-ripple-element, .app.thm-gray-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-gray-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-gray-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-flat-button, .app.thm-gray-light .mat-raised-button, .app.thm-gray-light .mat-fab, .app.thm-gray-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-gray-light .mat-flat-button.mat-primary, .app.thm-gray-light .mat-raised-button.mat-primary, .app.thm-gray-light .mat-fab.mat-primary, .app.thm-gray-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-gray-light .mat-flat-button.mat-accent, .app.thm-gray-light .mat-raised-button.mat-accent, .app.thm-gray-light .mat-fab.mat-accent, .app.thm-gray-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-gray-light .mat-flat-button.mat-warn, .app.thm-gray-light .mat-raised-button.mat-warn, .app.thm-gray-light .mat-fab.mat-warn, .app.thm-gray-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-gray-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-flat-button.mat-primary, .app.thm-gray-light .mat-raised-button.mat-primary, .app.thm-gray-light .mat-fab.mat-primary, .app.thm-gray-light .mat-mini-fab.mat-primary {
  background-color: #616161;
}
.app.thm-gray-light .mat-flat-button.mat-accent, .app.thm-gray-light .mat-raised-button.mat-accent, .app.thm-gray-light .mat-fab.mat-accent, .app.thm-gray-light .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-flat-button.mat-warn, .app.thm-gray-light .mat-raised-button.mat-warn, .app.thm-gray-light .mat-fab.mat-warn, .app.thm-gray-light .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.thm-gray-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-gray-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-gray-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-gray-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-gray-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-gray-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-gray-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-gray-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-gray-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-gray-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-gray-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-gray-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-gray-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-gray-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-gray-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-button-toggle-standalone,
.app.thm-gray-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-gray-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-gray-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-gray-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-gray-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-gray-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-gray-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-gray-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-gray-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-gray-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-gray-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-gray-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-gray-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #616161;
}
.app.thm-gray-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-gray-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-gray-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.thm-gray-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-gray-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-gray-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-gray-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #616161;
}
.app.thm-gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.thm-gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.thm-gray-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-gray-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-gray-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #616161;
  color: white;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-light .mat-table {
  background: white;
}
.app.thm-gray-light .mat-table thead, .app.thm-gray-light .mat-table tbody, .app.thm-gray-light .mat-table tfoot,
.app.thm-gray-light mat-header-row, .app.thm-gray-light mat-row, .app.thm-gray-light mat-footer-row,
.app.thm-gray-light [mat-header-row], .app.thm-gray-light [mat-row], .app.thm-gray-light [mat-footer-row],
.app.thm-gray-light .mat-table-sticky {
  background: inherit;
}
.app.thm-gray-light mat-row, .app.thm-gray-light mat-header-row, .app.thm-gray-light mat-footer-row,
.app.thm-gray-light th.mat-header-cell, .app.thm-gray-light td.mat-cell, .app.thm-gray-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-cell, .app.thm-gray-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-datepicker-toggle,
.app.thm-gray-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-gray-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-calendar-body-cell-content,
.app.thm-gray-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-gray-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-gray-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-gray-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-gray-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-gray-light .mat-calendar-body-in-range::before {
  background: rgba(97, 97, 97, 0.2);
}
.app.thm-gray-light .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-light .mat-calendar-body-selected {
  background-color: #616161;
  color: white;
}
.app.thm-gray-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.thm-gray-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.thm-gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.thm-gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-datepicker-toggle-active {
  color: #616161;
}
.app.thm-gray-light .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.thm-gray-light .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.thm-gray-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-gray-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-gray-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-gray-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-gray-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-expansion-panel-header-description,
.app.thm-gray-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-gray-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-gray-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-gray-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #616161;
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.thm-gray-light .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.thm-gray-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #616161;
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.thm-gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #616161;
}
.app.thm-gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.thm-gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.thm-gray-light .mat-error {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-gray-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-gray-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-gray-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-gray-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #616161;
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-gray-light .mat-icon.mat-primary {
  color: #616161;
}
.app.thm-gray-light .mat-icon.mat-accent {
  color: #ff4081;
}
.app.thm-gray-light .mat-icon.mat-warn {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-input-element:disabled,
.app.thm-gray-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-input-element {
  caret-color: #616161;
}
.app.thm-gray-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.thm-gray-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-gray-light .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.thm-gray-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-gray-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-gray-light .mat-list-option:hover, .app.thm-gray-light .mat-list-option:focus,
.app.thm-gray-light .mat-nav-list .mat-list-item:hover,
.app.thm-gray-light .mat-nav-list .mat-list-item:focus,
.app.thm-gray-light .mat-action-list .mat-list-item:hover,
.app.thm-gray-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-list-single-selected-option, .app.thm-gray-light .mat-list-single-selected-option:hover, .app.thm-gray-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-menu-panel {
  background: white;
}
.app.thm-gray-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-menu-item[disabled], .app.thm-gray-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-menu-item .mat-icon-no-color,
.app.thm-gray-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-menu-item:hover:not([disabled]),
.app.thm-gray-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-gray-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-gray-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-gray-light .mat-paginator {
  background: white;
}
.app.thm-gray-light .mat-paginator,
.app.thm-gray-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-paginator-decrement,
.app.thm-gray-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-paginator-first,
.app.thm-gray-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-gray-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-gray-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-gray-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-progress-bar-background {
  fill: #fafafa;
}
.app.thm-gray-light .mat-progress-bar-buffer {
  background-color: #fafafa;
}
.app.thm-gray-light .mat-progress-bar-fill::after {
  background-color: #616161;
}
.app.thm-gray-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab;
}
.app.thm-gray-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab;
}
.app.thm-gray-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-gray-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-gray-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.thm-gray-light .mat-progress-spinner circle, .app.thm-gray-light .mat-spinner circle {
  stroke: #616161;
}
.app.thm-gray-light .mat-progress-spinner.mat-accent circle, .app.thm-gray-light .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.thm-gray-light .mat-progress-spinner.mat-warn circle, .app.thm-gray-light .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.thm-gray-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #616161;
}
.app.thm-gray-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-gray-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #616161;
}
.app.thm-gray-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.thm-gray-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-gray-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.thm-gray-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-gray-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.thm-gray-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-gray-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-gray-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-gray-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-gray-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-select-panel {
  background: white;
}
.app.thm-gray-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #616161;
}
.app.thm-gray-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.thm-gray-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.thm-gray-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-gray-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-gray-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-gray-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-gray-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.thm-gray-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #616161;
}
.app.thm-gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(97, 97, 97, 0.54);
}
.app.thm-gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #616161;
}
.app.thm-gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.thm-gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.thm-gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.thm-gray-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-gray-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-gray-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-primary .mat-slider-track-fill,
.app.thm-gray-light .mat-primary .mat-slider-thumb,
.app.thm-gray-light .mat-primary .mat-slider-thumb-label {
  background-color: #616161;
}
.app.thm-gray-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(97, 97, 97, 0.2);
}
.app.thm-gray-light .mat-accent .mat-slider-track-fill,
.app.thm-gray-light .mat-accent .mat-slider-thumb,
.app.thm-gray-light .mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.thm-gray-light .mat-warn .mat-slider-track-fill,
.app.thm-gray-light .mat-warn .mat-slider-thumb,
.app.thm-gray-light .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.thm-gray-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.thm-gray-light .mat-slider:hover .mat-slider-track-background,
.app.thm-gray-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-gray-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-gray-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-gray-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-gray-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-gray-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-gray-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-gray-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-gray-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-gray-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-gray-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-gray-light .mat-step-header.cdk-keyboard-focused, .app.thm-gray-light .mat-step-header.cdk-program-focused, .app.thm-gray-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-gray-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-gray-light .mat-step-header .mat-step-label,
.app.thm-gray-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-gray-light .mat-step-header .mat-step-icon-selected,
.app.thm-gray-light .mat-step-header .mat-step-icon-state-done,
.app.thm-gray-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #616161;
  color: white;
}
.app.thm-gray-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.thm-gray-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.thm-gray-light .mat-stepper-horizontal, .app.thm-gray-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-gray-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-horizontal-stepper-header::before,
.app.thm-gray-light .mat-horizontal-stepper-header::after,
.app.thm-gray-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-gray-light .mat-tab-nav-bar,
.app.thm-gray-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-gray-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-gray-light .mat-tab-label, .app.thm-gray-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-gray-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-gray-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 250, 250, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #616161;
}
.app.thm-gray-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.thm-gray-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-gray-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 250, 250, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #616161;
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-gray-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-gray-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-gray-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-toolbar.mat-primary {
  background: #616161;
  color: white;
}
.app.thm-gray-light .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.thm-gray-light .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.thm-gray-light .mat-toolbar .mat-form-field-underline,
.app.thm-gray-light .mat-toolbar .mat-form-field-ripple,
.app.thm-gray-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-gray-light .mat-toolbar .mat-form-field-label,
.app.thm-gray-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-gray-light .mat-toolbar .mat-select-value,
.app.thm-gray-light .mat-toolbar .mat-select-arrow,
.app.thm-gray-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-gray-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-gray-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-gray-light .mat-tree {
  background: white;
}
.app.thm-gray-light .mat-tree-node,
.app.thm-gray-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .mat-simple-snackbar-action {
  color: #ff4081;
}
.app.thm-gray-light .bg-primary {
  background: #616161;
  color: white;
}
.app.thm-gray-light .bg-primary-l-400 {
  background-color: #bdbdbd;
  color: white;
}
.app.thm-gray-light .bg-accent {
  background: #ff4081;
  color: white;
}
.app.thm-gray-light .bg-warn {
  background: #f44336;
  color: white;
}
.app.thm-gray-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .primary-color {
  color: #616161 !important;
}
.app.thm-gray-light .accent-color {
  color: #ff4081 !important;
}
.app.thm-gray-light .warn-color {
  color: #f44336 !important;
}
.app.thm-gray-light .bg-card {
  background: white;
}
.app.thm-gray-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-gray-light .bg-white {
  background: #fff;
}
.app.thm-gray-light .link-style {
  color: #ff4081;
  cursor: pointer;
}
.app.thm-gray-light .card-style, .app.thm-gray-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .ui-tree, .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .ui-table-caption,
.app.thm-gray-light .ui-table .ui-table-summary, .app.thm-gray-light .ui-paginator, .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-gray-light .ui-dialog-content, .app.thm-gray-light .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-gray-light .ui-dialog-footer, .app.thm-gray-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box, .app.thm-gray-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-gray-light .ui-tabview-panels, .app.thm-gray-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-gray-light .ui-table-caption,
.app.thm-gray-light .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-gray-light .ui-table-summary, .app.thm-gray-light .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-gray-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-gray-light .ui-dropdown .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-gray-light .ui-multiselect .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect .app.thm-gray-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-gray-light .ui-multiselect-trigger, .app.thm-red-light .ui-multiselect-panel .app.thm-gray-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item, .app.thm-red-light .ui-dropdown .app.thm-gray-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-gray-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-chkbox .app.thm-gray-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-light li.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-gray-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-light .ui-treenode-label.ui-state-highlight {
  background: #616161;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-light .ui-multiselect-item.ui-state-highlight {
  background: #bdbdbd;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-gray-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .border-error-style, .app.thm-gray-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-gray-light .clv-bg-danger, .app.thm-gray-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-gray-light .clv-color-danger, .app.thm-gray-light .danger-color {
  color: #d32f2f;
}
.app.thm-gray-light .clv-bg-danger-imp, .app.thm-gray-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-danger-imp, .app.thm-gray-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-gray-light .clv-bg-success, .app.thm-gray-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-gray-light .clv-color-success, .app.thm-gray-light .success-color {
  color: #388e3c;
}
.app.thm-gray-light .clv-bg-success-imp, .app.thm-gray-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-success-imp, .app.thm-gray-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-gray-light .clv-bg-warning, .app.thm-gray-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .clv-color-warning, .app.thm-gray-light .warning-color {
  color: #fbc02d;
}
.app.thm-gray-light .clv-bg-warning-imp, .app.thm-gray-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-gray-light .clv-color-warning-imp, .app.thm-gray-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-gray-light .clv-bg-orange, .app.thm-gray-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .clv-color-orange, .app.thm-gray-light .orange-color {
  color: #ffa000;
}
.app.thm-gray-light .clv-bg-orange-imp, .app.thm-gray-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-gray-light .clv-color-orange-imp, .app.thm-gray-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-gray-light .clv-bg-info, .app.thm-gray-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-gray-light .clv-color-info, .app.thm-gray-light .info-color {
  color: #0288d1;
}
.app.thm-gray-light .clv-bg-info-imp, .app.thm-gray-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-info-imp, .app.thm-gray-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-gray-light .clv-bg-blue, .app.thm-gray-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-gray-light .clv-color-blue, .app.thm-gray-light .blue-color {
  color: #1976d2;
}
.app.thm-gray-light .clv-bg-blue-imp, .app.thm-gray-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-blue-imp, .app.thm-gray-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-gray-light .clv-bg-indigo, .app.thm-gray-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-gray-light .clv-color-indigo, .app.thm-gray-light .indigo-color {
  color: #303f9f;
}
.app.thm-gray-light .clv-bg-indigo-imp, .app.thm-gray-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-indigo-imp, .app.thm-gray-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-gray-light .clv-bg-brown, .app.thm-gray-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-gray-light .clv-color-brown, .app.thm-gray-light .brown-color {
  color: #5d4037;
}
.app.thm-gray-light .clv-bg-brown-imp, .app.thm-gray-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-gray-light .clv-color-brown-imp, .app.thm-gray-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-gray-light .connexion-titre {
  color: #616161;
}
.app.thm-gray-light .header {
  background: white;
  color: black;
}
.app.thm-gray-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .content-header h4 {
  color: #fafafa;
}
.app.thm-gray-light .favorites-container:after {
  color: #ff4081;
}
.app.thm-gray-light .horizontal-menu {
  background: white;
}
.app.thm-gray-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-gray-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #616161;
  color: #fff;
}
.app.thm-gray-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .menu-item .mat-button.active-link .menu-icon {
  background: #616161;
  color: #fff;
}
.app.thm-gray-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-gray-light .breadcrumb a {
  color: #fafafa;
}
.app.thm-gray-light .top-toolbar, .app.thm-gray-light .landing-toolbar {
  background: white;
  color: #424242;
}
.app.thm-gray-light .menu-toolbar {
  background: #212121;
  color: whitesmoke;
}
.app.thm-gray-light .ps__thumb-y {
  background-color: #616161;
}
.app.thm-gray-light .testimonial-item, .app.thm-gray-light .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.thm-gray-light .login-container {
  background: #fafafa;
}
.app.thm-gray-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-gray-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-gray-light .annular-pagination .ngx-pagination .current {
  background: #616161;
  color: white;
}
.app.thm-gray-light .annular-pagination .ngx-pagination a:hover, .app.thm-gray-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(97, 97, 97, 0.2);
  color: black;
}
.app.thm-gray-light .cal-day-view .cal-hour-segment:hover,
.app.thm-gray-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-gray-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-gray-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-gray-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-gray-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.thm-gray-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(97, 97, 97, 0.05);
}
.app.thm-gray-light .cal-week-view .cal-header.cal-today,
.app.thm-gray-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.thm-gray-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-gray-light .cal-event-action i {
  margin: 4px;
}
.app.thm-gray-light .ql-snow .ql-picker,
.app.thm-gray-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-gray-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-gray-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-gray-light .ui-dialog .ui-dialog-titlebar {
  background-color: #616161;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-gray-light .ui-button {
  color: !important;
  background-color: #616161;
  border: 1px solid #616161;
}
.app.thm-gray-light .ui-button.ui-button-primary {
  background-color: #616161;
  border: 1px solid #616161;
}
.app.thm-gray-light .ui-button.ui-button-secondary {
  background: #ff4081;
  color: white;
  border: 1px solid #ff4081;
}
.app.thm-gray-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-gray-light .ui-multiselect {
  display: block;
}
.app.thm-gray-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-gray-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-gray-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .form-separator {
  background: #616161;
  text-align: center;
  color: white;
}
.app.thm-gray-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-gray-light .titre-in-table {
  background: #ff4081;
  color: white;
  text-align: center;
}
.app.thm-gray-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-blue-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-option {
  color: white;
}
.app.thm-blue-dark .mat-option:hover:not(.mat-option-disabled), .app.thm-blue-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-blue-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-blue-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.thm-blue-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0288d1;
}
.app.thm-blue-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app.thm-blue-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-blue-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.thm-blue-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.thm-blue-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-blue-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0288d1;
}
.app.thm-blue-dark .mat-pseudo-checkbox-checked,
.app.thm-blue-dark .mat-pseudo-checkbox-indeterminate,
.app.thm-blue-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-blue-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app.thm-blue-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-blue-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-blue-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-blue-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.thm-blue-dark .mat-app-background, .app.thm-blue-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.thm-blue-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-blue-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.thm-blue-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.thm-blue-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.thm-blue-dark .mat-badge-content {
  color: white;
  background: #0288d1;
}
.cdk-high-contrast-active .app.thm-blue-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-blue-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-blue-dark .mat-badge {
  position: relative;
}
.app.thm-blue-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-blue-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-blue-dark .ng-animate-disabled .mat-badge-content,
.app.thm-blue-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-blue-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-blue-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-blue-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-blue-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-blue-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-blue-dark .mat-button, .app.thm-blue-dark .mat-icon-button, .app.thm-blue-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-blue-dark .mat-button.mat-primary, .app.thm-blue-dark .mat-icon-button.mat-primary, .app.thm-blue-dark .mat-stroked-button.mat-primary {
  color: #0288d1;
}
.app.thm-blue-dark .mat-button.mat-accent, .app.thm-blue-dark .mat-icon-button.mat-accent, .app.thm-blue-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app.thm-blue-dark .mat-button.mat-warn, .app.thm-blue-dark .mat-icon-button.mat-warn, .app.thm-blue-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-blue-dark .mat-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-blue-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-blue-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-blue-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-blue-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-blue-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-blue-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-blue-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-blue-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-blue-dark .mat-button .mat-ripple-element, .app.thm-blue-dark .mat-icon-button .mat-ripple-element, .app.thm-blue-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-blue-dark .mat-button-focus-overlay {
  background: white;
}
.app.thm-blue-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-flat-button, .app.thm-blue-dark .mat-raised-button, .app.thm-blue-dark .mat-fab, .app.thm-blue-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.thm-blue-dark .mat-flat-button.mat-primary, .app.thm-blue-dark .mat-raised-button.mat-primary, .app.thm-blue-dark .mat-fab.mat-primary, .app.thm-blue-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-blue-dark .mat-flat-button.mat-accent, .app.thm-blue-dark .mat-raised-button.mat-accent, .app.thm-blue-dark .mat-fab.mat-accent, .app.thm-blue-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-flat-button.mat-warn, .app.thm-blue-dark .mat-raised-button.mat-warn, .app.thm-blue-dark .mat-fab.mat-warn, .app.thm-blue-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-blue-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-flat-button.mat-primary, .app.thm-blue-dark .mat-raised-button.mat-primary, .app.thm-blue-dark .mat-fab.mat-primary, .app.thm-blue-dark .mat-mini-fab.mat-primary {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-flat-button.mat-accent, .app.thm-blue-dark .mat-raised-button.mat-accent, .app.thm-blue-dark .mat-fab.mat-accent, .app.thm-blue-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-flat-button.mat-warn, .app.thm-blue-dark .mat-raised-button.mat-warn, .app.thm-blue-dark .mat-fab.mat-warn, .app.thm-blue-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-blue-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-blue-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-blue-dark .mat-fab.mat-primary .mat-ripple-element, .app.thm-blue-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-blue-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-blue-dark .mat-fab.mat-accent .mat-ripple-element, .app.thm-blue-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-blue-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-blue-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-blue-dark .mat-fab.mat-warn .mat-ripple-element, .app.thm-blue-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-blue-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-fab:not([class*=mat-elevation-z]), .app.thm-blue-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-blue-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-blue-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-button-toggle-standalone,
.app.thm-blue-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-blue-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-blue-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.thm-blue-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.thm-blue-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.thm-blue-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.thm-blue-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.thm-blue-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.thm-blue-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-blue-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-card {
  background: #424242;
  color: white;
}
.app.thm-blue-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.thm-blue-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.thm-blue-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.thm-blue-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-blue-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-blue-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-blue-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-blue-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.thm-blue-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.thm-blue-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.thm-blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0288d1;
}
.app.thm-blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app.thm-blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-blue-dark .mat-table {
  background: #424242;
}
.app.thm-blue-dark .mat-table thead, .app.thm-blue-dark .mat-table tbody, .app.thm-blue-dark .mat-table tfoot,
.app.thm-blue-dark mat-header-row, .app.thm-blue-dark mat-row, .app.thm-blue-dark mat-footer-row,
.app.thm-blue-dark [mat-header-row], .app.thm-blue-dark [mat-row], .app.thm-blue-dark [mat-footer-row],
.app.thm-blue-dark .mat-table-sticky {
  background: inherit;
}
.app.thm-blue-dark mat-row, .app.thm-blue-dark mat-header-row, .app.thm-blue-dark mat-footer-row,
.app.thm-blue-dark th.mat-header-cell, .app.thm-blue-dark td.mat-cell, .app.thm-blue-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-cell, .app.thm-blue-dark .mat-footer-cell {
  color: white;
}
.app.thm-blue-dark .mat-calendar-arrow {
  border-top-color: white;
}
.app.thm-blue-dark .mat-datepicker-toggle,
.app.thm-blue-dark .mat-datepicker-content .mat-calendar-next-button,
.app.thm-blue-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.thm-blue-dark .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-calendar-body-cell-content,
.app.thm-blue-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.thm-blue-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-blue-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-blue-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.thm-blue-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.thm-blue-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-calendar-body-in-range::before {
  background: rgba(2, 136, 209, 0.2);
}
.app.thm-blue-dark .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-dark .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-dark .mat-calendar-body-selected {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-blue-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app.thm-blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-blue-dark .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-datepicker-toggle-active {
  color: #0288d1;
}
.app.thm-blue-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app.thm-blue-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-blue-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-blue-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.thm-blue-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-blue-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-blue-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-blue-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.thm-blue-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.thm-blue-dark .mat-expansion-panel-header-description,
.app.thm-blue-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-blue-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-blue-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #0288d1;
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-blue-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app.thm-blue-dark .mat-form-field-ripple {
  background-color: white;
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0288d1;
}
.app.thm-blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app.thm-blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-error {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-blue-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-blue-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.thm-blue-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-blue-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0288d1;
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.thm-blue-dark .mat-icon.mat-primary {
  color: #0288d1;
}
.app.thm-blue-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app.thm-blue-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-input-element:disabled,
.app.thm-blue-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-input-element {
  caret-color: #0288d1;
}
.app.thm-blue-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app.thm-blue-dark .mat-form-field.mat-warn .mat-input-element,
.app.thm-blue-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-blue-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-blue-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.thm-blue-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.thm-blue-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-list-item-disabled {
  background-color: black;
}
.app.thm-blue-dark .mat-list-option:hover, .app.thm-blue-dark .mat-list-option:focus,
.app.thm-blue-dark .mat-nav-list .mat-list-item:hover,
.app.thm-blue-dark .mat-nav-list .mat-list-item:focus,
.app.thm-blue-dark .mat-action-list .mat-list-item:hover,
.app.thm-blue-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-blue-dark .mat-list-single-selected-option, .app.thm-blue-dark .mat-list-single-selected-option:hover, .app.thm-blue-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-menu-panel {
  background: #424242;
}
.app.thm-blue-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.thm-blue-dark .mat-menu-item[disabled], .app.thm-blue-dark .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-menu-item .mat-icon-no-color,
.app.thm-blue-dark .mat-menu-item-submenu-trigger::after {
  color: white;
}
.app.thm-blue-dark .mat-menu-item:hover:not([disabled]),
.app.thm-blue-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-blue-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-blue-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-blue-dark .mat-paginator {
  background: #424242;
}
.app.thm-blue-dark .mat-paginator,
.app.thm-blue-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-paginator-decrement,
.app.thm-blue-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.thm-blue-dark .mat-paginator-first,
.app.thm-blue-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.thm-blue-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-blue-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-blue-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-blue-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-progress-bar-background {
  fill: #e1f5fe;
}
.app.thm-blue-dark .mat-progress-bar-buffer {
  background-color: #e1f5fe;
}
.app.thm-blue-dark .mat-progress-bar-fill::after {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffe57f;
}
.app.thm-blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffe57f;
}
.app.thm-blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-progress-spinner circle, .app.thm-blue-dark .mat-spinner circle {
  stroke: #0288d1;
}
.app.thm-blue-dark .mat-progress-spinner.mat-accent circle, .app.thm-blue-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app.thm-blue-dark .mat-progress-spinner.mat-warn circle, .app.thm-blue-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-blue-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0288d1;
}
.app.thm-blue-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-blue-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app.thm-blue-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-blue-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-blue-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-blue-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.thm-blue-dark .mat-select-value {
  color: white;
}
.app.thm-blue-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-select-panel {
  background: #424242;
}
.app.thm-blue-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0288d1;
}
.app.thm-blue-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app.thm-blue-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-blue-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.thm-blue-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.thm-blue-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.thm-blue-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-blue-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-blue-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.thm-blue-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app.thm-blue-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(2, 136, 209, 0.54);
}
.app.thm-blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.thm-blue-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.thm-blue-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-primary .mat-slider-track-fill,
.app.thm-blue-dark .mat-primary .mat-slider-thumb,
.app.thm-blue-dark .mat-primary .mat-slider-thumb-label {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-blue-dark .mat-primary .mat-slider-focus-ring {
  background-color: rgba(2, 136, 209, 0.2);
}
.app.thm-blue-dark .mat-accent .mat-slider-track-fill,
.app.thm-blue-dark .mat-accent .mat-slider-thumb,
.app.thm-blue-dark .mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app.thm-blue-dark .mat-warn .mat-slider-track-fill,
.app.thm-blue-dark .mat-warn .mat-slider-thumb,
.app.thm-blue-dark .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-blue-dark .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-blue-dark .mat-slider:hover .mat-slider-track-background,
.app.thm-blue-dark .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-disabled .mat-slider-track-background,
.app.thm-blue-dark .mat-slider-disabled .mat-slider-track-fill,
.app.thm-blue-dark .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-blue-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.thm-blue-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-blue-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.thm-blue-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-blue-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-blue-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-blue-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-blue-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-blue-dark .mat-step-header.cdk-keyboard-focused, .app.thm-blue-dark .mat-step-header.cdk-program-focused, .app.thm-blue-dark .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-blue-dark .mat-step-header:hover {
    background: none;
  }
}
.app.thm-blue-dark .mat-step-header .mat-step-label,
.app.thm-blue-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.thm-blue-dark .mat-step-header .mat-step-icon-selected,
.app.thm-blue-dark .mat-step-header .mat-step-icon-state-done,
.app.thm-blue-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-blue-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.thm-blue-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-blue-dark .mat-stepper-horizontal, .app.thm-blue-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.thm-blue-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-horizontal-stepper-header::before,
.app.thm-blue-dark .mat-horizontal-stepper-header::after,
.app.thm-blue-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.thm-blue-dark .mat-tab-nav-bar,
.app.thm-blue-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-blue-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.thm-blue-dark .mat-tab-label, .app.thm-blue-dark .mat-tab-link {
  color: white;
}
.app.thm-blue-dark .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-blue-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-blue-dark .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-blue-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-blue-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-blue-dark .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #0288d1;
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-dark .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-blue-dark .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.thm-blue-dark .mat-toolbar.mat-primary {
  background: #0288d1;
  color: white;
}
.app.thm-blue-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-blue-dark .mat-toolbar .mat-form-field-underline,
.app.thm-blue-dark .mat-toolbar .mat-form-field-ripple,
.app.thm-blue-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-blue-dark .mat-toolbar .mat-form-field-label,
.app.thm-blue-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-blue-dark .mat-toolbar .mat-select-value,
.app.thm-blue-dark .mat-toolbar .mat-select-arrow,
.app.thm-blue-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-blue-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-blue-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-blue-dark .mat-tree {
  background: #424242;
}
.app.thm-blue-dark .mat-tree-node,
.app.thm-blue-dark .mat-nested-tree-node {
  color: white;
}
.app.thm-blue-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.thm-blue-dark .bg-primary {
  background: #0288d1;
  color: white;
}
.app.thm-blue-dark .bg-primary-l-400 {
  background-color: #29b6f6;
  color: white;
}
.app.thm-blue-dark .bg-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-blue-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .primary-color {
  color: #0288d1 !important;
}
.app.thm-blue-dark .accent-color {
  color: #ffd740 !important;
}
.app.thm-blue-dark .warn-color {
  color: #ff5252 !important;
}
.app.thm-blue-dark .bg-card {
  background: #424242;
}
.app.thm-blue-dark .bg-e2 {
  background: #e2e2e2;
}
.app.thm-blue-dark .bg-white {
  background: #fff;
}
.app.thm-blue-dark .link-style {
  color: #ffd740;
  cursor: pointer;
}
.app.thm-blue-dark .card-style, .app.thm-blue-dark .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .ui-table-caption,
.app.thm-blue-dark .ui-table .ui-table-summary, .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .ui-dialog .app.thm-blue-dark .ui-dialog-content, .app.thm-blue-dark .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-blue-dark .ui-dialog-footer, .app.thm-blue-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box, .app.thm-blue-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-tabview .app.thm-blue-dark .ui-tabview-panels, .app.thm-blue-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-gray-light .ui-table .ui-table-caption, .app.thm-gray-light .ui-table .app.thm-blue-dark .ui-table-caption,
.app.thm-blue-dark .app.thm-gray-light .ui-table .ui-table-summary,
.app.thm-gray-light .ui-table .app.thm-blue-dark .ui-table-summary, .app.thm-blue-dark .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-blue-dark .ui-tree {
  background: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-blue-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-red-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-red-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-blue-dark .ui-multiselect-trigger, .app.thm-gray-light .ui-multiselect-panel .app.thm-blue-dark .ui-multiselect-header, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item, .app.thm-gray-light .ui-dropdown .app.thm-blue-dark .ui-dropdown-trigger {
  background: #424242;
  color: white;
}
.app.thm-blue-dark .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-chkbox .app.thm-blue-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-dark li.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-blue-dark .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-dark .ui-treenode-label.ui-state-highlight {
  background: #0288d1;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-dark .ui-multiselect-item.ui-state-highlight {
  background: #29b6f6;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-blue-dark .ui-table-tbody > tr:nth-child(even) {
  background: #303030;
  color: white;
}
.app.thm-blue-dark .border-error-style, .app.thm-blue-dark .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-blue-dark .clv-bg-danger, .app.thm-blue-dark .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-blue-dark .clv-color-danger, .app.thm-blue-dark .danger-color {
  color: #d32f2f;
}
.app.thm-blue-dark .clv-bg-danger-imp, .app.thm-blue-dark .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-danger-imp, .app.thm-blue-dark .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-blue-dark .clv-bg-success, .app.thm-blue-dark .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-blue-dark .clv-color-success, .app.thm-blue-dark .success-color {
  color: #388e3c;
}
.app.thm-blue-dark .clv-bg-success-imp, .app.thm-blue-dark .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-success-imp, .app.thm-blue-dark .success-color-imp {
  color: #388e3c !important;
}
.app.thm-blue-dark .clv-bg-warning, .app.thm-blue-dark .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .clv-color-warning, .app.thm-blue-dark .warning-color {
  color: #fbc02d;
}
.app.thm-blue-dark .clv-bg-warning-imp, .app.thm-blue-dark .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-blue-dark .clv-color-warning-imp, .app.thm-blue-dark .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-blue-dark .clv-bg-orange, .app.thm-blue-dark .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-dark .clv-color-orange, .app.thm-blue-dark .orange-color {
  color: #ffa000;
}
.app.thm-blue-dark .clv-bg-orange-imp, .app.thm-blue-dark .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-blue-dark .clv-color-orange-imp, .app.thm-blue-dark .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-blue-dark .clv-bg-info, .app.thm-blue-dark .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-blue-dark .clv-color-info, .app.thm-blue-dark .info-color {
  color: #0288d1;
}
.app.thm-blue-dark .clv-bg-info-imp, .app.thm-blue-dark .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-info-imp, .app.thm-blue-dark .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-blue-dark .clv-bg-blue, .app.thm-blue-dark .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-blue-dark .clv-color-blue, .app.thm-blue-dark .blue-color {
  color: #1976d2;
}
.app.thm-blue-dark .clv-bg-blue-imp, .app.thm-blue-dark .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-blue-imp, .app.thm-blue-dark .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-blue-dark .clv-bg-indigo, .app.thm-blue-dark .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-blue-dark .clv-color-indigo, .app.thm-blue-dark .indigo-color {
  color: #303f9f;
}
.app.thm-blue-dark .clv-bg-indigo-imp, .app.thm-blue-dark .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-indigo-imp, .app.thm-blue-dark .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-blue-dark .clv-bg-brown, .app.thm-blue-dark .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-blue-dark .clv-color-brown, .app.thm-blue-dark .brown-color {
  color: #5d4037;
}
.app.thm-blue-dark .clv-bg-brown-imp, .app.thm-blue-dark .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-blue-dark .clv-color-brown-imp, .app.thm-blue-dark .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-blue-dark .connexion-titre {
  color: #0288d1;
}
.app.thm-blue-dark .header {
  background: #424242;
  color: white;
}
.app.thm-blue-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .content-header h4 {
  color: #e1f5fe;
}
.app.thm-blue-dark .favorites-container:after {
  color: #ffd740;
}
.app.thm-blue-dark .horizontal-menu {
  background: #424242;
}
.app.thm-blue-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.thm-blue-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.thm-blue-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.thm-blue-dark .menu-item .mat-button .menu-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.thm-blue-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .menu-item .mat-button.active-link .menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.thm-blue-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-blue-dark .breadcrumb a {
  color: #e1f5fe;
}
.app.thm-blue-dark .top-toolbar, .app.thm-blue-dark .landing-toolbar {
  background: white;
  color: #0277bd;
}
.app.thm-blue-dark .menu-toolbar {
  background: #01579b;
  color: #b3e5fc;
}
.app.thm-blue-dark .ps__thumb-y {
  background-color: #0288d1;
}
.app.thm-blue-dark .testimonial-item, .app.thm-blue-dark .pricing-card {
  border-top: 4px solid #ffd740;
}
.app.thm-blue-dark .login-container {
  background: #303030;
}
.app.thm-blue-dark .ngx-charts text {
  fill: white;
}
.app.thm-blue-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-blue-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-blue-dark .annular-pagination .ngx-pagination .current {
  background: #0288d1;
  color: white;
}
.app.thm-blue-dark .annular-pagination .ngx-pagination a:hover, .app.thm-blue-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(2, 136, 209, 0.2);
  color: white;
}
.app.thm-blue-dark .cal-day-view .cal-hour-segment:hover,
.app.thm-blue-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-blue-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-blue-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-blue-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-blue-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(2, 136, 209, 0.05);
}
.app.thm-blue-dark .cal-week-view .cal-header.cal-today,
.app.thm-blue-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-blue-dark .cal-event-action i {
  margin: 4px;
}
.app.thm-blue-dark .ql-snow .ql-picker,
.app.thm-blue-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.thm-blue-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.thm-blue-dark .ql-snow .ql-fill {
  fill: white;
}
.app.thm-blue-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-blue-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.thm-blue-dark .ngx-charts text {
  fill: white;
}
.app.thm-blue-dark .mat-table td {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-blue-dark .ui-dialog .ui-dialog-titlebar {
  background-color: #0288d1;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-blue-dark .ui-button {
  color: !important;
  background-color: #0288d1;
  border: 1px solid #0288d1;
}
.app.thm-blue-dark .ui-button.ui-button-primary {
  background-color: #0288d1;
  border: 1px solid #0288d1;
}
.app.thm-blue-dark .ui-button.ui-button-secondary {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ffd740;
}
.app.thm-blue-dark .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-blue-dark .ui-multiselect {
  display: block;
}
.app.thm-blue-dark .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-blue-dark .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-blue-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .form-separator {
  background: #0288d1;
  text-align: center;
  color: white;
}
.app.thm-blue-dark .form-separator::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-dark .titre-in-table {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.app.thm-blue-dark .titre-in-table::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-blue-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-blue-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-option:hover:not(.mat-option-disabled), .app.thm-blue-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0288d1;
}
.app.thm-blue-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffab40;
}
.app.thm-blue-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-blue-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.thm-blue-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.thm-blue-light .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-blue-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0288d1;
}
.app.thm-blue-light .mat-pseudo-checkbox-checked,
.app.thm-blue-light .mat-pseudo-checkbox-indeterminate,
.app.thm-blue-light .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-blue-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffab40;
}
.app.thm-blue-light .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-blue-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-blue-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-blue-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.thm-blue-light .mat-app-background, .app.thm-blue-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-blue-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.thm-blue-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-badge-content {
  color: white;
  background: #0288d1;
}
.cdk-high-contrast-active .app.thm-blue-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-blue-light .mat-badge-accent .mat-badge-content {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-blue-light .mat-badge {
  position: relative;
}
.app.thm-blue-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-blue-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-blue-light .ng-animate-disabled .mat-badge-content,
.app.thm-blue-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-blue-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-blue-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-blue-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-blue-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-blue-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-blue-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-blue-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-blue-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-blue-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-button, .app.thm-blue-light .mat-icon-button, .app.thm-blue-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-blue-light .mat-button.mat-primary, .app.thm-blue-light .mat-icon-button.mat-primary, .app.thm-blue-light .mat-stroked-button.mat-primary {
  color: #0288d1;
}
.app.thm-blue-light .mat-button.mat-accent, .app.thm-blue-light .mat-icon-button.mat-accent, .app.thm-blue-light .mat-stroked-button.mat-accent {
  color: #ffab40;
}
.app.thm-blue-light .mat-button.mat-warn, .app.thm-blue-light .mat-icon-button.mat-warn, .app.thm-blue-light .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-blue-light .mat-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-blue-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-blue-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-blue-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-blue-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-blue-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-blue-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-blue-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-blue-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-blue-light .mat-button .mat-ripple-element, .app.thm-blue-light .mat-icon-button .mat-ripple-element, .app.thm-blue-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-blue-light .mat-button-focus-overlay {
  background: black;
}
.app.thm-blue-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-flat-button, .app.thm-blue-light .mat-raised-button, .app.thm-blue-light .mat-fab, .app.thm-blue-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.thm-blue-light .mat-flat-button.mat-primary, .app.thm-blue-light .mat-raised-button.mat-primary, .app.thm-blue-light .mat-fab.mat-primary, .app.thm-blue-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-blue-light .mat-flat-button.mat-accent, .app.thm-blue-light .mat-raised-button.mat-accent, .app.thm-blue-light .mat-fab.mat-accent, .app.thm-blue-light .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-flat-button.mat-warn, .app.thm-blue-light .mat-raised-button.mat-warn, .app.thm-blue-light .mat-fab.mat-warn, .app.thm-blue-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-blue-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-flat-button.mat-primary, .app.thm-blue-light .mat-raised-button.mat-primary, .app.thm-blue-light .mat-fab.mat-primary, .app.thm-blue-light .mat-mini-fab.mat-primary {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-flat-button.mat-accent, .app.thm-blue-light .mat-raised-button.mat-accent, .app.thm-blue-light .mat-fab.mat-accent, .app.thm-blue-light .mat-mini-fab.mat-accent {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-flat-button.mat-warn, .app.thm-blue-light .mat-raised-button.mat-warn, .app.thm-blue-light .mat-fab.mat-warn, .app.thm-blue-light .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-blue-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-blue-light .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-blue-light .mat-fab.mat-primary .mat-ripple-element, .app.thm-blue-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-light .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-blue-light .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-blue-light .mat-fab.mat-accent .mat-ripple-element, .app.thm-blue-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-blue-light .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-blue-light .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-blue-light .mat-fab.mat-warn .mat-ripple-element, .app.thm-blue-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-blue-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-fab:not([class*=mat-elevation-z]), .app.thm-blue-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-blue-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-blue-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-button-toggle-standalone,
.app.thm-blue-light .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-blue-light .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-blue-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.thm-blue-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.thm-blue-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.thm-blue-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.thm-blue-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.thm-blue-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-blue-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.thm-blue-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.thm-blue-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.thm-blue-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-blue-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-blue-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-blue-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-blue-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.thm-blue-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.thm-blue-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.thm-blue-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-blue-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0288d1;
}
.app.thm-blue-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-blue-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffab40;
}
.app.thm-blue-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-blue-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-blue-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-blue-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-blue-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-blue-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-blue-light .mat-table {
  background: white;
}
.app.thm-blue-light .mat-table thead, .app.thm-blue-light .mat-table tbody, .app.thm-blue-light .mat-table tfoot,
.app.thm-blue-light mat-header-row, .app.thm-blue-light mat-row, .app.thm-blue-light mat-footer-row,
.app.thm-blue-light [mat-header-row], .app.thm-blue-light [mat-row], .app.thm-blue-light [mat-footer-row],
.app.thm-blue-light .mat-table-sticky {
  background: inherit;
}
.app.thm-blue-light mat-row, .app.thm-blue-light mat-header-row, .app.thm-blue-light mat-footer-row,
.app.thm-blue-light th.mat-header-cell, .app.thm-blue-light td.mat-cell, .app.thm-blue-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-cell, .app.thm-blue-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-datepicker-toggle,
.app.thm-blue-light .mat-datepicker-content .mat-calendar-next-button,
.app.thm-blue-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-calendar-body-cell-content,
.app.thm-blue-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.thm-blue-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-blue-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-blue-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.thm-blue-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.thm-blue-light .mat-calendar-body-in-range::before {
  background: rgba(2, 136, 209, 0.2);
}
.app.thm-blue-light .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-light .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-light .mat-calendar-body-selected {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-blue-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 171, 64, 0.2);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 171, 64, 0.4);
}
.app.thm-blue-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-blue-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-blue-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-blue-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-blue-light .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-datepicker-toggle-active {
  color: #0288d1;
}
.app.thm-blue-light .mat-datepicker-toggle-active.mat-accent {
  color: #ffab40;
}
.app.thm-blue-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-blue-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-blue-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-blue-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-blue-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.thm-blue-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-expansion-panel-header-description,
.app.thm-blue-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-blue-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-blue-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-blue-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #0288d1;
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffab40;
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-blue-light .mat-focused .mat-form-field-required-marker {
  color: #ffab40;
}
.app.thm-blue-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0288d1;
}
.app.thm-blue-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffab40;
}
.app.thm-blue-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-blue-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-blue-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-error {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-blue-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-blue-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.thm-blue-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-blue-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0288d1;
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffab40;
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.thm-blue-light .mat-icon.mat-primary {
  color: #0288d1;
}
.app.thm-blue-light .mat-icon.mat-accent {
  color: #ffab40;
}
.app.thm-blue-light .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-input-element:disabled,
.app.thm-blue-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-input-element {
  caret-color: #0288d1;
}
.app.thm-blue-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffab40;
}
.app.thm-blue-light .mat-form-field.mat-warn .mat-input-element,
.app.thm-blue-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-blue-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-blue-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-list-item-disabled {
  background-color: #eeeeee;
}
.app.thm-blue-light .mat-list-option:hover, .app.thm-blue-light .mat-list-option:focus,
.app.thm-blue-light .mat-nav-list .mat-list-item:hover,
.app.thm-blue-light .mat-nav-list .mat-list-item:focus,
.app.thm-blue-light .mat-action-list .mat-list-item:hover,
.app.thm-blue-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-list-single-selected-option, .app.thm-blue-light .mat-list-single-selected-option:hover, .app.thm-blue-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-menu-panel {
  background: white;
}
.app.thm-blue-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-menu-item[disabled], .app.thm-blue-light .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-menu-item .mat-icon-no-color,
.app.thm-blue-light .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-menu-item:hover:not([disabled]),
.app.thm-blue-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-blue-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-blue-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.thm-blue-light .mat-paginator {
  background: white;
}
.app.thm-blue-light .mat-paginator,
.app.thm-blue-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-paginator-decrement,
.app.thm-blue-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-paginator-first,
.app.thm-blue-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-blue-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-blue-light .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-blue-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-progress-bar-background {
  fill: #e1f5fe;
}
.app.thm-blue-light .mat-progress-bar-buffer {
  background-color: #e1f5fe;
}
.app.thm-blue-light .mat-progress-bar-fill::after {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffd180;
}
.app.thm-blue-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffd180;
}
.app.thm-blue-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-blue-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-blue-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-progress-spinner circle, .app.thm-blue-light .mat-spinner circle {
  stroke: #0288d1;
}
.app.thm-blue-light .mat-progress-spinner.mat-accent circle, .app.thm-blue-light .mat-spinner.mat-accent circle {
  stroke: #ffab40;
}
.app.thm-blue-light .mat-progress-spinner.mat-warn circle, .app.thm-blue-light .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-blue-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0288d1;
}
.app.thm-blue-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-blue-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffab40;
}
.app.thm-blue-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-blue-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-blue-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-blue-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-blue-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-blue-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-blue-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-blue-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.thm-blue-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.thm-blue-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-select-panel {
  background: white;
}
.app.thm-blue-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0288d1;
}
.app.thm-blue-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffab40;
}
.app.thm-blue-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-blue-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.thm-blue-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-blue-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.thm-blue-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.thm-blue-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 64, 0.54);
}
.app.thm-blue-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(2, 136, 209, 0.54);
}
.app.thm-blue-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-blue-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.thm-blue-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.thm-blue-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-primary .mat-slider-track-fill,
.app.thm-blue-light .mat-primary .mat-slider-thumb,
.app.thm-blue-light .mat-primary .mat-slider-thumb-label {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-blue-light .mat-primary .mat-slider-focus-ring {
  background-color: rgba(2, 136, 209, 0.2);
}
.app.thm-blue-light .mat-accent .mat-slider-track-fill,
.app.thm-blue-light .mat-accent .mat-slider-thumb,
.app.thm-blue-light .mat-accent .mat-slider-thumb-label {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 171, 64, 0.2);
}
.app.thm-blue-light .mat-warn .mat-slider-track-fill,
.app.thm-blue-light .mat-warn .mat-slider-thumb,
.app.thm-blue-light .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-blue-light .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-blue-light .mat-slider:hover .mat-slider-track-background,
.app.thm-blue-light .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-slider-disabled .mat-slider-track-background,
.app.thm-blue-light .mat-slider-disabled .mat-slider-track-fill,
.app.thm-blue-light .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-blue-light .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-blue-light .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.thm-blue-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-blue-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-blue-light .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.thm-blue-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.thm-blue-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-blue-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.thm-blue-light .mat-step-header.cdk-keyboard-focused, .app.thm-blue-light .mat-step-header.cdk-program-focused, .app.thm-blue-light .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.thm-blue-light .mat-step-header:hover {
    background: none;
  }
}
.app.thm-blue-light .mat-step-header .mat-step-label,
.app.thm-blue-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.thm-blue-light .mat-step-header .mat-step-icon-selected,
.app.thm-blue-light .mat-step-header .mat-step-icon-state-done,
.app.thm-blue-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #0288d1;
  color: white;
}
.app.thm-blue-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-blue-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-blue-light .mat-stepper-horizontal, .app.thm-blue-light .mat-stepper-vertical {
  background-color: white;
}
.app.thm-blue-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-horizontal-stepper-header::before,
.app.thm-blue-light .mat-horizontal-stepper-header::after,
.app.thm-blue-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-sort-header-arrow {
  color: #757575;
}
.app.thm-blue-light .mat-tab-nav-bar,
.app.thm-blue-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-blue-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.thm-blue-light .mat-tab-label, .app.thm-blue-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.thm-blue-light .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-blue-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-blue-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-blue-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-blue-light .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #0288d1;
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-blue-light .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffab40;
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-blue-light .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-blue-light .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-blue-light .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-blue-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-toolbar.mat-primary {
  background: #0288d1;
  color: white;
}
.app.thm-blue-light .mat-toolbar.mat-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-blue-light .mat-toolbar .mat-form-field-underline,
.app.thm-blue-light .mat-toolbar .mat-form-field-ripple,
.app.thm-blue-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-blue-light .mat-toolbar .mat-form-field-label,
.app.thm-blue-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-blue-light .mat-toolbar .mat-select-value,
.app.thm-blue-light .mat-toolbar .mat-select-arrow,
.app.thm-blue-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-blue-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-blue-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-blue-light .mat-tree {
  background: white;
}
.app.thm-blue-light .mat-tree-node,
.app.thm-blue-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .mat-simple-snackbar-action {
  color: #ffab40;
}
.app.thm-blue-light .bg-primary {
  background: #0288d1;
  color: white;
}
.app.thm-blue-light .bg-primary-l-400 {
  background-color: #29b6f6;
  color: white;
}
.app.thm-blue-light .bg-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-blue-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .primary-color {
  color: #0288d1 !important;
}
.app.thm-blue-light .accent-color {
  color: #ffab40 !important;
}
.app.thm-blue-light .warn-color {
  color: #ff5252 !important;
}
.app.thm-blue-light .bg-card {
  background: white;
}
.app.thm-blue-light .bg-e2 {
  background: #e2e2e2;
}
.app.thm-blue-light .bg-white {
  background: #fff;
}
.app.thm-blue-light .link-style {
  color: #ffab40;
  cursor: pointer;
}
.app.thm-blue-light .card-style, .app.thm-blue-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .ui-tree, .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .ui-table-caption,
.app.thm-blue-light .ui-table .ui-table-summary, .app.thm-blue-light .ui-paginator, .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-gray-light .ui-table .ui-table-caption, .app.thm-gray-light .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-gray-light .ui-table .ui-table-summary,
.app.thm-gray-light .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .ui-dialog .app.thm-blue-light .ui-dialog-content, .app.thm-blue-light .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-blue-light .ui-dialog-footer, .app.thm-blue-light .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box, .app.thm-blue-light .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-blue-light .ui-tabview-panels, .app.thm-blue-light .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-blue-light .ui-table-caption,
.app.thm-blue-light .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-blue-light .ui-table-summary, .app.thm-blue-light .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-blue-light .ui-tree {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-blue-light .ui-dropdown .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-blue-light .ui-multiselect .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-red-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-red-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-gray-light .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-gray-light .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-blue-light .ui-multiselect-trigger, .app.thm-blue-dark .ui-multiselect-panel .app.thm-blue-light .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item, .app.thm-blue-dark .ui-dropdown .app.thm-blue-light .ui-dropdown-trigger {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-chkbox .app.thm-blue-light .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-blue-light li.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-blue-light .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-blue-light .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-blue-light .ui-treenode-label.ui-state-highlight {
  background: #0288d1;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-blue-light .ui-multiselect-item.ui-state-highlight {
  background: #29b6f6;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-blue-light .ui-table-tbody > tr:nth-child(even) {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .border-error-style, .app.thm-blue-light .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-blue-light .clv-bg-danger, .app.thm-blue-light .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-blue-light .clv-color-danger, .app.thm-blue-light .danger-color {
  color: #d32f2f;
}
.app.thm-blue-light .clv-bg-danger-imp, .app.thm-blue-light .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-danger-imp, .app.thm-blue-light .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-blue-light .clv-bg-success, .app.thm-blue-light .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-blue-light .clv-color-success, .app.thm-blue-light .success-color {
  color: #388e3c;
}
.app.thm-blue-light .clv-bg-success-imp, .app.thm-blue-light .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-success-imp, .app.thm-blue-light .success-color-imp {
  color: #388e3c !important;
}
.app.thm-blue-light .clv-bg-warning, .app.thm-blue-light .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .clv-color-warning, .app.thm-blue-light .warning-color {
  color: #fbc02d;
}
.app.thm-blue-light .clv-bg-warning-imp, .app.thm-blue-light .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-blue-light .clv-color-warning-imp, .app.thm-blue-light .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-blue-light .clv-bg-orange, .app.thm-blue-light .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .clv-color-orange, .app.thm-blue-light .orange-color {
  color: #ffa000;
}
.app.thm-blue-light .clv-bg-orange-imp, .app.thm-blue-light .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-blue-light .clv-color-orange-imp, .app.thm-blue-light .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-blue-light .clv-bg-info, .app.thm-blue-light .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-blue-light .clv-color-info, .app.thm-blue-light .info-color {
  color: #0288d1;
}
.app.thm-blue-light .clv-bg-info-imp, .app.thm-blue-light .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-info-imp, .app.thm-blue-light .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-blue-light .clv-bg-blue, .app.thm-blue-light .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-blue-light .clv-color-blue, .app.thm-blue-light .blue-color {
  color: #1976d2;
}
.app.thm-blue-light .clv-bg-blue-imp, .app.thm-blue-light .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-blue-imp, .app.thm-blue-light .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-blue-light .clv-bg-indigo, .app.thm-blue-light .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-blue-light .clv-color-indigo, .app.thm-blue-light .indigo-color {
  color: #303f9f;
}
.app.thm-blue-light .clv-bg-indigo-imp, .app.thm-blue-light .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-indigo-imp, .app.thm-blue-light .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-blue-light .clv-bg-brown, .app.thm-blue-light .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-blue-light .clv-color-brown, .app.thm-blue-light .brown-color {
  color: #5d4037;
}
.app.thm-blue-light .clv-bg-brown-imp, .app.thm-blue-light .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-blue-light .clv-color-brown-imp, .app.thm-blue-light .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-blue-light .connexion-titre {
  color: #0288d1;
}
.app.thm-blue-light .header {
  background: white;
  color: black;
}
.app.thm-blue-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .content-header h4 {
  color: #e1f5fe;
}
.app.thm-blue-light .favorites-container:after {
  color: #ffab40;
}
.app.thm-blue-light .horizontal-menu {
  background: white;
}
.app.thm-blue-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.thm-blue-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.thm-blue-light .menu-item .mat-button .menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .menu-item .mat-button.active-link .menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.thm-blue-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.thm-blue-light .breadcrumb a {
  color: #e1f5fe;
}
.app.thm-blue-light .top-toolbar, .app.thm-blue-light .landing-toolbar {
  background: white;
  color: #0277bd;
}
.app.thm-blue-light .menu-toolbar {
  background: #01579b;
  color: #b3e5fc;
}
.app.thm-blue-light .ps__thumb-y {
  background-color: #0288d1;
}
.app.thm-blue-light .testimonial-item, .app.thm-blue-light .pricing-card {
  border-top: 4px solid #ffab40;
}
.app.thm-blue-light .login-container {
  background: #fafafa;
}
.app.thm-blue-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-blue-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-blue-light .annular-pagination .ngx-pagination .current {
  background: #0288d1;
  color: white;
}
.app.thm-blue-light .annular-pagination .ngx-pagination a:hover, .app.thm-blue-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(2, 136, 209, 0.2);
  color: black;
}
.app.thm-blue-light .cal-day-view .cal-hour-segment:hover,
.app.thm-blue-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-blue-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-blue-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-blue-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-blue-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(2, 136, 209, 0.05);
}
.app.thm-blue-light .cal-week-view .cal-header.cal-today,
.app.thm-blue-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.thm-blue-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-blue-light .cal-event-action i {
  margin: 4px;
}
.app.thm-blue-light .ql-snow .ql-picker,
.app.thm-blue-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-blue-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.thm-blue-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.thm-blue-light .mat-table td {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-blue-light .ui-dialog .ui-dialog-titlebar {
  background-color: #0288d1;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-blue-light .ui-button {
  color: !important;
  background-color: #0288d1;
  border: 1px solid #0288d1;
}
.app.thm-blue-light .ui-button.ui-button-primary {
  background-color: #0288d1;
  border: 1px solid #0288d1;
}
.app.thm-blue-light .ui-button.ui-button-secondary {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ffab40;
}
.app.thm-blue-light .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-blue-light .ui-multiselect {
  display: block;
}
.app.thm-blue-light .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-blue-light .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-blue-light .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .form-separator {
  background: #0288d1;
  text-align: center;
  color: white;
}
.app.thm-blue-light .form-separator::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-blue-light .titre-in-table {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.app.thm-blue-light .titre-in-table::before {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-green-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-option {
  color: white;
}
.app.thm-green-dark .mat-option:hover:not(.mat-option-disabled), .app.thm-green-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-green-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-green-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.thm-green-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #388e3c;
}
.app.thm-green-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app.thm-green-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-green-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.thm-green-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.thm-green-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-green-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #388e3c;
}
.app.thm-green-dark .mat-pseudo-checkbox-checked,
.app.thm-green-dark .mat-pseudo-checkbox-indeterminate,
.app.thm-green-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-green-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app.thm-green-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-green-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-green-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-green-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.thm-green-dark .mat-app-background, .app.thm-green-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.thm-green-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-green-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.thm-green-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.thm-green-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.thm-green-dark .mat-badge-content {
  color: white;
  background: #388e3c;
}
.cdk-high-contrast-active .app.thm-green-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-green-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-green-dark .mat-badge {
  position: relative;
}
.app.thm-green-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-green-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-green-dark .ng-animate-disabled .mat-badge-content,
.app.thm-green-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-green-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-green-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-green-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-green-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-green-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-green-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-green-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-green-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-green-dark .mat-button, .app.thm-green-dark .mat-icon-button, .app.thm-green-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-green-dark .mat-button.mat-primary, .app.thm-green-dark .mat-icon-button.mat-primary, .app.thm-green-dark .mat-stroked-button.mat-primary {
  color: #388e3c;
}
.app.thm-green-dark .mat-button.mat-accent, .app.thm-green-dark .mat-icon-button.mat-accent, .app.thm-green-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app.thm-green-dark .mat-button.mat-warn, .app.thm-green-dark .mat-icon-button.mat-warn, .app.thm-green-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-green-dark .mat-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-green-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-green-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-green-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-green-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-green-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-green-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-green-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-green-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-green-dark .mat-button .mat-ripple-element, .app.thm-green-dark .mat-icon-button .mat-ripple-element, .app.thm-green-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-green-dark .mat-button-focus-overlay {
  background: white;
}
.app.thm-green-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-flat-button, .app.thm-green-dark .mat-raised-button, .app.thm-green-dark .mat-fab, .app.thm-green-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.thm-green-dark .mat-flat-button.mat-primary, .app.thm-green-dark .mat-raised-button.mat-primary, .app.thm-green-dark .mat-fab.mat-primary, .app.thm-green-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-green-dark .mat-flat-button.mat-accent, .app.thm-green-dark .mat-raised-button.mat-accent, .app.thm-green-dark .mat-fab.mat-accent, .app.thm-green-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-flat-button.mat-warn, .app.thm-green-dark .mat-raised-button.mat-warn, .app.thm-green-dark .mat-fab.mat-warn, .app.thm-green-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-green-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-flat-button.mat-primary, .app.thm-green-dark .mat-raised-button.mat-primary, .app.thm-green-dark .mat-fab.mat-primary, .app.thm-green-dark .mat-mini-fab.mat-primary {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-flat-button.mat-accent, .app.thm-green-dark .mat-raised-button.mat-accent, .app.thm-green-dark .mat-fab.mat-accent, .app.thm-green-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-flat-button.mat-warn, .app.thm-green-dark .mat-raised-button.mat-warn, .app.thm-green-dark .mat-fab.mat-warn, .app.thm-green-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-green-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-green-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-green-dark .mat-fab.mat-primary .mat-ripple-element, .app.thm-green-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-green-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-green-dark .mat-fab.mat-accent .mat-ripple-element, .app.thm-green-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-green-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-green-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-green-dark .mat-fab.mat-warn .mat-ripple-element, .app.thm-green-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-green-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-fab:not([class*=mat-elevation-z]), .app.thm-green-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-green-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-green-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-button-toggle-standalone,
.app.thm-green-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-green-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-green-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.thm-green-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.thm-green-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.thm-green-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.thm-green-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.thm-green-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.thm-green-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-green-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-card {
  background: #424242;
  color: white;
}
.app.thm-green-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.thm-green-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.thm-green-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.thm-green-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-green-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-green-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-green-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-green-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.thm-green-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.thm-green-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.thm-green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #388e3c;
}
.app.thm-green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app.thm-green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-green-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.thm-green-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-green-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-green-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.thm-green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.thm-green-dark .mat-table {
  background: #424242;
}
.app.thm-green-dark .mat-table thead, .app.thm-green-dark .mat-table tbody, .app.thm-green-dark .mat-table tfoot,
.app.thm-green-dark mat-header-row, .app.thm-green-dark mat-row, .app.thm-green-dark mat-footer-row,
.app.thm-green-dark [mat-header-row], .app.thm-green-dark [mat-row], .app.thm-green-dark [mat-footer-row],
.app.thm-green-dark .mat-table-sticky {
  background: inherit;
}
.app.thm-green-dark mat-row, .app.thm-green-dark mat-header-row, .app.thm-green-dark mat-footer-row,
.app.thm-green-dark th.mat-header-cell, .app.thm-green-dark td.mat-cell, .app.thm-green-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-cell, .app.thm-green-dark .mat-footer-cell {
  color: white;
}
.app.thm-green-dark .mat-calendar-arrow {
  border-top-color: white;
}
.app.thm-green-dark .mat-datepicker-toggle,
.app.thm-green-dark .mat-datepicker-content .mat-calendar-next-button,
.app.thm-green-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.thm-green-dark .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-calendar-body-cell-content,
.app.thm-green-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.thm-green-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-green-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-green-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.thm-green-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.thm-green-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-calendar-body-in-range::before {
  background: rgba(56, 142, 60, 0.2);
}
.app.thm-green-dark .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-dark .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-dark .mat-calendar-body-selected {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-green-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app.thm-green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-green-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-green-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-green-dark .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-datepicker-toggle-active {
  color: #388e3c;
}
.app.thm-green-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app.thm-green-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-green-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-green-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.thm-green-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-green-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-green-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-green-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.thm-green-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.thm-green-dark .mat-expansion-panel-header-description,
.app.thm-green-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-green-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-green-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #388e3c;
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-green-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app.thm-green-dark .mat-form-field-ripple {
  background-color: white;
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #388e3c;
}
.app.thm-green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app.thm-green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-error {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-green-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-green-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.thm-green-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-green-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #388e3c;
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.thm-green-dark .mat-icon.mat-primary {
  color: #388e3c;
}
.app.thm-green-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app.thm-green-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-input-element:disabled,
.app.thm-green-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-input-element {
  caret-color: #388e3c;
}
.app.thm-green-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-green-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app.thm-green-dark .mat-form-field.mat-warn .mat-input-element,
.app.thm-green-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-green-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-green-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.thm-green-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.thm-green-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-list-item-disabled {
  background-color: black;
}
.app.thm-green-dark .mat-list-option:hover, .app.thm-green-dark .mat-list-option:focus,
.app.thm-green-dark .mat-nav-list .mat-list-item:hover,
.app.thm-green-dark .mat-nav-list .mat-list-item:focus,
.app.thm-green-dark .mat-action-list .mat-list-item:hover,
.app.thm-green-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-green-dark .mat-list-single-selected-option, .app.thm-green-dark .mat-list-single-selected-option:hover, .app.thm-green-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-menu-panel {
  background: #424242;
}
.app.thm-green-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.thm-green-dark .mat-menu-item[disabled], .app.thm-green-dark .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-menu-item .mat-icon-no-color,
.app.thm-green-dark .mat-menu-item-submenu-trigger::after {
  color: white;
}
.app.thm-green-dark .mat-menu-item:hover:not([disabled]),
.app.thm-green-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-green-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-green-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-green-dark .mat-paginator {
  background: #424242;
}
.app.thm-green-dark .mat-paginator,
.app.thm-green-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-paginator-decrement,
.app.thm-green-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.thm-green-dark .mat-paginator-first,
.app.thm-green-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.thm-green-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-green-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-green-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-green-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-progress-bar-background {
  fill: #e8f5e9;
}
.app.thm-green-dark .mat-progress-bar-buffer {
  background-color: #e8f5e9;
}
.app.thm-green-dark .mat-progress-bar-fill::after {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffe57f;
}
.app.thm-green-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffe57f;
}
.app.thm-green-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-green-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-green-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-progress-spinner circle, .app.thm-green-dark .mat-spinner circle {
  stroke: #388e3c;
}
.app.thm-green-dark .mat-progress-spinner.mat-accent circle, .app.thm-green-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app.thm-green-dark .mat-progress-spinner.mat-warn circle, .app.thm-green-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-green-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #388e3c;
}
.app.thm-green-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-green-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app.thm-green-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-green-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-green-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-green-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-green-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-green-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-green-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-green-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.thm-green-dark .mat-select-value {
  color: white;
}
.app.thm-green-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-select-panel {
  background: #424242;
}
.app.thm-green-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #388e3c;
}
.app.thm-green-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app.thm-green-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-green-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.thm-green-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.thm-green-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.thm-green-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-green-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-green-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.thm-green-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app.thm-green-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(56, 142, 60, 0.54);
}
.app.thm-green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.thm-green-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.thm-green-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-primary .mat-slider-track-fill,
.app.thm-green-dark .mat-primary .mat-slider-thumb,
.app.thm-green-dark .mat-primary .mat-slider-thumb-label {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-green-dark .mat-primary .mat-slider-focus-ring {
  background-color: rgba(56, 142, 60, 0.2);
}
.app.thm-green-dark .mat-accent .mat-slider-track-fill,
.app.thm-green-dark .mat-accent .mat-slider-thumb,
.app.thm-green-dark .mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app.thm-green-dark .mat-warn .mat-slider-track-fill,
.app.thm-green-dark .mat-warn .mat-slider-thumb,
.app.thm-green-dark .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-green-dark .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-green-dark .mat-slider:hover .mat-slider-track-background,
.app.thm-green-dark .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-disabled .mat-slider-track-background,
.app.thm-green-dark .mat-slider-disabled .mat-slider-track-fill,
.app.thm-green-dark .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-green-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.thm-green-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-green-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.thm-green-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-green-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-green-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-green-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-green-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-green-dark .mat-step-header.cdk-keyboard-focused, .app.thm-green-dark .mat-step-header.cdk-program-focused, .app.thm-green-dark .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-green-dark .mat-step-header:hover {
    background: none;
  }
}
.app.thm-green-dark .mat-step-header .mat-step-label,
.app.thm-green-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.thm-green-dark .mat-step-header .mat-step-icon-selected,
.app.thm-green-dark .mat-step-header .mat-step-icon-state-done,
.app.thm-green-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #388e3c;
  color: white;
}
.app.thm-green-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-green-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.thm-green-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-green-dark .mat-stepper-horizontal, .app.thm-green-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.thm-green-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-horizontal-stepper-header::before,
.app.thm-green-dark .mat-horizontal-stepper-header::after,
.app.thm-green-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.thm-green-dark .mat-tab-nav-bar,
.app.thm-green-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-green-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.thm-green-dark .mat-tab-label, .app.thm-green-dark .mat-tab-link {
  color: white;
}
.app.thm-green-dark .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-green-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-green-dark .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-green-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-green-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-green-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-green-dark .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #388e3c;
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-green-dark .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-green-dark .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-green-dark .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-green-dark .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.thm-green-dark .mat-toolbar.mat-primary {
  background: #388e3c;
  color: white;
}
.app.thm-green-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-green-dark .mat-toolbar .mat-form-field-underline,
.app.thm-green-dark .mat-toolbar .mat-form-field-ripple,
.app.thm-green-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-green-dark .mat-toolbar .mat-form-field-label,
.app.thm-green-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-green-dark .mat-toolbar .mat-select-value,
.app.thm-green-dark .mat-toolbar .mat-select-arrow,
.app.thm-green-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-green-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-green-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-green-dark .mat-tree {
  background: #424242;
}
.app.thm-green-dark .mat-tree-node,
.app.thm-green-dark .mat-nested-tree-node {
  color: white;
}
.app.thm-green-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-green-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.thm-green-dark .bg-primary {
  background: #388e3c;
  color: white;
}
.app.thm-green-dark .bg-primary-l-400 {
  background-color: #66bb6a;
  color: white;
}
.app.thm-green-dark .bg-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-green-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .primary-color {
  color: #388e3c !important;
}
.app.thm-green-dark .accent-color {
  color: #ffd740 !important;
}
.app.thm-green-dark .warn-color {
  color: #ff5252 !important;
}
.app.thm-green-dark .bg-card {
  background: #424242;
}
.app.thm-green-dark .bg-e2 {
  background: #e2e2e2;
}
.app.thm-green-dark .bg-white {
  background: #fff;
}
.app.thm-green-dark .link-style {
  color: #ffd740;
  cursor: pointer;
}
.app.thm-green-dark .card-style, .app.thm-green-dark .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .ui-tree, .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .ui-table-caption,
.app.thm-green-dark .ui-table .ui-table-summary, .app.thm-green-dark .ui-paginator, .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-gray-light .ui-table .ui-table-caption, .app.thm-gray-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-gray-light .ui-table .ui-table-summary,
.app.thm-gray-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-green-dark .ui-dialog-content, .app.thm-green-dark .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-green-dark .ui-dialog-footer, .app.thm-green-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box, .app.thm-green-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-green-dark .ui-tabview-panels, .app.thm-green-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-green-dark .ui-table-caption,
.app.thm-green-dark .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-green-dark .ui-table-summary, .app.thm-green-dark .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-green-dark .ui-tree {
  background: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-green-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-green-dark .ui-multiselect .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-red-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-red-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-gray-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-gray-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-blue-dark .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-blue-dark .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect .app.thm-green-dark .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-green-dark .ui-multiselect-trigger, .app.thm-blue-light .ui-multiselect-panel .app.thm-green-dark .ui-multiselect-header, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item, .app.thm-blue-light .ui-dropdown .app.thm-green-dark .ui-dropdown-trigger {
  background: #424242;
  color: white;
}
.app.thm-green-dark .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-chkbox .app.thm-green-dark .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-green-dark li.ui-state-active, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-green-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-green-dark .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-green-dark .ui-treenode-label.ui-state-highlight {
  background: #388e3c;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-green-dark .ui-multiselect-item.ui-state-highlight {
  background: #66bb6a;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-green-dark .ui-table-tbody > tr:nth-child(even) {
  background: #303030;
  color: white;
}
.app.thm-green-dark .border-error-style, .app.thm-green-dark .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-green-dark .clv-bg-danger, .app.thm-green-dark .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-green-dark .clv-color-danger, .app.thm-green-dark .danger-color {
  color: #d32f2f;
}
.app.thm-green-dark .clv-bg-danger-imp, .app.thm-green-dark .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-danger-imp, .app.thm-green-dark .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-green-dark .clv-bg-success, .app.thm-green-dark .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-green-dark .clv-color-success, .app.thm-green-dark .success-color {
  color: #388e3c;
}
.app.thm-green-dark .clv-bg-success-imp, .app.thm-green-dark .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-success-imp, .app.thm-green-dark .success-color-imp {
  color: #388e3c !important;
}
.app.thm-green-dark .clv-bg-warning, .app.thm-green-dark .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .clv-color-warning, .app.thm-green-dark .warning-color {
  color: #fbc02d;
}
.app.thm-green-dark .clv-bg-warning-imp, .app.thm-green-dark .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-green-dark .clv-color-warning-imp, .app.thm-green-dark .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-green-dark .clv-bg-orange, .app.thm-green-dark .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-green-dark .clv-color-orange, .app.thm-green-dark .orange-color {
  color: #ffa000;
}
.app.thm-green-dark .clv-bg-orange-imp, .app.thm-green-dark .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-green-dark .clv-color-orange-imp, .app.thm-green-dark .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-green-dark .clv-bg-info, .app.thm-green-dark .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-green-dark .clv-color-info, .app.thm-green-dark .info-color {
  color: #0288d1;
}
.app.thm-green-dark .clv-bg-info-imp, .app.thm-green-dark .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-info-imp, .app.thm-green-dark .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-green-dark .clv-bg-blue, .app.thm-green-dark .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-green-dark .clv-color-blue, .app.thm-green-dark .blue-color {
  color: #1976d2;
}
.app.thm-green-dark .clv-bg-blue-imp, .app.thm-green-dark .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-blue-imp, .app.thm-green-dark .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-green-dark .clv-bg-indigo, .app.thm-green-dark .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-green-dark .clv-color-indigo, .app.thm-green-dark .indigo-color {
  color: #303f9f;
}
.app.thm-green-dark .clv-bg-indigo-imp, .app.thm-green-dark .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-indigo-imp, .app.thm-green-dark .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-green-dark .clv-bg-brown, .app.thm-green-dark .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-green-dark .clv-color-brown, .app.thm-green-dark .brown-color {
  color: #5d4037;
}
.app.thm-green-dark .clv-bg-brown-imp, .app.thm-green-dark .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-green-dark .clv-color-brown-imp, .app.thm-green-dark .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-green-dark .connexion-titre {
  color: #388e3c;
}
.app.thm-green-dark .header {
  background: #424242;
  color: white;
}
.app.thm-green-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .content-header h4 {
  color: #e8f5e9;
}
.app.thm-green-dark .favorites-container:after {
  color: #ffd740;
}
.app.thm-green-dark .horizontal-menu {
  background: #424242;
}
.app.thm-green-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.thm-green-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.thm-green-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.thm-green-dark .menu-item .mat-button .menu-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.thm-green-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .menu-item .mat-button.active-link .menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.thm-green-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-green-dark .breadcrumb a {
  color: #e8f5e9;
}
.app.thm-green-dark .top-toolbar, .app.thm-green-dark .landing-toolbar {
  background: white;
  color: #2e7d32;
}
.app.thm-green-dark .menu-toolbar {
  background: #1b5e20;
  color: #c8e6c9;
}
.app.thm-green-dark .ps__thumb-y {
  background-color: #388e3c;
}
.app.thm-green-dark .testimonial-item, .app.thm-green-dark .pricing-card {
  border-top: 4px solid #ffd740;
}
.app.thm-green-dark .login-container {
  background: #303030;
}
.app.thm-green-dark .ngx-charts text {
  fill: white;
}
.app.thm-green-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-green-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-green-dark .annular-pagination .ngx-pagination .current {
  background: #388e3c;
  color: white;
}
.app.thm-green-dark .annular-pagination .ngx-pagination a:hover, .app.thm-green-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(56, 142, 60, 0.2);
  color: white;
}
.app.thm-green-dark .cal-day-view .cal-hour-segment:hover,
.app.thm-green-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-green-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-green-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-green-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-green-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(56, 142, 60, 0.05);
}
.app.thm-green-dark .cal-week-view .cal-header.cal-today,
.app.thm-green-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.thm-green-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-green-dark .cal-event-action i {
  margin: 4px;
}
.app.thm-green-dark .ql-snow .ql-picker,
.app.thm-green-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.thm-green-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.thm-green-dark .ql-snow .ql-fill {
  fill: white;
}
.app.thm-green-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-green-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.thm-green-dark .ngx-charts text {
  fill: white;
}
.app.thm-green-dark .mat-table td {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-green-dark .ui-dialog .ui-dialog-titlebar {
  background-color: #388e3c;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-green-dark .ui-button {
  color: !important;
  background-color: #388e3c;
  border: 1px solid #388e3c;
}
.app.thm-green-dark .ui-button.ui-button-primary {
  background-color: #388e3c;
  border: 1px solid #388e3c;
}
.app.thm-green-dark .ui-button.ui-button-secondary {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ffd740;
}
.app.thm-green-dark .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-green-dark .ui-multiselect {
  display: block;
}
.app.thm-green-dark .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-green-dark .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-green-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .form-separator {
  background: #388e3c;
  text-align: center;
  color: white;
}
.app.thm-green-dark .form-separator::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-green-dark .titre-in-table {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.app.thm-green-dark .titre-in-table::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-pink-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-option {
  color: white;
}
.app.thm-pink-dark .mat-option:hover:not(.mat-option-disabled), .app.thm-pink-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-pink-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-pink-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.thm-pink-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c2185b;
}
.app.thm-pink-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #546e7a;
}
.app.thm-pink-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.thm-pink-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.thm-pink-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.thm-pink-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-pink-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #c2185b;
}
.app.thm-pink-dark .mat-pseudo-checkbox-checked,
.app.thm-pink-dark .mat-pseudo-checkbox-indeterminate,
.app.thm-pink-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-pink-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #546e7a;
}
.app.thm-pink-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-pink-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.thm-pink-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-pink-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.thm-pink-dark .mat-app-background, .app.thm-pink-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.thm-pink-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-pink-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.thm-pink-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.thm-pink-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.thm-pink-dark .mat-badge-content {
  color: white;
  background: #c2185b;
}
.cdk-high-contrast-active .app.thm-pink-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-pink-dark .mat-badge-accent .mat-badge-content {
  background: #546e7a;
  color: white;
}
.app.thm-pink-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.thm-pink-dark .mat-badge {
  position: relative;
}
.app.thm-pink-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-pink-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-pink-dark .ng-animate-disabled .mat-badge-content,
.app.thm-pink-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-pink-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-pink-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-pink-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-pink-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-pink-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-pink-dark .mat-button, .app.thm-pink-dark .mat-icon-button, .app.thm-pink-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-pink-dark .mat-button.mat-primary, .app.thm-pink-dark .mat-icon-button.mat-primary, .app.thm-pink-dark .mat-stroked-button.mat-primary {
  color: #c2185b;
}
.app.thm-pink-dark .mat-button.mat-accent, .app.thm-pink-dark .mat-icon-button.mat-accent, .app.thm-pink-dark .mat-stroked-button.mat-accent {
  color: #546e7a;
}
.app.thm-pink-dark .mat-button.mat-warn, .app.thm-pink-dark .mat-icon-button.mat-warn, .app.thm-pink-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.thm-pink-dark .mat-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-pink-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-pink-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-pink-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-pink-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-pink-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-pink-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-pink-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-pink-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-pink-dark .mat-button .mat-ripple-element, .app.thm-pink-dark .mat-icon-button .mat-ripple-element, .app.thm-pink-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-pink-dark .mat-button-focus-overlay {
  background: white;
}
.app.thm-pink-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-flat-button, .app.thm-pink-dark .mat-raised-button, .app.thm-pink-dark .mat-fab, .app.thm-pink-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.thm-pink-dark .mat-flat-button.mat-primary, .app.thm-pink-dark .mat-raised-button.mat-primary, .app.thm-pink-dark .mat-fab.mat-primary, .app.thm-pink-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-pink-dark .mat-flat-button.mat-accent, .app.thm-pink-dark .mat-raised-button.mat-accent, .app.thm-pink-dark .mat-fab.mat-accent, .app.thm-pink-dark .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-pink-dark .mat-flat-button.mat-warn, .app.thm-pink-dark .mat-raised-button.mat-warn, .app.thm-pink-dark .mat-fab.mat-warn, .app.thm-pink-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-pink-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-flat-button.mat-primary, .app.thm-pink-dark .mat-raised-button.mat-primary, .app.thm-pink-dark .mat-fab.mat-primary, .app.thm-pink-dark .mat-mini-fab.mat-primary {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-flat-button.mat-accent, .app.thm-pink-dark .mat-raised-button.mat-accent, .app.thm-pink-dark .mat-fab.mat-accent, .app.thm-pink-dark .mat-mini-fab.mat-accent {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-flat-button.mat-warn, .app.thm-pink-dark .mat-raised-button.mat-warn, .app.thm-pink-dark .mat-fab.mat-warn, .app.thm-pink-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-pink-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-pink-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-pink-dark .mat-fab.mat-primary .mat-ripple-element, .app.thm-pink-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-pink-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-pink-dark .mat-fab.mat-accent .mat-ripple-element, .app.thm-pink-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-pink-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-pink-dark .mat-fab.mat-warn .mat-ripple-element, .app.thm-pink-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-pink-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-fab:not([class*=mat-elevation-z]), .app.thm-pink-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-pink-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-pink-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-button-toggle-standalone,
.app.thm-pink-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-pink-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-pink-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.thm-pink-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.thm-pink-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.thm-pink-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.thm-pink-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.thm-pink-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.thm-pink-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-pink-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-card {
  background: #424242;
  color: white;
}
.app.thm-pink-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.thm-pink-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.thm-pink-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.thm-pink-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-pink-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-pink-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-pink-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-pink-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.thm-pink-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.thm-pink-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.thm-pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #c2185b;
}
.app.thm-pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #546e7a;
}
.app.thm-pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #c2185b;
  color: white;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #546e7a;
  color: white;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-table {
  background: #424242;
}
.app.thm-pink-dark .mat-table thead, .app.thm-pink-dark .mat-table tbody, .app.thm-pink-dark .mat-table tfoot,
.app.thm-pink-dark mat-header-row, .app.thm-pink-dark mat-row, .app.thm-pink-dark mat-footer-row,
.app.thm-pink-dark [mat-header-row], .app.thm-pink-dark [mat-row], .app.thm-pink-dark [mat-footer-row],
.app.thm-pink-dark .mat-table-sticky {
  background: inherit;
}
.app.thm-pink-dark mat-row, .app.thm-pink-dark mat-header-row, .app.thm-pink-dark mat-footer-row,
.app.thm-pink-dark th.mat-header-cell, .app.thm-pink-dark td.mat-cell, .app.thm-pink-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-cell, .app.thm-pink-dark .mat-footer-cell {
  color: white;
}
.app.thm-pink-dark .mat-calendar-arrow {
  border-top-color: white;
}
.app.thm-pink-dark .mat-datepicker-toggle,
.app.thm-pink-dark .mat-datepicker-content .mat-calendar-next-button,
.app.thm-pink-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.thm-pink-dark .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-calendar-body-cell-content,
.app.thm-pink-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.thm-pink-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-pink-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-pink-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.thm-pink-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.thm-pink-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-calendar-body-in-range::before {
  background: rgba(194, 24, 91, 0.2);
}
.app.thm-pink-dark .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-pink-dark .mat-calendar-body-comparison-bridge-start::before,
.app.thm-pink-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-calendar-body-comparison-bridge-end::before,
.app.thm-pink-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-pink-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-pink-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-pink-dark .mat-calendar-body-selected {
  background-color: #c2185b;
  color: white;
}
.app.thm-pink-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.thm-pink-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-pink-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(84, 110, 122, 0.2);
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-pink-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-pink-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #546e7a;
  color: white;
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(84, 110, 122, 0.4);
}
.app.thm-pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-pink-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-pink-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.thm-pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-pink-dark .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-datepicker-toggle-active {
  color: #c2185b;
}
.app.thm-pink-dark .mat-datepicker-toggle-active.mat-accent {
  color: #546e7a;
}
.app.thm-pink-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.thm-pink-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-pink-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.thm-pink-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-pink-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-pink-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-pink-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.thm-pink-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.thm-pink-dark .mat-expansion-panel-header-description,
.app.thm-pink-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-pink-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-pink-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #c2185b;
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #546e7a;
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.thm-pink-dark .mat-focused .mat-form-field-required-marker {
  color: #546e7a;
}
.app.thm-pink-dark .mat-form-field-ripple {
  background-color: white;
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #c2185b;
}
.app.thm-pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #546e7a;
}
.app.thm-pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-error {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-pink-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-pink-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.thm-pink-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-pink-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #c2185b;
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #546e7a;
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.thm-pink-dark .mat-icon.mat-primary {
  color: #c2185b;
}
.app.thm-pink-dark .mat-icon.mat-accent {
  color: #546e7a;
}
.app.thm-pink-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-input-element:disabled,
.app.thm-pink-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-input-element {
  caret-color: #c2185b;
}
.app.thm-pink-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-pink-dark .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-pink-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #546e7a;
}
.app.thm-pink-dark .mat-form-field.mat-warn .mat-input-element,
.app.thm-pink-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.thm-pink-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.thm-pink-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.thm-pink-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.thm-pink-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-list-item-disabled {
  background-color: black;
}
.app.thm-pink-dark .mat-list-option:hover, .app.thm-pink-dark .mat-list-option:focus,
.app.thm-pink-dark .mat-nav-list .mat-list-item:hover,
.app.thm-pink-dark .mat-nav-list .mat-list-item:focus,
.app.thm-pink-dark .mat-action-list .mat-list-item:hover,
.app.thm-pink-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-pink-dark .mat-list-single-selected-option, .app.thm-pink-dark .mat-list-single-selected-option:hover, .app.thm-pink-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-menu-panel {
  background: #424242;
}
.app.thm-pink-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.thm-pink-dark .mat-menu-item[disabled], .app.thm-pink-dark .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-menu-item .mat-icon-no-color,
.app.thm-pink-dark .mat-menu-item-submenu-trigger::after {
  color: white;
}
.app.thm-pink-dark .mat-menu-item:hover:not([disabled]),
.app.thm-pink-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-pink-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-pink-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-pink-dark .mat-paginator {
  background: #424242;
}
.app.thm-pink-dark .mat-paginator,
.app.thm-pink-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-paginator-decrement,
.app.thm-pink-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.thm-pink-dark .mat-paginator-first,
.app.thm-pink-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.thm-pink-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-pink-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-pink-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-pink-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-progress-bar-background {
  fill: #fce4ec;
}
.app.thm-pink-dark .mat-progress-bar-buffer {
  background-color: #fce4ec;
}
.app.thm-pink-dark .mat-progress-bar-fill::after {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #90a4ae;
}
.app.thm-pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #90a4ae;
}
.app.thm-pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-progress-spinner circle, .app.thm-pink-dark .mat-spinner circle {
  stroke: #c2185b;
}
.app.thm-pink-dark .mat-progress-spinner.mat-accent circle, .app.thm-pink-dark .mat-spinner.mat-accent circle {
  stroke: #546e7a;
}
.app.thm-pink-dark .mat-progress-spinner.mat-warn circle, .app.thm-pink-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.thm-pink-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c2185b;
}
.app.thm-pink-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-pink-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-pink-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-pink-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #546e7a;
}
.app.thm-pink-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-pink-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-pink-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.thm-pink-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-pink-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-pink-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-pink-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.thm-pink-dark .mat-select-value {
  color: white;
}
.app.thm-pink-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-select-panel {
  background: #424242;
}
.app.thm-pink-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #c2185b;
}
.app.thm-pink-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #546e7a;
}
.app.thm-pink-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.thm-pink-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.thm-pink-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.thm-pink-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.thm-pink-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-pink-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-pink-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.thm-pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(84, 110, 122, 0.54);
}
.app.thm-pink-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(194, 24, 91, 0.54);
}
.app.thm-pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.thm-pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.thm-pink-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.thm-pink-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-primary .mat-slider-track-fill,
.app.thm-pink-dark .mat-primary .mat-slider-thumb,
.app.thm-pink-dark .mat-primary .mat-slider-thumb-label {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-pink-dark .mat-primary .mat-slider-focus-ring {
  background-color: rgba(194, 24, 91, 0.2);
}
.app.thm-pink-dark .mat-accent .mat-slider-track-fill,
.app.thm-pink-dark .mat-accent .mat-slider-thumb,
.app.thm-pink-dark .mat-accent .mat-slider-thumb-label {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-pink-dark .mat-accent .mat-slider-focus-ring {
  background-color: rgba(84, 110, 122, 0.2);
}
.app.thm-pink-dark .mat-warn .mat-slider-track-fill,
.app.thm-pink-dark .mat-warn .mat-slider-thumb,
.app.thm-pink-dark .mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-pink-dark .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.thm-pink-dark .mat-slider:hover .mat-slider-track-background,
.app.thm-pink-dark .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-disabled .mat-slider-track-background,
.app.thm-pink-dark .mat-slider-disabled .mat-slider-track-fill,
.app.thm-pink-dark .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-pink-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.thm-pink-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-pink-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.thm-pink-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-pink-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-pink-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-pink-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-pink-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-pink-dark .mat-step-header.cdk-keyboard-focused, .app.thm-pink-dark .mat-step-header.cdk-program-focused, .app.thm-pink-dark .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-pink-dark .mat-step-header:hover {
    background: none;
  }
}
.app.thm-pink-dark .mat-step-header .mat-step-label,
.app.thm-pink-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.thm-pink-dark .mat-step-header .mat-step-icon-selected,
.app.thm-pink-dark .mat-step-header .mat-step-icon-state-done,
.app.thm-pink-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #c2185b;
  color: white;
}
.app.thm-pink-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.thm-pink-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.thm-pink-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.thm-pink-dark .mat-stepper-horizontal, .app.thm-pink-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.thm-pink-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-horizontal-stepper-header::before,
.app.thm-pink-dark .mat-horizontal-stepper-header::after,
.app.thm-pink-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.thm-pink-dark .mat-tab-nav-bar,
.app.thm-pink-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-pink-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.thm-pink-dark .mat-tab-label, .app.thm-pink-dark .mat-tab-link {
  color: white;
}
.app.thm-pink-dark .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-pink-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-pink-dark .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-pink-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-pink-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(144, 164, 174, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-pink-dark .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #c2185b;
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(144, 164, 174, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #546e7a;
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-pink-dark .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-pink-dark .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.thm-pink-dark .mat-toolbar.mat-primary {
  background: #c2185b;
  color: white;
}
.app.thm-pink-dark .mat-toolbar.mat-accent {
  background: #546e7a;
  color: white;
}
.app.thm-pink-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.thm-pink-dark .mat-toolbar .mat-form-field-underline,
.app.thm-pink-dark .mat-toolbar .mat-form-field-ripple,
.app.thm-pink-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-pink-dark .mat-toolbar .mat-form-field-label,
.app.thm-pink-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-pink-dark .mat-toolbar .mat-select-value,
.app.thm-pink-dark .mat-toolbar .mat-select-arrow,
.app.thm-pink-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-pink-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-pink-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-pink-dark .mat-tree {
  background: #424242;
}
.app.thm-pink-dark .mat-tree-node,
.app.thm-pink-dark .mat-nested-tree-node {
  color: white;
}
.app.thm-pink-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-pink-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.thm-pink-dark .bg-primary {
  background: #c2185b;
  color: white;
}
.app.thm-pink-dark .bg-primary-l-400 {
  background-color: #ec407a;
  color: white;
}
.app.thm-pink-dark .bg-accent {
  background: #546e7a;
  color: white;
}
.app.thm-pink-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.thm-pink-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .primary-color {
  color: #c2185b !important;
}
.app.thm-pink-dark .accent-color {
  color: #546e7a !important;
}
.app.thm-pink-dark .warn-color {
  color: #ff5252 !important;
}
.app.thm-pink-dark .bg-card {
  background: #424242;
}
.app.thm-pink-dark .bg-e2 {
  background: #e2e2e2;
}
.app.thm-pink-dark .bg-white {
  background: #fff;
}
.app.thm-pink-dark .link-style {
  color: #546e7a;
  cursor: pointer;
}
.app.thm-pink-dark .card-style, .app.thm-pink-dark .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-gray-dark .ui-table .ui-table-caption, .app.thm-gray-dark .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-gray-dark .ui-table .ui-table-summary,
.app.thm-gray-dark .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .ui-table-caption,
.app.thm-pink-dark .ui-table .ui-table-summary, .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-gray-light .ui-table .ui-table-caption, .app.thm-gray-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-gray-light .ui-table .ui-table-summary,
.app.thm-gray-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-pink-dark .ui-dialog-content, .app.thm-pink-dark .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-pink-dark .ui-dialog-footer, .app.thm-pink-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box, .app.thm-pink-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-pink-dark .ui-tabview-panels, .app.thm-pink-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-pink-dark .ui-table-caption,
.app.thm-pink-dark .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-pink-dark .ui-table-summary, .app.thm-pink-dark .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-pink-dark .ui-tree {
  background: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-pink-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect .ui-multiselect-label,
.app.thm-pink-dark .ui-multiselect .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-yellow-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-yellow-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-yellow-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-green-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-green-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-green-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-green-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-indigo-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-indigo-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-indigo-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-indigo-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-teal-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-teal-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-teal-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-teal-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-red-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-red-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-red-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-red-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-gray-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-gray-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-gray-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-gray-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-blue-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-blue-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-blue-dark .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-blue-dark .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-blue-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-blue-light .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-blue-light .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-blue-light .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger, .app.thm-green-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-label,
.app.thm-green-dark .ui-multiselect .app.thm-pink-dark .ui-multiselect-trigger, .app.thm-green-dark .ui-multiselect-panel .app.thm-pink-dark .ui-multiselect-header, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item, .app.thm-green-dark .ui-dropdown .app.thm-pink-dark .ui-dropdown-trigger {
  background: #424242;
  color: white;
}
.app.thm-pink-dark .active-style, .app.thm-pink-dark .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-gray-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-gray-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-pink-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-yellow-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-green-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-indigo-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-teal-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-red-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-gray-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-blue-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-blue-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-blue-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .app.thm-green-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-pink-dark .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-pink-dark li.ui-state-active, .app.thm-pink-dark .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-pink-dark .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-pink-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-pink-dark .app.thm-green-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-pink-dark .ui-paginator-page.ui-state-active, .app.thm-pink-dark .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-pink-dark .ui-treenode-label.ui-state-highlight {
  background: #c2185b;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-pink-dark .ui-multiselect-item.ui-state-highlight {
  background: #ec407a;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .active-alternance-table, .app.thm-gray-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-pink-dark .ui-table-tbody > tr:nth-child(even) {
  background: #303030;
  color: white;
}
.app.thm-pink-dark .border-error-style, .app.thm-pink-dark .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-pink-dark .clv-bg-danger, .app.thm-pink-dark .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-pink-dark .clv-color-danger, .app.thm-pink-dark .danger-color {
  color: #d32f2f;
}
.app.thm-pink-dark .clv-bg-danger-imp, .app.thm-pink-dark .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-danger-imp, .app.thm-pink-dark .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-pink-dark .clv-bg-success, .app.thm-pink-dark .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-pink-dark .clv-color-success, .app.thm-pink-dark .success-color {
  color: #388e3c;
}
.app.thm-pink-dark .clv-bg-success-imp, .app.thm-pink-dark .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-success-imp, .app.thm-pink-dark .success-color-imp {
  color: #388e3c !important;
}
.app.thm-pink-dark .clv-bg-warning, .app.thm-pink-dark .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-pink-dark .clv-color-warning, .app.thm-pink-dark .warning-color {
  color: #fbc02d;
}
.app.thm-pink-dark .clv-bg-warning-imp, .app.thm-pink-dark .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-pink-dark .clv-color-warning-imp, .app.thm-pink-dark .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-pink-dark .clv-bg-orange, .app.thm-pink-dark .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-pink-dark .clv-color-orange, .app.thm-pink-dark .orange-color {
  color: #ffa000;
}
.app.thm-pink-dark .clv-bg-orange-imp, .app.thm-pink-dark .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-pink-dark .clv-color-orange-imp, .app.thm-pink-dark .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-pink-dark .clv-bg-info, .app.thm-pink-dark .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-pink-dark .clv-color-info, .app.thm-pink-dark .info-color {
  color: #0288d1;
}
.app.thm-pink-dark .clv-bg-info-imp, .app.thm-pink-dark .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-info-imp, .app.thm-pink-dark .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-pink-dark .clv-bg-blue, .app.thm-pink-dark .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-pink-dark .clv-color-blue, .app.thm-pink-dark .blue-color {
  color: #1976d2;
}
.app.thm-pink-dark .clv-bg-blue-imp, .app.thm-pink-dark .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-blue-imp, .app.thm-pink-dark .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-pink-dark .clv-bg-indigo, .app.thm-pink-dark .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-pink-dark .clv-color-indigo, .app.thm-pink-dark .indigo-color {
  color: #303f9f;
}
.app.thm-pink-dark .clv-bg-indigo-imp, .app.thm-pink-dark .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-indigo-imp, .app.thm-pink-dark .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-pink-dark .clv-bg-brown, .app.thm-pink-dark .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-pink-dark .clv-color-brown, .app.thm-pink-dark .brown-color {
  color: #5d4037;
}
.app.thm-pink-dark .clv-bg-brown-imp, .app.thm-pink-dark .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-pink-dark .clv-color-brown-imp, .app.thm-pink-dark .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-pink-dark .connexion-titre {
  color: #c2185b;
}
.app.thm-pink-dark .header {
  background: #424242;
  color: white;
}
.app.thm-pink-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .content-header h4 {
  color: #fce4ec;
}
.app.thm-pink-dark .favorites-container:after {
  color: #546e7a;
}
.app.thm-pink-dark .horizontal-menu {
  background: #424242;
}
.app.thm-pink-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.thm-pink-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.thm-pink-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #c2185b;
  color: #fff;
}
.app.thm-pink-dark .menu-item .mat-button .menu-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.thm-pink-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .menu-item .mat-button.active-link .menu-icon {
  background: #c2185b;
  color: #fff;
}
.app.thm-pink-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-pink-dark .breadcrumb a {
  color: #fce4ec;
}
.app.thm-pink-dark .top-toolbar, .app.thm-pink-dark .landing-toolbar {
  background: white;
  color: #ad1457;
}
.app.thm-pink-dark .menu-toolbar {
  background: #880e4f;
  color: #f8bbd0;
}
.app.thm-pink-dark .ps__thumb-y {
  background-color: #c2185b;
}
.app.thm-pink-dark .testimonial-item, .app.thm-pink-dark .pricing-card {
  border-top: 4px solid #546e7a;
}
.app.thm-pink-dark .login-container {
  background: #303030;
}
.app.thm-pink-dark .ngx-charts text {
  fill: white;
}
.app.thm-pink-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-pink-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-pink-dark .annular-pagination .ngx-pagination .current {
  background: #c2185b;
  color: white;
}
.app.thm-pink-dark .annular-pagination .ngx-pagination a:hover, .app.thm-pink-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(194, 24, 91, 0.2);
  color: white;
}
.app.thm-pink-dark .cal-day-view .cal-hour-segment:hover,
.app.thm-pink-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-pink-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-pink-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-pink-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-pink-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.thm-pink-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(194, 24, 91, 0.05);
}
.app.thm-pink-dark .cal-week-view .cal-header.cal-today,
.app.thm-pink-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.thm-pink-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-pink-dark .cal-event-action i {
  margin: 4px;
}
.app.thm-pink-dark .ql-snow .ql-picker,
.app.thm-pink-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.thm-pink-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.thm-pink-dark .ql-snow .ql-fill {
  fill: white;
}
.app.thm-pink-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-pink-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.thm-pink-dark .ngx-charts text {
  fill: white;
}
.app.thm-pink-dark .mat-table td {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-pink-dark .ui-dialog .ui-dialog-titlebar {
  background-color: #c2185b;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-pink-dark .ui-button {
  color: !important;
  background-color: #c2185b;
  border: 1px solid #c2185b;
}
.app.thm-pink-dark .ui-button.ui-button-primary {
  background-color: #c2185b;
  border: 1px solid #c2185b;
}
.app.thm-pink-dark .ui-button.ui-button-secondary {
  background: #546e7a;
  color: white;
  border: 1px solid #546e7a;
}
.app.thm-pink-dark .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-pink-dark .ui-multiselect {
  display: block;
}
.app.thm-pink-dark .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-pink-dark .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-pink-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .form-separator {
  background: #c2185b;
  text-align: center;
  color: white;
}
.app.thm-pink-dark .form-separator::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-pink-dark .titre-in-table {
  background: #546e7a;
  color: white;
  text-align: center;
}
.app.thm-pink-dark .titre-in-table::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
  /* mat-tables */
  /* drop zone */
  /* Prime ng */
}
.app.thm-gray-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-option {
  color: white;
}
.app.thm-gray-dark .mat-option:hover:not(.mat-option-disabled), .app.thm-gray-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-gray-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-gray-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.thm-gray-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #455a64;
}
.app.thm-gray-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.thm-gray-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.thm-gray-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.thm-gray-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.thm-gray-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.thm-gray-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #455a64;
}
.app.thm-gray-dark .mat-pseudo-checkbox-checked,
.app.thm-gray-dark .mat-pseudo-checkbox-indeterminate,
.app.thm-gray-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.thm-gray-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.thm-gray-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.thm-gray-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.thm-gray-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.thm-gray-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.thm-gray-dark .mat-app-background, .app.thm-gray-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.thm-gray-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.thm-gray-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.thm-gray-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.thm-gray-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.thm-gray-dark .mat-badge-content {
  color: white;
  background: #455a64;
}
.cdk-high-contrast-active .app.thm-gray-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.thm-gray-dark .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.thm-gray-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.thm-gray-dark .mat-badge {
  position: relative;
}
.app.thm-gray-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.thm-gray-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.thm-gray-dark .ng-animate-disabled .mat-badge-content,
.app.thm-gray-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.thm-gray-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.thm-gray-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.thm-gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.thm-gray-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.thm-gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.thm-gray-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.thm-gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.thm-gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.thm-gray-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-gray-dark .mat-button, .app.thm-gray-dark .mat-icon-button, .app.thm-gray-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.thm-gray-dark .mat-button.mat-primary, .app.thm-gray-dark .mat-icon-button.mat-primary, .app.thm-gray-dark .mat-stroked-button.mat-primary {
  color: #455a64;
}
.app.thm-gray-dark .mat-button.mat-accent, .app.thm-gray-dark .mat-icon-button.mat-accent, .app.thm-gray-dark .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.thm-gray-dark .mat-button.mat-warn, .app.thm-gray-dark .mat-icon-button.mat-warn, .app.thm-gray-dark .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.thm-gray-dark .mat-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.thm-gray-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.thm-gray-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.thm-gray-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.thm-gray-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.thm-gray-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.thm-gray-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-gray-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.thm-gray-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.thm-gray-dark .mat-button .mat-ripple-element, .app.thm-gray-dark .mat-icon-button .mat-ripple-element, .app.thm-gray-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.thm-gray-dark .mat-button-focus-overlay {
  background: white;
}
.app.thm-gray-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-flat-button, .app.thm-gray-dark .mat-raised-button, .app.thm-gray-dark .mat-fab, .app.thm-gray-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.thm-gray-dark .mat-flat-button.mat-primary, .app.thm-gray-dark .mat-raised-button.mat-primary, .app.thm-gray-dark .mat-fab.mat-primary, .app.thm-gray-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.thm-gray-dark .mat-flat-button.mat-accent, .app.thm-gray-dark .mat-raised-button.mat-accent, .app.thm-gray-dark .mat-fab.mat-accent, .app.thm-gray-dark .mat-mini-fab.mat-accent {
  color: white;
}
.app.thm-gray-dark .mat-flat-button.mat-warn, .app.thm-gray-dark .mat-raised-button.mat-warn, .app.thm-gray-dark .mat-fab.mat-warn, .app.thm-gray-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.thm-gray-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-flat-button.mat-primary, .app.thm-gray-dark .mat-raised-button.mat-primary, .app.thm-gray-dark .mat-fab.mat-primary, .app.thm-gray-dark .mat-mini-fab.mat-primary {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-flat-button.mat-accent, .app.thm-gray-dark .mat-raised-button.mat-accent, .app.thm-gray-dark .mat-fab.mat-accent, .app.thm-gray-dark .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-flat-button.mat-warn, .app.thm-gray-dark .mat-raised-button.mat-warn, .app.thm-gray-dark .mat-fab.mat-warn, .app.thm-gray-dark .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.thm-gray-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.thm-gray-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.thm-gray-dark .mat-fab.mat-primary .mat-ripple-element, .app.thm-gray-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.thm-gray-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.thm-gray-dark .mat-fab.mat-accent .mat-ripple-element, .app.thm-gray-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.thm-gray-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.thm-gray-dark .mat-fab.mat-warn .mat-ripple-element, .app.thm-gray-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.thm-gray-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-fab:not([class*=mat-elevation-z]), .app.thm-gray-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.thm-gray-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.thm-gray-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-button-toggle-standalone,
.app.thm-gray-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-gray-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app.thm-gray-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.thm-gray-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.thm-gray-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.thm-gray-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.thm-gray-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.thm-gray-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.thm-gray-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.thm-gray-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-card {
  background: #424242;
  color: white;
}
.app.thm-gray-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.thm-gray-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.thm-gray-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.thm-gray-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.thm-gray-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.thm-gray-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.thm-gray-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.thm-gray-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.thm-gray-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.thm-gray-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.thm-gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.thm-gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #455a64;
}
.app.thm-gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.thm-gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.thm-gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.thm-gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #455a64;
  color: white;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.thm-gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-table {
  background: #424242;
}
.app.thm-gray-dark .mat-table thead, .app.thm-gray-dark .mat-table tbody, .app.thm-gray-dark .mat-table tfoot,
.app.thm-gray-dark mat-header-row, .app.thm-gray-dark mat-row, .app.thm-gray-dark mat-footer-row,
.app.thm-gray-dark [mat-header-row], .app.thm-gray-dark [mat-row], .app.thm-gray-dark [mat-footer-row],
.app.thm-gray-dark .mat-table-sticky {
  background: inherit;
}
.app.thm-gray-dark mat-row, .app.thm-gray-dark mat-header-row, .app.thm-gray-dark mat-footer-row,
.app.thm-gray-dark th.mat-header-cell, .app.thm-gray-dark td.mat-cell, .app.thm-gray-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-cell, .app.thm-gray-dark .mat-footer-cell {
  color: white;
}
.app.thm-gray-dark .mat-calendar-arrow {
  border-top-color: white;
}
.app.thm-gray-dark .mat-datepicker-toggle,
.app.thm-gray-dark .mat-datepicker-content .mat-calendar-next-button,
.app.thm-gray-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.thm-gray-dark .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-calendar-body-cell-content,
.app.thm-gray-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.thm-gray-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-gray-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.thm-gray-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.thm-gray-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.thm-gray-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-calendar-body-in-range::before {
  background: rgba(69, 90, 100, 0.2);
}
.app.thm-gray-dark .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-dark .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-dark .mat-calendar-body-selected {
  background-color: #455a64;
  color: white;
}
.app.thm-gray-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.thm-gray-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.thm-gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.thm-gray-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.thm-gray-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.thm-gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.thm-gray-dark .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-datepicker-toggle-active {
  color: #455a64;
}
.app.thm-gray-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.thm-gray-dark .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.thm-gray-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.thm-gray-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.thm-gray-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.thm-gray-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.thm-gray-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-gray-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.thm-gray-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.thm-gray-dark .mat-expansion-panel-header-description,
.app.thm-gray-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.thm-gray-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.thm-gray-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #455a64;
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.thm-gray-dark .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.thm-gray-dark .mat-form-field-ripple {
  background-color: white;
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #455a64;
}
.app.thm-gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.thm-gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.thm-gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.thm-gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-error {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-gray-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.thm-gray-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.thm-gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.thm-gray-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.thm-gray-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #455a64;
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.thm-gray-dark .mat-icon.mat-primary {
  color: #455a64;
}
.app.thm-gray-dark .mat-icon.mat-accent {
  color: #ff4081;
}
.app.thm-gray-dark .mat-icon.mat-warn {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-input-element:disabled,
.app.thm-gray-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-input-element {
  caret-color: #455a64;
}
.app.thm-gray-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-dark .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.thm-gray-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.thm-gray-dark .mat-form-field.mat-warn .mat-input-element,
.app.thm-gray-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.thm-gray-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.thm-gray-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.thm-gray-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.thm-gray-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-list-item-disabled {
  background-color: black;
}
.app.thm-gray-dark .mat-list-option:hover, .app.thm-gray-dark .mat-list-option:focus,
.app.thm-gray-dark .mat-nav-list .mat-list-item:hover,
.app.thm-gray-dark .mat-nav-list .mat-list-item:focus,
.app.thm-gray-dark .mat-action-list .mat-list-item:hover,
.app.thm-gray-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-gray-dark .mat-list-single-selected-option, .app.thm-gray-dark .mat-list-single-selected-option:hover, .app.thm-gray-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-menu-panel {
  background: #424242;
}
.app.thm-gray-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.thm-gray-dark .mat-menu-item[disabled], .app.thm-gray-dark .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-menu-item .mat-icon-no-color,
.app.thm-gray-dark .mat-menu-item-submenu-trigger::after {
  color: white;
}
.app.thm-gray-dark .mat-menu-item:hover:not([disabled]),
.app.thm-gray-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.thm-gray-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.thm-gray-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.thm-gray-dark .mat-paginator {
  background: #424242;
}
.app.thm-gray-dark .mat-paginator,
.app.thm-gray-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-paginator-decrement,
.app.thm-gray-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.thm-gray-dark .mat-paginator-first,
.app.thm-gray-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.thm-gray-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.thm-gray-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.thm-gray-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.thm-gray-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-progress-bar-background {
  fill: #eceff1;
}
.app.thm-gray-dark .mat-progress-bar-buffer {
  background-color: #eceff1;
}
.app.thm-gray-dark .mat-progress-bar-fill::after {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab;
}
.app.thm-gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab;
}
.app.thm-gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.app.thm-gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.app.thm-gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-progress-spinner circle, .app.thm-gray-dark .mat-spinner circle {
  stroke: #455a64;
}
.app.thm-gray-dark .mat-progress-spinner.mat-accent circle, .app.thm-gray-dark .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.thm-gray-dark .mat-progress-spinner.mat-warn circle, .app.thm-gray-dark .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.thm-gray-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #455a64;
}
.app.thm-gray-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.thm-gray-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.thm-gray-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.thm-gray-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.thm-gray-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.thm-gray-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.thm-gray-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.thm-gray-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.thm-gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.thm-gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.thm-gray-dark .mat-select-value {
  color: white;
}
.app.thm-gray-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-select-panel {
  background: #424242;
}
.app.thm-gray-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #455a64;
}
.app.thm-gray-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.thm-gray-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.thm-gray-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.thm-gray-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.thm-gray-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.thm-gray-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-gray-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.thm-gray-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.thm-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.thm-gray-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(69, 90, 100, 0.54);
}
.app.thm-gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.thm-gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.thm-gray-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.thm-gray-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-primary .mat-slider-track-fill,
.app.thm-gray-dark .mat-primary .mat-slider-thumb,
.app.thm-gray-dark .mat-primary .mat-slider-thumb-label {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-dark .mat-primary .mat-slider-focus-ring {
  background-color: rgba(69, 90, 100, 0.2);
}
.app.thm-gray-dark .mat-accent .mat-slider-track-fill,
.app.thm-gray-dark .mat-accent .mat-slider-thumb,
.app.thm-gray-dark .mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-dark .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.thm-gray-dark .mat-warn .mat-slider-track-fill,
.app.thm-gray-dark .mat-warn .mat-slider-thumb,
.app.thm-gray-dark .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.thm-gray-dark .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.thm-gray-dark .mat-slider:hover .mat-slider-track-background,
.app.thm-gray-dark .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-disabled .mat-slider-track-background,
.app.thm-gray-dark .mat-slider-disabled .mat-slider-track-fill,
.app.thm-gray-dark .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.thm-gray-dark .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.thm-gray-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.thm-gray-dark .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.thm-gray-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.thm-gray-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.thm-gray-dark .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.thm-gray-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-gray-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.thm-gray-dark .mat-step-header.cdk-keyboard-focused, .app.thm-gray-dark .mat-step-header.cdk-program-focused, .app.thm-gray-dark .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.thm-gray-dark .mat-step-header:hover {
    background: none;
  }
}
.app.thm-gray-dark .mat-step-header .mat-step-label,
.app.thm-gray-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.thm-gray-dark .mat-step-header .mat-step-icon-selected,
.app.thm-gray-dark .mat-step-header .mat-step-icon-state-done,
.app.thm-gray-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #455a64;
  color: white;
}
.app.thm-gray-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.thm-gray-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.thm-gray-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.thm-gray-dark .mat-stepper-horizontal, .app.thm-gray-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.thm-gray-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-horizontal-stepper-header::before,
.app.thm-gray-dark .mat-horizontal-stepper-header::after,
.app.thm-gray-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.thm-gray-dark .mat-tab-nav-bar,
.app.thm-gray-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.thm-gray-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.thm-gray-dark .mat-tab-label, .app.thm-gray-dark .mat-tab-link {
  color: white;
}
.app.thm-gray-dark .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.thm-gray-dark .mat-tab-group[class*=mat-background-] .mat-tab-header,
.app.thm-gray-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.thm-gray-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .app.thm-gray-dark .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-header, .app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-links, .app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #455a64;
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-label, .app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-link, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-primary .mat-ripple-element, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-header, .app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-links, .app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-label, .app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-link, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-accent .mat-ripple-element, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-header, .app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-links, .app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-label, .app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-link, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.app.thm-gray-dark .mat-tab-group.mat-background-warn .mat-ripple-element, .app.thm-gray-dark .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.thm-gray-dark .mat-toolbar.mat-primary {
  background: #455a64;
  color: white;
}
.app.thm-gray-dark .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.thm-gray-dark .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.thm-gray-dark .mat-toolbar .mat-form-field-underline,
.app.thm-gray-dark .mat-toolbar .mat-form-field-ripple,
.app.thm-gray-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.thm-gray-dark .mat-toolbar .mat-form-field-label,
.app.thm-gray-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.thm-gray-dark .mat-toolbar .mat-select-value,
.app.thm-gray-dark .mat-toolbar .mat-select-arrow,
.app.thm-gray-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.thm-gray-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.thm-gray-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.thm-gray-dark .mat-tree {
  background: #424242;
}
.app.thm-gray-dark .mat-tree-node,
.app.thm-gray-dark .mat-nested-tree-node {
  color: white;
}
.app.thm-gray-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.thm-gray-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.thm-gray-dark .bg-primary {
  background: #455a64;
  color: white;
}
.app.thm-gray-dark .bg-primary-l-400 {
  background-color: #78909c;
  color: white;
}
.app.thm-gray-dark .bg-accent {
  background: #ff4081;
  color: white;
}
.app.thm-gray-dark .bg-warn {
  background: #f44336;
  color: white;
}
.app.thm-gray-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .primary-color {
  color: #455a64 !important;
}
.app.thm-gray-dark .accent-color {
  color: #ff4081 !important;
}
.app.thm-gray-dark .warn-color {
  color: #f44336 !important;
}
.app.thm-gray-dark .bg-card {
  background: #424242;
}
.app.thm-gray-dark .bg-e2 {
  background: #e2e2e2;
}
.app.thm-gray-dark .bg-white {
  background: #fff;
}
.app.thm-gray-dark .link-style {
  color: #ff4081;
  cursor: pointer;
}
.app.thm-gray-dark .card-style, .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .ui-table .ui-table-caption,
.app.thm-gray-dark .ui-table .ui-table-summary, .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .ui-tabview .ui-tabview-panels, .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .ui-chkbox .ui-chkbox-box, .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .ui-dialog .ui-dialog-footer, .app.thm-gray-dark .ui-dialog .ui-dialog-content, .app.thm-gray-dark .app.thm-yellow-light .ui-dialog .ui-dialog-content, .app.thm-yellow-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-yellow-light .ui-dialog .ui-dialog-footer, .app.thm-yellow-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect, .app.thm-yellow-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect-panel, .app.thm-yellow-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box, .app.thm-yellow-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-yellow-light .ui-inputtext, .app.thm-yellow-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-yellow-light .ui-tabview .ui-tabview-panels, .app.thm-yellow-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-yellow-light .ui-paginator, .app.thm-yellow-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-yellow-light .ui-table .ui-table-caption, .app.thm-yellow-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-yellow-light .ui-table .ui-table-summary,
.app.thm-yellow-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-yellow-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-yellow-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-yellow-light .ui-tree, .app.thm-yellow-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-green-light .ui-dialog .ui-dialog-content, .app.thm-green-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-green-light .ui-dialog .ui-dialog-footer, .app.thm-green-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-green-light .ui-multiselect, .app.thm-green-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-green-light .ui-multiselect-panel, .app.thm-green-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box, .app.thm-green-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-green-light .ui-inputtext, .app.thm-green-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-green-light .ui-tabview .ui-tabview-panels, .app.thm-green-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-green-light .ui-paginator, .app.thm-green-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-green-light .ui-table .ui-table-caption, .app.thm-green-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-green-light .ui-table .ui-table-summary,
.app.thm-green-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-green-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-green-light .ui-tree, .app.thm-green-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-indigo-light .ui-dialog .ui-dialog-content, .app.thm-indigo-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-indigo-light .ui-dialog .ui-dialog-footer, .app.thm-indigo-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect, .app.thm-indigo-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect-panel, .app.thm-indigo-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box, .app.thm-indigo-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-indigo-light .ui-inputtext, .app.thm-indigo-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-indigo-light .ui-tabview .ui-tabview-panels, .app.thm-indigo-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-indigo-light .ui-paginator, .app.thm-indigo-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-indigo-light .ui-table .ui-table-caption, .app.thm-indigo-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-indigo-light .ui-table .ui-table-summary,
.app.thm-indigo-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-indigo-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-indigo-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-indigo-light .ui-tree, .app.thm-indigo-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-teal-light .ui-dialog .ui-dialog-content, .app.thm-teal-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-teal-light .ui-dialog .ui-dialog-footer, .app.thm-teal-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect, .app.thm-teal-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect-panel, .app.thm-teal-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box, .app.thm-teal-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-teal-light .ui-inputtext, .app.thm-teal-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-teal-light .ui-tabview .ui-tabview-panels, .app.thm-teal-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-teal-light .ui-paginator, .app.thm-teal-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-teal-light .ui-table .ui-table-caption, .app.thm-teal-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-teal-light .ui-table .ui-table-summary,
.app.thm-teal-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-teal-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-teal-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-teal-light .ui-tree, .app.thm-teal-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-red-light .ui-dialog .ui-dialog-content, .app.thm-red-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-red-light .ui-dialog .ui-dialog-footer, .app.thm-red-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-red-light .ui-multiselect, .app.thm-red-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-red-light .ui-multiselect-panel, .app.thm-red-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box, .app.thm-red-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-red-light .ui-inputtext, .app.thm-red-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-red-light .ui-tabview .ui-tabview-panels, .app.thm-red-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-red-light .ui-paginator, .app.thm-red-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-red-light .ui-table .ui-table-caption, .app.thm-red-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-red-light .ui-table .ui-table-summary,
.app.thm-red-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-red-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-red-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-red-light .ui-tree, .app.thm-red-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-gray-light .ui-dialog .ui-dialog-content, .app.thm-gray-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-gray-light .ui-dialog .ui-dialog-footer, .app.thm-gray-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect, .app.thm-gray-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect-panel, .app.thm-gray-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box, .app.thm-gray-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-gray-light .ui-inputtext, .app.thm-gray-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-gray-light .ui-tabview .ui-tabview-panels, .app.thm-gray-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-gray-light .ui-paginator, .app.thm-gray-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-gray-light .ui-table .ui-table-caption, .app.thm-gray-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-gray-light .ui-table .ui-table-summary,
.app.thm-gray-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-gray-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-gray-light .ui-tree, .app.thm-gray-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-blue-dark .ui-dialog .ui-dialog-content, .app.thm-blue-dark .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-blue-dark .ui-dialog .ui-dialog-footer, .app.thm-blue-dark .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect, .app.thm-blue-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect-panel, .app.thm-blue-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-blue-dark .ui-chkbox .ui-chkbox-box, .app.thm-blue-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-blue-dark .ui-inputtext, .app.thm-blue-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-blue-dark .ui-tabview .ui-tabview-panels, .app.thm-blue-dark .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-blue-dark .ui-paginator, .app.thm-blue-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-blue-dark .ui-table .ui-table-caption, .app.thm-blue-dark .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-blue-dark .ui-table .ui-table-summary,
.app.thm-blue-dark .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-blue-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-blue-dark .ui-tree, .app.thm-blue-dark .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-blue-light .ui-dialog .ui-dialog-content, .app.thm-blue-light .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-blue-light .ui-dialog .ui-dialog-footer, .app.thm-blue-light .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect, .app.thm-blue-light .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect-panel, .app.thm-blue-light .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box, .app.thm-blue-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-blue-light .ui-inputtext, .app.thm-blue-light .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-blue-light .ui-tabview .ui-tabview-panels, .app.thm-blue-light .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-blue-light .ui-paginator, .app.thm-blue-light .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-blue-light .ui-table .ui-table-caption, .app.thm-blue-light .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-blue-light .ui-table .ui-table-summary,
.app.thm-blue-light .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-blue-light .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-blue-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-blue-light .ui-tree, .app.thm-blue-light .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-green-dark .ui-dialog .ui-dialog-content, .app.thm-green-dark .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-green-dark .ui-dialog .ui-dialog-footer, .app.thm-green-dark .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect, .app.thm-green-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect-panel, .app.thm-green-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-green-dark .ui-chkbox .ui-chkbox-box, .app.thm-green-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-green-dark .ui-inputtext, .app.thm-green-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-green-dark .ui-tabview .ui-tabview-panels, .app.thm-green-dark .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-green-dark .ui-paginator, .app.thm-green-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-green-dark .ui-table .ui-table-caption, .app.thm-green-dark .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-green-dark .ui-table .ui-table-summary,
.app.thm-green-dark .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-green-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-green-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-green-dark .ui-tree, .app.thm-green-dark .app.thm-gray-dark .ui-tree, .app.thm-gray-dark .app.thm-pink-dark .ui-dialog .ui-dialog-content, .app.thm-pink-dark .ui-dialog .app.thm-gray-dark .ui-dialog-content, .app.thm-gray-dark .app.thm-pink-dark .ui-dialog .ui-dialog-footer, .app.thm-pink-dark .ui-dialog .app.thm-gray-dark .ui-dialog-footer, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect, .app.thm-pink-dark .app.thm-gray-dark .ui-multiselect, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect-panel, .app.thm-pink-dark .app.thm-gray-dark .ui-multiselect-panel, .app.thm-gray-dark .app.thm-pink-dark .ui-chkbox .ui-chkbox-box, .app.thm-pink-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box, .app.thm-gray-dark .app.thm-pink-dark .ui-inputtext, .app.thm-pink-dark .app.thm-gray-dark .ui-inputtext, .app.thm-gray-dark .app.thm-pink-dark .ui-tabview .ui-tabview-panels, .app.thm-pink-dark .ui-tabview .app.thm-gray-dark .ui-tabview-panels, .app.thm-gray-dark .app.thm-pink-dark .ui-paginator, .app.thm-pink-dark .app.thm-gray-dark .ui-paginator, .app.thm-gray-dark .app.thm-pink-dark .ui-table .ui-table-caption, .app.thm-pink-dark .ui-table .app.thm-gray-dark .ui-table-caption,
.app.thm-gray-dark .app.thm-pink-dark .ui-table .ui-table-summary,
.app.thm-pink-dark .ui-table .app.thm-gray-dark .ui-table-summary, .app.thm-gray-dark .app.thm-pink-dark .ui-table .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-pink-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:not([class*=bg-]) > td:not([class*=bg-]), .app.thm-gray-dark .app.thm-pink-dark .ui-tree, .app.thm-pink-dark .app.thm-gray-dark .ui-tree {
  background: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .card-style-no-border-set, .app.thm-gray-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-gray-dark .ui-multiselect .ui-multiselect-label,
.app.thm-gray-dark .ui-multiselect .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect .ui-multiselect-label, .app.thm-yellow-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-yellow-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-yellow-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-yellow-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-yellow-light .ui-dropdown .ui-dropdown-trigger, .app.thm-yellow-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-green-light .ui-multiselect .ui-multiselect-label, .app.thm-green-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-green-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-green-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-green-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-green-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-green-light .ui-dropdown .ui-dropdown-trigger, .app.thm-green-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect .ui-multiselect-label, .app.thm-indigo-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-indigo-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-indigo-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-indigo-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-indigo-light .ui-dropdown .ui-dropdown-trigger, .app.thm-indigo-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect .ui-multiselect-label, .app.thm-teal-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-teal-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-teal-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-teal-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-teal-light .ui-dropdown .ui-dropdown-trigger, .app.thm-teal-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-red-light .ui-multiselect .ui-multiselect-label, .app.thm-red-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-red-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-red-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-red-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-red-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-red-light .ui-dropdown .ui-dropdown-trigger, .app.thm-red-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect .ui-multiselect-label, .app.thm-gray-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-gray-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-gray-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-gray-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-gray-light .ui-dropdown .ui-dropdown-trigger, .app.thm-gray-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect .ui-multiselect-label, .app.thm-blue-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-blue-dark .ui-multiselect .ui-multiselect-trigger,
.app.thm-blue-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-blue-dark .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-blue-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-blue-dark .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect .ui-multiselect-label, .app.thm-blue-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-blue-light .ui-multiselect .ui-multiselect-trigger,
.app.thm-blue-light .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-header, .app.thm-blue-light .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-blue-light .ui-dropdown .ui-dropdown-trigger, .app.thm-blue-light .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect .ui-multiselect-label, .app.thm-green-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-green-dark .ui-multiselect .ui-multiselect-trigger,
.app.thm-green-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-green-dark .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-green-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-green-dark .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect .ui-multiselect-label, .app.thm-pink-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-label,
.app.thm-gray-dark .app.thm-pink-dark .ui-multiselect .ui-multiselect-trigger,
.app.thm-pink-dark .ui-multiselect .app.thm-gray-dark .ui-multiselect-trigger, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-header, .app.thm-pink-dark .ui-multiselect-panel .app.thm-gray-dark .ui-multiselect-header, .app.thm-gray-dark .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item, .app.thm-gray-dark .app.thm-pink-dark .ui-dropdown .ui-dropdown-trigger, .app.thm-pink-dark .ui-dropdown .app.thm-gray-dark .ui-dropdown-trigger {
  background: #424242;
  color: white;
}
.app.thm-gray-dark .active-style, .app.thm-gray-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-gray-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-yellow-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-yellow-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-yellow-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-yellow-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-yellow-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-yellow-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-yellow-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-green-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-green-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-green-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-green-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-indigo-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-indigo-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-indigo-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-indigo-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-indigo-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-indigo-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-indigo-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-teal-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-teal-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-teal-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-teal-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-teal-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-teal-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-teal-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-red-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-red-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-red-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-red-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-red-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-red-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-red-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-gray-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-gray-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-gray-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-gray-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-gray-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-gray-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-gray-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-blue-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-blue-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-blue-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-blue-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-dark .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-blue-light .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-blue-light .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-blue-light .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-blue-light .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-blue-light .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-blue-light .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-blue-light .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-green-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-green-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-green-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-green-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-green-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-green-dark .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-green-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight, .app.thm-gray-dark .app.thm-pink-dark .ui-chkbox .ui-chkbox-box.ui-state-active, .app.thm-pink-dark .ui-chkbox .app.thm-gray-dark .ui-chkbox-box.ui-state-active, .app.thm-gray-dark .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .app.thm-pink-dark .ui-tabview.ui-tabview-top .ui-tabview-nav .app.thm-gray-dark li.ui-state-active, .app.thm-gray-dark .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item.ui-state-highlight,
.app.thm-gray-dark .app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight,
.app.thm-pink-dark .ui-dropdown-panel .ui-dropdown-items .app.thm-gray-dark .ui-dropdown-item-group.ui-state-highlight, .app.thm-gray-dark .app.thm-pink-dark .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active, .app.thm-pink-dark .ui-paginator .ui-paginator-pages .app.thm-gray-dark .ui-paginator-page.ui-state-active, .app.thm-gray-dark .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight, .app.thm-pink-dark .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .app.thm-gray-dark .ui-treenode-label.ui-state-highlight {
  background: #455a64;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .active-style-light, .app.thm-gray-dark .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight, .app.thm-yellow-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-indigo-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-teal-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-red-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-gray-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-blue-light .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-green-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight, .app.thm-pink-dark .ui-multiselect-panel .ui-multiselect-items .app.thm-gray-dark .ui-multiselect-item.ui-state-highlight {
  background: #78909c;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .active-alternance-table, .app.thm-gray-dark .ui-table .ui-table-tbody > tr:nth-child(even), .app.thm-yellow-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-indigo-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-teal-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-red-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-gray-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-blue-light .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-green-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even), .app.thm-pink-dark .ui-table .app.thm-gray-dark .ui-table-tbody > tr:nth-child(even) {
  background: #303030;
  color: white;
}
.app.thm-gray-dark .border-error-style, .app.thm-gray-dark .ui-inputtext.ng-invalid.ng-touched {
  border-color: #d32f2f;
}
.app.thm-gray-dark .clv-bg-danger, .app.thm-gray-dark .bg-danger {
  background: #f44336;
  color: white;
}
.app.thm-gray-dark .clv-color-danger, .app.thm-gray-dark .danger-color {
  color: #d32f2f;
}
.app.thm-gray-dark .clv-bg-danger-imp, .app.thm-gray-dark .bg-danger-imp {
  background: #f44336 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-danger-imp, .app.thm-gray-dark .danger-color-imp {
  color: #d32f2f !important;
}
.app.thm-gray-dark .clv-bg-success, .app.thm-gray-dark .bg-success {
  background: #4caf50;
  color: white;
}
.app.thm-gray-dark .clv-color-success, .app.thm-gray-dark .success-color {
  color: #388e3c;
}
.app.thm-gray-dark .clv-bg-success-imp, .app.thm-gray-dark .bg-success-imp {
  background: #4caf50 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-success-imp, .app.thm-gray-dark .success-color-imp {
  color: #388e3c !important;
}
.app.thm-gray-dark .clv-bg-warning, .app.thm-gray-dark .bg-warning {
  background: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-dark .clv-color-warning, .app.thm-gray-dark .warning-color {
  color: #fbc02d;
}
.app.thm-gray-dark .clv-bg-warning-imp, .app.thm-gray-dark .bg-warning-imp {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-gray-dark .clv-color-warning-imp, .app.thm-gray-dark .warning-color-imp {
  color: #fbc02d !important;
}
.app.thm-gray-dark .clv-bg-orange, .app.thm-gray-dark .bg-orange {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.app.thm-gray-dark .clv-color-orange, .app.thm-gray-dark .orange-color {
  color: #ffa000;
}
.app.thm-gray-dark .clv-bg-orange-imp, .app.thm-gray-dark .bg-orange-imp {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.thm-gray-dark .clv-color-orange-imp, .app.thm-gray-dark .orange-color-imp {
  color: #ffa000 !important;
}
.app.thm-gray-dark .clv-bg-info, .app.thm-gray-dark .bg-info {
  background: #03a9f4;
  color: white;
}
.app.thm-gray-dark .clv-color-info, .app.thm-gray-dark .info-color {
  color: #0288d1;
}
.app.thm-gray-dark .clv-bg-info-imp, .app.thm-gray-dark .bg-info-imp {
  background: #03a9f4 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-info-imp, .app.thm-gray-dark .info-color-imp {
  color: #0288d1 !important;
}
.app.thm-gray-dark .clv-bg-blue, .app.thm-gray-dark .bg-blue {
  background: #2196f3;
  color: white;
}
.app.thm-gray-dark .clv-color-blue, .app.thm-gray-dark .blue-color {
  color: #1976d2;
}
.app.thm-gray-dark .clv-bg-blue-imp, .app.thm-gray-dark .bg-blue-imp {
  background: #2196f3 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-blue-imp, .app.thm-gray-dark .blue-color-imp {
  color: #1976d2 !important;
}
.app.thm-gray-dark .clv-bg-indigo, .app.thm-gray-dark .bg-indigo {
  background: #3f51b5;
  color: white;
}
.app.thm-gray-dark .clv-color-indigo, .app.thm-gray-dark .indigo-color {
  color: #303f9f;
}
.app.thm-gray-dark .clv-bg-indigo-imp, .app.thm-gray-dark .bg-indigo-imp {
  background: #3f51b5 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-indigo-imp, .app.thm-gray-dark .indigo-color-imp {
  color: #303f9f !important;
}
.app.thm-gray-dark .clv-bg-brown, .app.thm-gray-dark .bg-brown {
  background: #795548;
  color: white;
}
.app.thm-gray-dark .clv-color-brown, .app.thm-gray-dark .brown-color {
  color: #5d4037;
}
.app.thm-gray-dark .clv-bg-brown-imp, .app.thm-gray-dark .bg-brown-imp {
  background: #795548 !important;
  color: white !important;
}
.app.thm-gray-dark .clv-color-brown-imp, .app.thm-gray-dark .brown-color-imp {
  color: #5d4037 !important;
}
.app.thm-gray-dark .connexion-titre {
  color: #455a64;
}
.app.thm-gray-dark .header {
  background: #424242;
  color: white;
}
.app.thm-gray-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .content-header h4 {
  color: #eceff1;
}
.app.thm-gray-dark .favorites-container:after {
  color: #ff4081;
}
.app.thm-gray-dark .horizontal-menu {
  background: #424242;
}
.app.thm-gray-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.thm-gray-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.thm-gray-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #455a64;
  color: #fff;
}
.app.thm-gray-dark .menu-item .mat-button .menu-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.thm-gray-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .menu-item .mat-button.active-link .menu-icon {
  background: #455a64;
  color: #fff;
}
.app.thm-gray-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.thm-gray-dark .breadcrumb a {
  color: #eceff1;
}
.app.thm-gray-dark .top-toolbar, .app.thm-gray-dark .landing-toolbar {
  background: white;
  color: #37474f;
}
.app.thm-gray-dark .menu-toolbar {
  background: #263238;
  color: #cfd8dc;
}
.app.thm-gray-dark .ps__thumb-y {
  background-color: #455a64;
}
.app.thm-gray-dark .testimonial-item, .app.thm-gray-dark .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.thm-gray-dark .login-container {
  background: #303030;
}
.app.thm-gray-dark .ngx-charts text {
  fill: white;
}
.app.thm-gray-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.thm-gray-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.thm-gray-dark .annular-pagination .ngx-pagination .current {
  background: #455a64;
  color: white;
}
.app.thm-gray-dark .annular-pagination .ngx-pagination a:hover, .app.thm-gray-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(69, 90, 100, 0.2);
  color: white;
}
.app.thm-gray-dark .cal-day-view .cal-hour-segment:hover,
.app.thm-gray-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.thm-gray-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.thm-gray-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.thm-gray-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.thm-gray-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.thm-gray-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(69, 90, 100, 0.05);
}
.app.thm-gray-dark .cal-week-view .cal-header.cal-today,
.app.thm-gray-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.thm-gray-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.thm-gray-dark .cal-event-action i {
  margin: 4px;
}
.app.thm-gray-dark .ql-snow .ql-picker,
.app.thm-gray-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.thm-gray-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.thm-gray-dark .ql-snow .ql-fill {
  fill: white;
}
.app.thm-gray-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.thm-gray-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.thm-gray-dark .ngx-charts text {
  fill: white;
}
.app.thm-gray-dark .mat-table td {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .drop-zone {
  border: 3px dashed;
  border-color: #ccc !important;
}
.app.thm-gray-dark .ui-dialog .ui-dialog-titlebar {
  background-color: #455a64;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
.app.thm-gray-dark .ui-button {
  color: !important;
  background-color: #455a64;
  border: 1px solid #455a64;
}
.app.thm-gray-dark .ui-button.ui-button-primary {
  background-color: #455a64;
  border: 1px solid #455a64;
}
.app.thm-gray-dark .ui-button.ui-button-secondary {
  background: #ff4081;
  color: white;
  border: 1px solid #ff4081;
}
.app.thm-gray-dark .ui-button.ui-button-info {
  background-color: #29b6f6;
  border: 1px solid #29b6f6;
}
.app.thm-gray-dark .ui-multiselect {
  display: block;
}
.app.thm-gray-dark .ui-table .ui-table-thead > tr > th {
  /*@extend .active-style;*/
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .ui-table .ui-table-tbody > tr:nth-child(even) > td:not([class*=bg-]) {
  background: initial;
}
.app.thm-gray-dark .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: white;
}
.app.thm-gray-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-titlebar {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .prime-ng-confirm-error.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #d32f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-titlebar {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .rejected-button-class {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .prime-ng-confirm-error.warning.ui-dialog .ui-dialog-footer .accepted-button-class {
  background-color: #f57c00;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .form-separator {
  background: #455a64;
  text-align: center;
  color: white;
}
.app.thm-gray-dark .form-separator::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.thm-gray-dark .titre-in-table {
  background: #ff4081;
  color: white;
  text-align: center;
}
.app.thm-gray-dark .titre-in-table::before {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.sb-light .mat-drawer {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.54);
}
.app.sb-light .mat-drawer .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.sb-light .mat-drawer .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.sb-light .mat-drawer .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.54);
}
.app.sb-light .mat-drawer .mat-button.mat-button-base.expanded .sub-menu {
  background: #fafafa;
}
.app.sb-light .mat-drawer mat-icon.menu-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.sb-dark .mat-drawer {
  background: #303030;
  color: rgba(255, 255, 255, 0.7);
}
.app.sb-dark .mat-drawer .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.sb-dark .mat-drawer .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.sb-dark .mat-drawer .menu-item .mat-button .menu-title {
  color: rgba(255, 255, 255, 0.7);
}
.app.sb-dark .mat-drawer .mat-button.mat-button-base.expanded .sub-menu {
  background: #303030;
}
.app.sb-dark .mat-drawer mat-icon.menu-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  color: rgba(255, 255, 255, 0.7);
}

.gradient-purple {
  background: #7873f5 !important;
  background: -webkit-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: -o-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  color: #fff !important;
}

.gradient-indigo {
  background: #303f9f !important;
  background: -webkit-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: -o-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  color: #fff !important;
}

.gradient-blue {
  background: #2196F3 !important;
  background: -webkit-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: -o-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: linear-gradient(50deg, #37ffb5, #2196F3) !important;
  color: #fff !important;
}

.gradient-orange {
  background: #FF7043 !important;
  background: -webkit-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: -o-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: linear-gradient(50deg, #ffd86f, #FF7043) !important;
  color: #fff !important;
}

.gradient-green {
  background: #2E7D32 !important;
  background: -webkit-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: -o-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: linear-gradient(50deg, #C5D429, #2E7D32) !important;
  color: #fff !important;
}

.gradient-pink {
  background: #E91E63 !important;
  background: -webkit-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: -o-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: linear-gradient(50deg, #F59E99, #E91E63) !important;
  color: #fff !important;
}

.gradient-red {
  background: #D50000 !important;
  background: -webkit-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: -o-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: linear-gradient(50deg, #FF9E80, #D50000) !important;
  color: #fff !important;
}

.gradient-amber {
  background: #e65100 !important;
  background: -webkit-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: -o-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: linear-gradient(50deg, #FFBA3A, #e65100) !important;
  color: #fff !important;
}

.gradient-gray {
  background: #263238 !important;
  background: -webkit-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: -o-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: linear-gradient(50deg, #8EF9FE, #263238) !important;
  color: #fff !important;
}

.gradient-teal {
  background: #00796B !important;
  background: -webkit-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: -o-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: linear-gradient(50deg, #A9DC7B, #00796B) !important;
  color: #fff !important;
}

.gradient-brown {
  background: #5D4037 !important;
  background: -webkit-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: -o-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: linear-gradient(50deg, #FA9151, #5D4037) !important;
  color: #fff !important;
}

.gradient-lime {
  background: #827717 !important;
  background: -webkit-linear-gradient(50deg, #FDD819, #827717) !important;
  background: -o-linear-gradient(50deg, #FDD819, #827717) !important;
  background: linear-gradient(50deg, #FDD819, #827717) !important;
  color: #fff !important;
}

.app[dir=rtl].fixed-sidenav .footer {
  float: left;
}
.app[dir=rtl].fixed-footer .footer {
  right: auto;
  left: 0;
}
.app[dir=rtl] .info-content .close-btn {
  right: auto !important;
  left: 8px;
}
.app[dir=rtl] .options-icon {
  left: -4px;
}
.app[dir=rtl] .menu-item .mat-button .menu-icon {
  margin-left: 12px;
}
.app[dir=rtl] .menu-expand-icon {
  right: auto;
  left: 10px;
}
.app[dir=rtl] .sub-menu .sub-menu .mat-button {
  padding-right: 40px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 60px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 80px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 100px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 120px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 140px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 160px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 180px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 200px;
}
.app[dir=rtl] .sub-menu .mat-button {
  padding-right: 20px;
}
.app[dir=rtl].compact .menu-item .mat-button .menu-icon {
  margin-left: 0;
}
.app[dir=rtl].compact .sub-menu .mat-button {
  padding-right: 0 !important;
}
.app[dir=rtl].mini .menu-item .mat-button .menu-icon {
  margin-left: 0;
}
.app[dir=rtl].mini .sub-menu .mat-button {
  padding-right: 0 !important;
}
.app[dir=rtl].mini .menu-expand-icon {
  right: auto;
  left: 0px;
}
.app[dir=rtl] .horizontal-menu-item .horizontal-menu-icon {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .horizontal-sub-menu .horizontal-sub-menu {
  left: auto;
  right: 100%;
}
.app[dir=rtl].horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button .horizontal-menu-icon {
  margin-right: 0;
  margin-left: 10px;
}
.app[dir=rtl] .back-to-top {
  right: auto;
  left: 20px;
}
.app[dir=rtl] .messages img {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .messages img, .app[dir=rtl] .messages .row-1 {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .messages span.text {
  text-align: right;
}
.app[dir=rtl] .flags img {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .tile {
  text-align: left;
}
.app[dir=rtl] .tile .mat-icon {
  left: auto;
  right: 32px;
}
.app[dir=rtl] .user-content .info-list span {
  padding-left: 0;
  padding-right: 8px;
}
.app[dir=rtl] .ql-editor.ql-blank::before {
  right: 6px;
}
.app[dir=rtl] .ql-editor {
  text-align: right;
}
.app[dir=rtl] .chat-status-icon {
  margin-right: 0;
  margin-left: 6px;
}
.app[dir=rtl] .chat-sidenav, .app[dir=rtl] .mailbox-sidenav {
  border-right: none;
  border-left: 1px solid transparent;
}
.app[dir=rtl] .cal-month-view .cal-day-number {
  float: left;
  margin-right: 0;
  margin-left: 15px;
}
.app[dir=rtl] .cal-month-view .cal-day-badge {
  margin-left: 0;
  margin-right: 10px;
}
.app[dir=rtl] .mat-dialog-title {
  text-align: right;
}
.app[dir=rtl] .favorites-container:after {
  right: auto;
  left: -14px;
}
.app[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
.app[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}

#hot-display-license-info {
  display: none;
}

.ht_master .wtHolder {
  width: initial !important;
  height: initial !important;
}

@keyframes zoomAllerRetour {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes zoomAllerRetour {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.5);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-webkit-keyframes zoomAllerRetour {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-o-keyframes zoomAllerRetour {
  0% {
    -o-transform: scale(1);
  }
  50% {
    -o-transform: scale(0.5);
  }
  100% {
    -o-transform: scale(1);
  }
}
/** Page demande PEC */
.mb-p-card {
  margin-bottom: 2rem;
}

/** color ligne table prise en charge: en accord préalable */
.color-row-red-PEC {
  background-color: #f7c9c9 !important;
}

/** color ligne table prise en charge: en accord préalable est false */
.color-row-white-PEC {
  background-color: transparent !important;
}

/** color indicateur champ éditable */
.color-champ-editable {
  color: #e09302;
  font-weight: bold;
  text-decoration: underline;
}

/** color arrow right list table Composant atlantis */
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #116fbf !important;
}

.imgAssure {
  border: solid 1px #cc532e;
  height: 156px;
  width: 145px;
}

/** couleur de bouton format SA JS */
.bg-info-sa-web {
  background-color: #6254b2 !important;
  color: white !important;
}

a.bg-info-sa-web:hover, a.bg-info-sa-web:focus,
button.bg-info-sa-web:hover,
button.bg-info-sa-web:focus {
  background-color: #6254b2 !important;
  color: white !important;
}

/** couleur de bouton de sélection de fichier dans ajout document */
.app.thm-blue-light .ui-button.ui-button-secondary {
  background: #1689d1 !important;
  color: white !important;
  border: 1px solid #1689d1 !important;
}

/** occupe obligatoirement 100% de la largeur */
.p-card-w100 {
  width: 100% !important;
}

/** */
.container-tableList-dmd {
  overflow: auto;
  max-height: 150px;
  width: 35%;
  margin-top: -10px;
}

div:not(.ui-dialog-maximized) div.ui-dialog-content.ui-widget-content {
  height: auto !important;
  max-height: calc(100vh - 30px) !important;
}

/** custom size new table */
.table-title {
  font-size: 14px !important;
  background: #e0e0e0;
  border: 0.5px solid #dadada;
}

/** custom text-area input commentaire accord préalable */
.input-accord-prealable {
  margin-top: 0.3rem;
  height: 64px;
  width: 207px;
  padding: 0.3rem;
}

/* changement de couleur lors d'une désactivation*/
.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}
.disabled button {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

/* changement de couleur du bouton calculer*/
.img-photo {
  width: 130px;
  height: 130px;
}

/** */
.desing-mat-spinner {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 8rem;
}

.zoomBodyApp {
  zoom: 85%;
}

/**
  Mode disabled color text
 */
input:disabled {
  background: #eee !important;
  color: #000 !important;
  opacity: 1 !important;
  border: 1px solid #ccc !important;
}

textarea:disabled {
  background: #eee !important;
  color: #000 !important;
  opacity: 1 !important;
  border: 1px solid #ccc !important;
}