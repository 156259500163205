@import "variables";
@mixin theme-reset($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $red-palette: mat-palette($mat-red, 700, 50, 800);
    $amber-palette: mat-palette($mat-amber, 700, 50, 800);
    $green-palette: mat-palette($mat-green, 700, 50, 800);
    $yellow-palette: mat-palette($mat-yellow, 700, 50, 800);
    $light-blue-palette: mat-palette($mat-light-blue, 700, 50, 800);
    $blue-palette: mat-palette($mat-blue, 700, 50, 800);
    $indigo-palette: mat-palette($mat-indigo, 700, 50, 800);
    $brown-palette: mat-palette($mat-brown, 700, 50, 800);

    .bg-primary {
        @include _mat-toolbar-color($primary);
    }
    .bg-primary-l-400 {
        @include mat-toolbar-400-color($primary);
    }
    .bg-accent {
        @include _mat-toolbar-color($accent);
    }
    .bg-warn {
        @include _mat-toolbar-color($warn);
    }

    .secondary-text-color{
        color: mat-color($foreground, secondary-text);
    }
    .primary-color{
        color: mat-color($primary) !important;
    }
    .accent-color{
        color: mat-color($accent) !important;
    }
    .warn-color{
        color: mat-color($warn) !important;
    }
    .bg-card {
        background: mat-color($background, card);
    }

    .bg-e2 {
      background: #e2e2e2;
    }
    .bg-white {
      background: #fff;
    }
  .link-style {
    color: mat-color($accent);
    cursor: pointer;
  }

  .card-style {
    background: mat-color($background, card);
    color: mat-color($foreground, text);
    border: 1px solid mat-color($foreground, divider);
  }
  .card-style-no-border-set {
    background: mat-color($background, card);
    color: mat-color($foreground, text);
  }
  .active-style {
    background: mat-color($primary);
    color: mat-color($primary, default-contrast);
    border: 1px solid mat-color($foreground, divider);
  }
  .active-style-light {
    background: mat-color($primary, 400);
    color: mat-color($primary, default-contrast);
    border: 1px solid mat-color($foreground, divider);
  }
  .active-alternance-table {
    background: mat-color($background, background);
    color: mat-color($foreground, text);
  }
  .border-error-style {
    border-color: mat-color($red-palette);
  }

  @include class-colors('danger', $red-palette);
  @include class-colors('success', $green-palette);
  @include class-colors('warning', $yellow-palette);
  @include class-colors('orange', $amber-palette);
  @include class-colors('info', $light-blue-palette);
  @include class-colors('blue', $blue-palette);
  @include class-colors('indigo', $indigo-palette);
  @include class-colors('brown', $brown-palette);


  .connexion-titre {
    color: mat-color($primary);
  }
    .header{
        background: mat-color($background, card);
        color: mat-color($foreground, base);
        .info-content{
            .top-contact-form{
                border-color: mat-color($foreground, divider);
            }
        }
    }
    .content-header h4{
        color: mat-color($primary, lighter);
    }
    .favorites-container:after{
        color: mat-color($accent);
    }

    .horizontal-menu{
        background: mat-color($background, card);
    }
    .horizontal-menu-item {
        .horizontal-sub-menu{
            background: mat-color($background, card);
        }
        .mat-button{
            color: mat-color($foreground, secondary-text);
            .horizontal-menu-title{
                color: mat-color($foreground, text);
            }
            &.parent-item{
                .horizontal-menu-icon{
                    background: mat-color($background, focused-button);
                    color: mat-color($foreground, secondary-text);
                }
                &.active-link{
                    background: mat-color($background, focused-button);
                    .horizontal-menu-icon{
                        background: mat-color($primary);
                        color: #fff;
                    }
                }
            }
        }
    }

    .menu-item{
        .mat-button{
            .menu-icon{
                //background: mat-color($background, focused-button);
                color: mat-color($foreground, secondary-text);
            }
            .menu-title{
                color: mat-color($foreground, text);
            }
            &.active-link{
                background: mat-color($background, focused-button);
                .menu-icon{
                    background: mat-color($primary);
                    color: #fff;
                }
            }
        }
    }
    .menu-expand-icon{
        color: mat-color($foreground, secondary-text);
    }
    .breadcrumb a{
        color: mat-color($primary, lighter);
    }
    .top-toolbar, .landing-toolbar{
        background: mat-color($primary, default-contrast); // darker , default-contrast
        color: mat-color($primary, darker); // darker , default-contrast
    }
    .menu-toolbar{
        background: mat-color($primary, 900);
        color: mat-color($primary, 100);
    }
    .ps__thumb-y{
        background-color: mat-color($primary);
    }

    .testimonial-item, .pricing-card {
        border-top: 4px solid mat-color($accent);
    }
    .login-container {
      background: mat-color($background, background);
    }

    /* ngx-charts */
    .ngx-charts{
        text{
            fill: mat-color($foreground, text);
        }
    }
    [class*=gradient-] .ngx-charts{
        text{
            fill: #fff;
        }
    }

    /* ngx-pagination */
    .annular-pagination .ngx-pagination{
        margin-bottom: 0;
        padding: 4px;
        .current {
            @include _mat-toolbar-color($primary);
        }
        a:hover, button:hover{
            background: rgba(mat-color($primary), 0.2);
            color: mat-color($foreground, base);
        }
    }

    /* angular-calendar */
    .cal-day-view .cal-hour-segment:hover,
    .cal-day-view .cal-drag-over .cal-hour-segment,
    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-day-headers .cal-drag-over,
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open{
        background-color: rgba(mat-color($primary), 0.4);
    }
    .cal-month-view .cal-cell-row:hover{
        background-color: rgba(mat-color($primary), 0.05);
    }
    .cal-week-view .cal-header.cal-today,
    .cal-month-view .cal-day-cell.cal-today{
        background-color: rgba(mat-color($primary), 0.4);
    }
    .cal-day-view .cal-hour:nth-child(odd){
        background-color: inherit;
    }
    .cal-event-action i{
        margin: 4px;
    }

    /* ngx-quill */
    .ql-snow .ql-picker,
    .ql-editor.ql-blank::before{
        color: mat-color($foreground, text);
    }
    .ql-snow .ql-stroke{
        stroke: mat-color($foreground, text);
    }
    .ql-snow .ql-fill{
        fill: mat-color($foreground, text);
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
        background: mat-color($background, app-bar);
    }

    /* ngx-charts */
    .ngx-charts{
        text{
            fill: mat-color($foreground, text);
        }
    }

    /* mat-tables */
    .mat-table td{
        border-bottom-color: mat-color($foreground, divider);
    }

  /* drop zone */
  .drop-zone {
    border: 3px dashed;
    //border-color: mat-color($accent, 400);
    border-color: #ccc !important;
  }

    /* Prime ng */
    .ui-dialog {
        .ui-dialog-titlebar {
            background-color: mat-color($primary);
            color: mat-color($primary, default-contrast);
            border: 1px solid mat-color($foreground, divider);
            .ui-dialog-titlebar-icon {
                color: mat-color($primary, default-contrast);
            }
        }
        .ui-dialog-content {
            @extend .card-style;
        }
        .ui-dialog-footer {
            @extend .card-style;
        }
    }
    .ui-button {
        color: mat-color($primary, default-contast) !important;
        background-color: mat-color($primary);
        border: 1px solid mat-color($primary);
        &.ui-button-primary {
            background-color: mat-color($primary);
            border: 1px solid mat-color($primary);
        }
        &.ui-button-secondary {
          @include _mat-toolbar-color($accent);
          border: 1px solid mat-color($accent);
        }
        &.ui-button-info {
            background-color: mat-color($mat-light-blue, 400);
            border: 1px solid mat-color($mat-light-blue, 400);
        }
    }
    .ui-multiselect {
      @extend .card-style;
      display: block;
      .ui-multiselect-label,
      .ui-multiselect-trigger {
        @extend .card-style-no-border-set;
      }
    }
    .ui-multiselect-panel {
      @extend .card-style;
      .ui-multiselect-header {
        @extend .card-style-no-border-set;
      }
      .ui-multiselect-items {
        .ui-multiselect-item {
          @extend .card-style-no-border-set;
          &.ui-state-highlight {
            @extend .active-style-light;
          }
        }
      }
    }
    .ui-chkbox {
      .ui-chkbox-box {
        @extend .card-style;
        &.ui-state-active {
          @extend .active-style;
        }
      }
    }
    .ui-inputtext {
      @extend .card-style;
      &.ng-invalid.ng-touched {
        @extend .border-error-style;
      }
    }
    .ui-dropdown {
      .ui-dropdown-trigger {
        @extend .card-style-no-border-set;
      }
    }
  .ui-tabview {
    .ui-tabview-panels {
      @extend .card-style;
    }
  }
  .ui-tabview {
    &.ui-tabview-top {
      .ui-tabview-nav li {
        //@extend .card-style;
        &.ui-state-active {
          @extend .active-style;
        }
      }
    }
  }
  .ui-dropdown-panel {
    .ui-dropdown-items {
      .ui-dropdown-item.ui-state-highlight,
      .ui-dropdown-item-group.ui-state-highlight {
        @extend .active-style;
      }
    }
  }
    .ui-paginator {
      @extend .card-style;
      .ui-paginator-pages {
        .ui-paginator-page.ui-state-active {
          @extend .active-style;
        }
      }
    }
    .ui-table {
        .ui-table-thead {
            & > tr {
                & > th {
                  /*@extend .active-style;*/
                  background: mat-color($mat-gray, 300);
                  color: mat-color(mat-palette($mat-gray, 500, 300, 300), default-contrast);
                  border: 1px solid mat-color($foreground, divider);
                }
            }
        }
        .ui-table-caption,
        .ui-table-summary {
          @extend .card-style;
        }
        .ui-table-tbody {
            & > tr:nth-child(even) {
              @extend .active-alternance-table;
              & > td:not([class*=bg-]) {
                background: initial;
              }
            }
            & > tr:not([class*=bg-]) {
                & > td:not([class*=bg-]) {
                    @extend .card-style;
                }
            }
        }
        .ui-sortable-column {
            .ui-sortable-column-icon {
                color: mat-color($primary, default-contrast);
            }
        }
    }
  .ui-tree {
    @extend .card-style;
    .ui-tree-container {
      .ui-treenode {
        .ui-treenode-content {
          .ui-treenode-label {
            &.ui-state-highlight {
              @extend .active-style;
            }
          }
        }
      }
    }
  }

  .prime-ng-confirm-error {
    @include prime-ng-confirm-error-mix($mat-red, $foreground, $background);
    &.warning {
      @include prime-ng-confirm-error-mix($mat-orange, $foreground, $background);
    }
  }

  //personnel
  .form-separator {
    background: mat-color($primary);
    text-align: center;
    color: mat-color($primary, default-contrast);
    &::before {
      border-color: mat-color($foreground, divider);
    }
  }
  .titre-in-table {
    background: mat-color($accent);
    color: mat-color($accent, default-contrast);
    text-align: center;
    &::before {
      border-color: mat-color($foreground, divider);
    }
  }

}

