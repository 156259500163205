@mixin menu-level-padding($direction){
    $elem: '';
    @for $i from 2 through 10 {
        $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));      
        #{$elem + ' .mat-button'} { 
            @if $direction == "rtl" {
                padding-right: 20px * $i; 
            } 
            @else{
                padding-left: 20px * $i; 
            }
        }
    }
}

@mixin theme-color($color) {
    color: $color;
}

@mixin theme-bg-color($bg, $color) {
    background: $bg;
    color: $color;
}

@mixin mat-toolbar-400-color($palette) {
    background-color: mat-color($palette, 400);
    color: mat-color($palette, default-contrast);
}

@mixin class-colors($name, $palette) {
    .clv-bg-#{$name}, .bg-#{$name} {
        background: mat-color($palette, 500);
        color: mat-color($palette, default-contrast);
    }

    .clv-color-#{$name}, .#{$name}-color {
        color: mat-color($palette, 700);
    }

    .clv-bg-#{$name}-imp, .bg-#{$name}-imp {
        background: mat-color($palette, 500) !important;
        color: mat-color($palette, default-contrast) !important;
    }

    .clv-color-#{$name}-imp, .#{$name}-color-imp {
        color: mat-color($palette, 700) !important;
    }
}


@mixin prime-ng-confirm-error-mix($palette, $foreground, $background) {
    &.ui-dialog {
        .ui-dialog-titlebar {
            background-color: mat-color($palette, 700);
            color: mat-color($palette, default-contrast);
            border: 1px solid mat-color($foreground, divider);
            .ui-dialog-titlebar-icon {
                color: mat-color($palette, default-contrast);
            }
        }
        .ui-dialog-footer {
            .rejected-button-class {
                background-color: mat-color($background, raised-button);
                color: mat-color($foreground, text);
                border: 1px solid mat-color($foreground, divider);
            }
            .accepted-button-class {
                background-color: mat-color($palette, 700);
                color: mat-color($palette, default-contrast);
                border: 1px solid mat-color($foreground, divider);
            }
        }
    }
}
