@import "variables";

@mixin sidebar-theme-reset($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-drawer {
    background: mat-color($background, background);
    color: mat-color($foreground, secondary-text);
    .menu-expand-icon {
      color: mat-color($foreground, secondary-text);
    }
    .secondary-text-color {
      color: mat-color($foreground, secondary-text);
    }
    .menu-item .mat-button {
      .menu-title {
        color: mat-color($foreground, secondary-text);
      }
    }
    .mat-button.mat-button-base.expanded {
      .sub-menu {
        background: mat-color($background, background);
      }
    }
    mat-icon.menu-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
      color: mat-color($foreground, secondary-text);
    }
  }
}
